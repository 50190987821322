import { FC } from 'react';
import useFormDropZone from './FormDropZone.hook';
import FormFieldTitle from 'components/FormFieldTitle';
import {
  FieldState,
  FormDropZoneConfig,
  SetFieldState,
  SetListLoading,
} from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/components/Box';
import DropZone from 'yoda-ui/components/DropZone';
import FormHelperText from 'yoda-ui/components/Form/FormHelperText';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

type FormDropZoneProps = FormDropZoneConfig & {
  fieldState: FieldState;
  title?: string;
  setListLoading?: SetListLoading;
  setFieldState: SetFieldState;
  transformToState: Function;
  disabled: boolean;
};

const FormDropZone: FC<FormDropZoneProps> = ({
  assetType,
  assetTarget,
  title,
  fieldState,
  setFieldState,
  transformToState,
  disabled,
  ...dropZoneProps
}) => {
  const { errorMessage, getInputProps, getRootProps, isDragActive } = useFormDropZone({
    assetType,
    assetTarget,
    fieldState,
    setFieldState,
    transformToState,
    ...dropZoneProps,
  });

  return (
    <Box marginBottom={ YodaSpacing.medium }>
      { title && <FormFieldTitle title={ title } /> }
      {
        disabled ? null : <DropZone
          getInputProps={ getInputProps }
          getRootProps={ getRootProps }
          isDragActive={ isDragActive }
          { ...dropZoneProps }
        />
      }
      { errorMessage && <FormHelperText error>{ errorMessage }</FormHelperText> }
    </Box>
  );
};

export default FormDropZone;
