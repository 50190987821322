export type CSVLine = Record <string, string>;

export type DimensionsImage = {
  maxHeight: number;
  maxWidth: number;
  minHeight: number;
  minWidth: number;
};

export enum FileExtension {
  csv = '.csv',
  jpeg = '.jpeg',
  jpg = '.jpg',
  json = '.json',
  mp4 = 'mp4',
  png = '.png',
  txt = '.txt',
  xls = '.xls',
  xlsx = '.xlsx',
  xml = '.xml',
  pdf = '.pdf',
}
