import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarContent from './NavbarContent';
import { mayaRoute } from 'basics/constants/routes.constants';
import { useGetConfig } from 'basics/utils/getConfig.hook';
import { mayaLogoStyle } from 'components/Layout/NavBar/Navbar.styles';
import MayaMenu from 'components/MayaMenu';
import { useCurrentUserState } from 'contexts/user';
import AppBar from 'yoda-ui/components/AppBar';
import Box from 'yoda-ui/components/Box';
import Toolbar from 'yoda-ui/components/Toolbar';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const NavBar: FC = () => {
  const navigate = useNavigate();
  const { user } = useCurrentUserState();
  const currentModuleConfig = useGetConfig();
  const location = useLocation();
  const currentURL = location.pathname;

  if (currentModuleConfig.noLayoutURL.includes(currentURL)) {
    return null;
  }

  return (
    <AppBar position="fixed">
      <Box paddingX={ YodaSpacing.small }>
        <Toolbar>
          <Box display="flex" flex="column" justifyContent="space-between" width="100%">
            <Box sx={ mayaLogoStyle } onClick={ () => navigate(mayaRoute) }>
              <img src={ currentModuleConfig?.logo } alt="biogen-logo" style={ { height: 'inherit' } } />
            </Box>
            { user ? <MayaMenu /> : null }
            <Box display="flex" alignItems="center">
              <NavbarContent />
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;
