import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { mayaCepListRoute } from 'basics/constants/routes.constants';
import { TacticStatus } from 'basics/enums/maya.enums';
import useGetAllTacticsQuery from 'basics/graphql/queries/getAllTactics';
import useGetCepByIdQuery from 'basics/graphql/queries/getCepById';
import { CepStateType } from 'basics/types/maya.types';
import { warningToast } from 'basics/utils/toast';
import { Tactic } from 'generated/maya.types';
import cepState from 'states/cep.states';

const useTacticPrintView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const cepId = urlParams.get('id') as string;

  const { data: cep, loading: cepLoading } = useGetCepByIdQuery(cepId || null);
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const queryFilters = useMemo(
    () => ({
      cep: cepId,
      status: [TacticStatus.DRAFT, TacticStatus.APPROVED],
    }),
    [cepId],
  );

  const { data: tactics, loading: tacticsLoading } = useGetAllTacticsQuery(undefined, queryFilters);

  useEffect(() => {
    if (!tacticsLoading) {
      cepState.tactics = tactics;
    }
  }, [tactics, tacticsLoading]);

  useEffect(() => {
    if (!cepLoading) {
      if (cep) {
        cepState.cep = cep;
      } else {
        warningToast(t('errors_standard'));
        navigate(mayaCepListRoute);
      }
    }
  }, [cep, cepLoading, navigate, t]);

  useEffect(() => {
    return function cleanup() {
      cepState.cep = {};
    };
  }, []);

  type TacticActionOrderGroup = {
    [actionOrder: string]: Tactic[];
  };

  const groupedTactics: TacticActionOrderGroup = {};
  cepStateValue.tactics.forEach((tactic) => {
    const actionOrder = tactic?.actionOrder;

    if (actionOrder) {
      if (groupedTactics[actionOrder] === undefined) {
        groupedTactics[actionOrder] = [tactic];
      } else {
        groupedTactics[actionOrder].push(tactic);
      }
    }
  });

  let date;
  if (cepStateValue.cep?.dateStart && cepStateValue.cep?.dateEnd) {
    date = `
      ${new Date(cepStateValue.cep.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(cepStateValue.cep.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    cepStateValue,
    date,
    groupedTactics,
  };
};

export default useTacticPrintView;
