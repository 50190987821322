import { Buffer } from 'buffer';
import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { StreamableBuffer } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const EXPORT_DATA = gql`
query($countries: [InitiativeCountry!]!, $from: String, $to: String) {
  exportData(countries: $countries, from: $from, to: $to) {
    buffer
  }
}
`;

type ResultType = {
  exportData: StreamableBuffer;
};

type UseExportData = () => {
  data: unknown;
  error?: ApolloError;
  exportData: Function;
  loading: boolean;
  refetch: Function;
};

const useExportDataQuery: UseExportData = () => {
  const [exportData, { data, loading, error, refetch }] = useLazyQuery<ResultType>(EXPORT_DATA, {
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      customContext,
      error,
      transactionName: 'exportData.ts - useExportDataQuery',
    });
  }

  return {
    data: data?.exportData?.buffer ? Buffer.from(data.exportData.buffer, 'base64') : null,
    error,
    exportData,
    loading,
    refetch,
  };
};

export default useExportDataQuery;
