import { FC } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';
import { appBarHeight } from 'basics/constants/common.constants';
import Box from 'yoda-ui/components/Box';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const Layout: FC = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    minHeight="100vh"
    justifyContent="space-between"
    overflow="hidden auto"
  >
    <NavBar />
    <Box
      display="flex"
      flexGrow="1"
      paddingTop={ appBarHeight }
      paddingBottom={ YodaSpacing.xLarge }
    >
      { children }
    </Box>
    <Footer />
  </Box>
);

export default Layout;
