import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';
import { FilterField } from './MiViewScreenFilterMenu.types';

const useMiViewScreenFilterMenu = (
  filterField: FilterField,
  updateFilterField: (fieldName: string, fieldValue: string) => void,
  clearFilterField: (fieldName: string) => void,
) => {
  const [fieldValue, setFieldValue] = useState('');
  const [field, setField] = useState(filterField);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value !== '') {
      updateFilterField(filterField.name, event.target.value as string);
      setFieldValue(event.target.value as string);
    } else {
      clearFilterField(filterField.name);
      setFieldValue('');
    }
  };

  useEffect(() => {
    setField(filterField);
  }, [filterField]);

  return {
    handleChange,
    fieldValue,
    field,
  };
};

export default useMiViewScreenFilterMenu;
