import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { CustomerInitiative } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const COUNT_CUSTOMER_INITIATIVES = gql`
query GetDashboardCIType($filters: DashboardFilter!, $limit: Int, $offset: Int) {
  getDashboardCI(filters: $filters, limit: $limit, offset: $offset) {
    customerInitiatives {
      _id
      title,
      product,
      therapeuticArea,
      functionalOwner,
      status,
      updated,
      veevaSyncStatus
    }
    count
  }
}
`;

export type ResultType = {
  getDashboardCI: {
    customerInitiatives: CustomerInitiative[];
    count: number;
  };
};

type UseGetDashboardCI = () => {
  getDashboardCIQuery: Function;
  loading: boolean;
  error?: ApolloError;
  ciData: CustomerInitiative[];
  ciCount: unknown;
};

const useGetDashboardCIQuery: UseGetDashboardCI = () => {
  const [getDashboardCIQuery, { data, loading, error }] = useLazyQuery<ResultType>(COUNT_CUSTOMER_INITIATIVES, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getDashboardCI.ts - usegetDashboardCIQuery',
    });
  }

  return {
    getDashboardCIQuery,
    ciData: data?.getDashboardCI.customerInitiatives || [],
    ciCount: data?.getDashboardCI.count,
    loading,
    error,
  };
};

export default useGetDashboardCIQuery;
