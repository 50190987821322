import { therapeuticAreaRelativesProducts } from 'basics/constants/common.constants';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { SelectOption } from 'basics/options/options.types';

const useProductOptions = (TAOption: string): SelectOption[] => {
  if (TAOption) {
    const TAProducts = therapeuticAreaRelativesProducts[TAOption as keyof typeof therapeuticAreaRelativesProducts];
    const productKeys = Object.keys(BiogenProduct).filter((key) => TAProducts.includes(BiogenProduct[key as keyof typeof BiogenProduct]));
    return productKeys.map((key) => ({ id: BiogenProduct[key as keyof typeof BiogenProduct], label: key }));
  }
  return Object.keys(BiogenProduct).map((key) => ({ id: BiogenProduct[key as keyof typeof BiogenProduct], label: key }));
};

export default useProductOptions;
