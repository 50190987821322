import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode, FC, useEffect } from 'react';
import { DataLayer } from 'basics/types/gtm';

type GTMProps = {
  children: ReactNode;
  gtmId: string;
};

export const useGTM = () => {
  const flags = useFlags();

  const dataLayer = (obj: DataLayer) => {
    if (!flags.mayaBdcfcsd1160EnablePianoAnalytics) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  };

  return dataLayer;
};

export const GTMProvider: FC<GTMProps> = ({ children, gtmId }) => {
  const flags = useFlags();

  useEffect(() => {
    const id = 'gtm';

    document.head.querySelector(`#${id}`)?.remove();

    if (flags.mayaBdcfcsd1160EnablePianoAnalytics) {
      const script = document.createElement('script');

      script.id = id;
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');`;
      document.head.appendChild(script);
    }
  }, [flags, gtmId]);

  return <>{ children }</>;
};
