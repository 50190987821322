import { ApolloError, gql, useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Products, DropMaterialsFilter } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_DROP_MATERIALS = gql`
  query getDropMaterials($filters: DropMaterialsFilter) {
    getDropMaterials(filters: $filters) {
      productID
      productName
      commercialFunctionFlag
      marketAccessFunctionFlag
      medicalFunctionFlag
    }
  }
`;

type ResultType = {
  getDropMaterials: Products[];
};

type UseGetDropMaterials = (filters: DropMaterialsFilter, skip?: boolean) => {
  loading: boolean;
  error?: ApolloError;
  data: Products[];
};

const useGetDropMaterials: UseGetDropMaterials = (filters: DropMaterialsFilter, skip = false) => {
  const flags = useFlags();
  const { data, loading, error } = useQuery<ResultType>(GET_DROP_MATERIALS, {
    fetchPolicy: 'cache-and-network',
    context: {
      clientName: MAYA_GRAPHQL_ENDPOINT,
    },
    skip: !flags.mayaBdcfcsd697EnableDropMaterials || skip,
    variables: {
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getDropMaterials.ts - useGetDropMaterials',
    });
  }

  return {
    data: data?.getDropMaterials || [],
    loading,
    error,
  };
};

export default useGetDropMaterials;
