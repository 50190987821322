import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TacticCard from 'components/TacticCard/TacticCard';
import { Tactic } from 'generated/maya.types';
import useTacticKanbanView from 'routes/AuthenticatedApp/CepView/Steps/TacticsView/TacticsView.hook';
import {
  TacticCardListStyle,
  TacticCardWrapperStyle,
  TacticKanbanStyle,
} from 'routes/AuthenticatedApp/CepView/Steps/TacticsView/TacticsView.styles';
import Box from 'yoda-ui/components/Box';
import Card from 'yoda-ui/components/Card/Card';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const TacticsView: FC = () => {
  const { t } = useTranslation();
  const {
    groupedTactics,
  } = useTacticKanbanView();

  return (
    <Box>
      <Box>
        <Box sx={ TacticKanbanStyle }>
          {
            Object.keys(groupedTactics).map((actionOrder: string) => {
              const tactics = groupedTactics[actionOrder];
              return (
                tactics.length > 0 && (
                  <Card key={ actionOrder } variant="outlined" sx={ TacticCardWrapperStyle }>
                    <Box marginBottom={ YodaSpacing.small }>
                      { `${t('maya_tactic_action_order')} ${actionOrder}` }
                    </Box>
                    <Box sx={ TacticCardListStyle }>
                      {
                        tactics.map((tactic: Tactic) => (
                          <TacticCard key={ tactic._id } tactic={ tactic } readOnly={ true } />
                        ))
                      }
                    </Box>
                  </Card>
                )
              );
            })
          }
        </Box>
      </Box>
    </Box>
  );
};

export default TacticsView;
