import { FC } from 'react';
import CepTargetListGroup from 'groups/CepFormTargetListGroup';

export type CepTargetListPropsType = {
  syncTargetsLoading: boolean;
};

const TargetListStep: FC<CepTargetListPropsType> = ({ syncTargetsLoading }) => {
  return (
    <>
      <CepTargetListGroup syncTargetsLoading={ syncTargetsLoading } />
    </>
  );
};

export default TargetListStep;
