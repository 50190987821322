import { FC } from 'react';
import Box from 'yoda-ui/components/Box';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type InfoTagProps = {
  title: string;
  field?: string;
};

const InfoTag: FC<InfoTagProps> = ({ title, field }) => (
  <Box marginBottom={ YodaSpacing.medium } fontWeight={ YodaFontWeight.medium } fontSize={ YodaFontSize.small }>
    <Box marginBottom={ YodaSpacing.xxxSmall }>
      { title }
    </Box>
    <Tag bgcolor={ YodaColors.blue2 } color={ YodaColors.gray5 } fontWeight={ YodaFontWeight.light } minHeight={ '21px' }>
      { field }
    </Tag>
  </Box>
);

export default InfoTag;
