import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { targetListFragment } from 'basics/graphql/fragments/targetList.fragments';
import { QueryOptionsType } from 'basics/types/common.types';
import { Target } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_TARGETLIST_BY_CEP_ID = gql`
query GetTargetListByCepIdQuery($cepId: String!, $limit: Int!, $offset: Int!) {
  getTargetListByCepId(cepId: $cepId, limit: $limit, offset: $offset) {
    targets {
    ${targetListFragment}
    }
    count
  }
}
`;

type ResultType = {
  getTargetListByCepId: {
    targets: Target[];
    count: number;
  };
};

type UseGetTargetListByCepId = (cepId: string | null, queryOptions?: QueryOptionsType) => {
  loading: boolean;
  error?: ApolloError;
  data: Target[];
  count: number;
};

const defaultTargetListValue: Target[] = [];

const useGetTargetListByCepIdQuery: UseGetTargetListByCepId = (cepId, queryOptions = { limit: 5000, offset: 0 }) => {
  const { limit, offset } = queryOptions;

  const { data, loading, error } = useQuery<ResultType>(GET_TARGETLIST_BY_CEP_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip: cepId === null,
    variables: {
      cepId,
      limit,
      offset,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getTargetListByCepId.ts - useGetTargetListByCepIdQuery',
    });
  }

  return {
    data: data?.getTargetListByCepId.targets || defaultTargetListValue,
    count: data?.getTargetListByCepId.count || 0,
    loading,
    error,
  };
};

export default useGetTargetListByCepIdQuery;
