import { cloneDeep } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { TacticStatus } from 'basics/enums/maya.enums';
import useGetAllTacticsQuery from 'basics/graphql/queries/getAllTactics';
import useGetTargetListByCepIdQuery from 'basics/graphql/queries/getTargetListByCepId';
import { CepStateType } from 'basics/types/maya.types';
import { FieldValue } from 'components/MiViewScreenFilterMenu/MiViewScreenFilterMenu.types';
import { Target, Tactic } from 'generated/maya.types';
import cepState from 'states/cep.states';

const useMiViewScreenTarget = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const [targets, setTargets] = useState<Target[]>([]);
  const [tactics, setTactics] = useState<Tactic[]>([]);
  const [nonChildTactics, setNonChildTactics] = useState<Tactic[]>([]);
  const [tacticFieldValues, setTacticFieldValues] = useState<FieldValue[]>([]);
  const [selectedTactic, setSelectedTactic] = useState<Tactic | null>(null);
  const [screenLoading, setScreenLoading] = useState<boolean>(true);

  const cepId = cepStateValue.cep?._id ? cepStateValue.cep._id : null;
  const cepTitle = cepStateValue.cep?.title ? cepStateValue.cep.title : null;

  const { data, loading } = useGetTargetListByCepIdQuery(cepId);

  const queryTacticFilters = useMemo(
    () => ({
      cep: cepId,
      status: [TacticStatus.DRAFT, TacticStatus.APPROVED],
    }),
    [cepId],
  );

  const { data: tacticsData, loading: tacticsLoading } = useGetAllTacticsQuery(undefined, queryTacticFilters);

  const transformTacticsToFieldValue = (tacticsValues: Tactic[]): FieldValue[] => {
    return tacticsValues.map((tactic) => ({
      id: tactic._id,
      value: tactic.title,
      filtered: true,
      count: 2,
    }));
  };

  const updateFilterField = (fieldName: string, fieldValue: string) => {
    const tactic = tactics.find((tacticItem) => tacticItem._id === fieldValue);
    setSelectedTactic(tactic || null);
  };

  const clearFilterField = () => {
    setSelectedTactic(null);
  };

  useEffect(() => {
    if (!loading) {
      const targetsList = cloneDeep(data);

      const sortedTargets = targetsList.sort((prevTarget, nextTarget) => {
        return `${prevTarget.firstName}`.localeCompare(`${nextTarget.firstName}`);
      });

      setTargets(sortedTargets);
      setScreenLoading(loading);
    }

    if (!tacticsLoading) {
      const tacticsList = cloneDeep(tacticsData);
      const sortedTactics = tacticsList.sort((prevTactic, nextTactic) => prevTactic.title.localeCompare(nextTactic.title));
      setTactics(sortedTactics);
      setNonChildTactics(sortedTactics?.filter((tactic) => !tactic.parentTactic));
    }
  }, [data, loading, tacticsData, tacticsLoading]);

  useEffect(() => {
    if (nonChildTactics.length) {
      setTacticFieldValues(transformTacticsToFieldValue(nonChildTactics));
    }
  }, [nonChildTactics, targets]);

  return { targets,
    tacticFieldValues,
    tactics,
    nonChildTactics,
    cepTitle,
    selectedTactic,
    updateFilterField,
    clearFilterField,
    screenLoading };
};

export default useMiViewScreenTarget;
