import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

export const boxStyle: SxProps<Theme> = {
  marginBottom: YodaSpacing.small,
};

export const alertStyle: SxProps<Theme> = {
  color: YodaColors.primaryBlue,
  '& .MuiAlert-icon': {
    color: YodaColors.primaryBlue,
  },
};
