import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MiViewColors, MiViewSpacing, MiViewFontSize } from 'miview-ui/miviewTheme';

export const formControlStyle: SxProps<Theme> = {
  m: MiViewSpacing.xxxSmall,
  minWidth: 100,
  pointerEvents: 'none',
};

export const selectStyle: SxProps<Theme> = {
  color: MiViewColors.tertiary,
  width: '100%',
  fontSize: MiViewFontSize.small,
  '& .MuiSelect-icon': {
    display: 'none',
  },
};

