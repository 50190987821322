/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import {
  MoreVert,
  Lock,
  LockOpen,
} from '@mui/icons-material';
import {
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  Divider,
  FormControl,
  RadioGroup,
  Chip,
  Box,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useMiViewTacticListRow from './MiViewTacticListRow.hook';
import {
  listItemStyle,
  avatarStyle,
  listItemTextStyle,
  boxStyle,
  childBoxStyle,
  formControlStyle,
  chipStyle,
  mainWrapperStyle,
  divCol1Style,
  divCol1ChildStyle,
  divCol2Style,
  divCol2ChildStyle,
  divCol3Style,
  divCol4Style,
  divSpacer,
  firstBadgeStyle,
  secondBadgeStyle,
  radioStyle,
  formControlLabelStyle,
  iconButtonStyle,
  iconButtonHiddenStyle,
} from './MiViewTacticListRow.style';
import { TacticListRowProps } from './MiViewTacticListRow.types';
import { Channel as TacticChannel } from 'basics/enums/maya.enums';
import MiViewBadgeAvatar from 'components/MiViewBadgeAvatar/MiViewScreenBadgeAvatar';
import { Tactic } from 'generated/maya.types';

const MiViewTacticListRow: FC<TacticListRowProps> = ({ id, style, childRecords, tactic, hideMenu }) => {
  const { t } = useTranslation();
  const {
    dateStart,
    dateEnd,
    pickChannelIcon,
    getTacticContent,
  } = useMiViewTacticListRow(tactic, childRecords || null);

  const IconComponent = pickChannelIcon();

  return (
    <div style={ { ...style, ...mainWrapperStyle } }>
      <ListItem
        sx={ listItemStyle }
        key={ id }
        alignItems="center"
        disablePadding
      >
        <div style={ divCol1Style }>
          <div style={ divCol1ChildStyle }>
            <MiViewBadgeAvatar
              tooltipMessage={ t('maya_cep_step_miview_preview_tooltip') } cursor={ 'pointer' }
              badgeList={
                [
                  {
                    content: `${tactic.actionOrder}`,
                    textColor: firstBadgeStyle.color,
                    backgroundColor: firstBadgeStyle.background,
                    location: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    invisible: false,
                  },
                  {
                    content: '0%',
                    textColor: secondBadgeStyle.color,
                    backgroundColor: secondBadgeStyle.background,
                    location: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    invisible: false,
                  },
                ]
              }
            >
              <Avatar
                sx={ avatarStyle }
                // eslint-disable-next-line react/no-children-prop
                children={ <IconComponent></IconComponent> }
              />
            </MiViewBadgeAvatar>
          </div>
          <div style={ divCol2Style }>
            <div style={ divCol2ChildStyle }>
              <ListItemText
                sx={ listItemTextStyle }
                primary={
                  `${childRecords && childRecords.length > 0
                    ? t('maya_cep_step_miview_preview_tactic_multi_name') : TacticChannel[tactic.channel]}`
                }
                secondary={ `${format(dateStart, 'MM/dd')} - ${format(dateEnd, 'MM/dd')}` }
              />
            </div>

            <div style={ divCol3Style }>
              {
                !childRecords || !childRecords?.length ? (
                  <ListItemText
                    sx={ listItemTextStyle }
                    primary={ `${tactic.title}` }
                    secondary={ `${getTacticContent(tactic)}` }
                  />
                ) : (
                  <Box sx={ boxStyle }>
                    <Box sx={ childBoxStyle }>
                      <FormControl sx={ formControlStyle } variant="standard">
                        <RadioGroup>
                          {
                            childRecords.map((record: Tactic, index) => {
                              return (
                                <FormControlLabel
                                  key={ index }
                                  value={ record.tacticId }
                                  control={ <Radio size="small" sx={ radioStyle } /> }
                                  label={ record.title }
                                  disabled
                                  sx={ formControlLabelStyle }
                                />
                              );
                            })
                          }
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                )
              }

            </div>
            <div style={ divCol4Style }>
              <Chip
                sx={ chipStyle }
                label={ '' }
                color="warning"
                size="small"
                variant="outlined"
                icon={ tactic.mandatory ? <Lock /> : <LockOpen /> }
              />
            </div>

            <IconButton edge="end" sx={ hideMenu ? iconButtonHiddenStyle : iconButtonStyle } disabled={ true }>
              <MoreVert />
            </IconButton>
          </div>
        </div>
        <div style={ divSpacer } />
      </ListItem>
      <Divider />
    </div>
  );
};

export default MiViewTacticListRow;
