import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthenticatedApp from './AuthenticatedApp';
import Login from './Login';
import { encrypt } from 'basics/utils/encrypt';
import { useGTM } from 'contexts/gtm';
import { useCurrentUserState } from 'contexts/user';
import { useTitle } from 'hooks/useTitle';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';

const Routes = () => {
  const { userLoading, user } = useCurrentUserState();

  const location = useLocation();
  const dataLayer = useGTM();
  const title = useTitle();

  useEffect(() => {
    if (title) {
      const url = `${location.pathname}${location.search}`;

      dataLayer({
        event: 'page_view',
        virtual_page_title: title,
        virtual_page_url: url,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    if (user) {
      const oktaTokenOld = String(localStorage.getItem('okta_token.old_token'));
      const oktaTokenCurrent = localStorage.getItem('okta_token.token');

      if (oktaTokenCurrent) {
        if (JSON.parse(oktaTokenOld)?.idToken !== JSON.parse(oktaTokenCurrent)?.idToken) {
          dataLayer({
            event: 'login',
            user_id: encrypt(user.email),
            visitor_id: user.id,
          });

          localStorage.setItem('okta_token.old_token', oktaTokenCurrent);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading]);

  if (userLoading) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  return user ? <AuthenticatedApp /> : <Login />;
};

export default Routes;
