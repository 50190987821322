import { KeyMessage } from 'generated/maya.types';

const useKeyMessagesOptions = (keyMessages: KeyMessage[]) => {
  const keyMessagesOptions = keyMessages.map((element: KeyMessage) => {
    return {
      id: element.ID,
      label: element.KeyName as string,
      medical: element?.Medical || false,
      commercial: element?.Commercial || false,
      marketAccess: element?.MarketAccess || false,
    };
  });

  return keyMessagesOptions;
};

export default useKeyMessagesOptions;
