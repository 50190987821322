import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';
import { YodaColors } from 'yoda-ui/yodaTheme';

export const TacticDialogStyle: SxProps<Theme> = {
  '& .MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '25px',
  },

  '& .tacticFormButtonsWrapper': {
    margin: '0 20px 20px 20px',
  },
};

export const ClosingCrossStyle: CSSProperties = {
  fill: YodaColors.gray3,
  cursor: 'pointer',
  position: 'absolute',
  top: '10px',
  right: '10px',
};
