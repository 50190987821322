import { proxy } from 'valtio';
import { TacticStateType } from 'basics/types/maya.types';

const tacticState = proxy<TacticStateType>({
  tactic: null,
  mode: null,
  cepId: '',
});

export default tacticState;
