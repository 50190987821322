import { Box } from '@mui/material';

import { FC } from 'react';
import { CepCreationMode } from 'basics/enums/cep.enums';
import CepTemplateSelectorFilters from 'components/CepTemplateSelectorFilters/CepTemplateSelectorFilters';
import useCepTemplateSelectorForm from 'components/CepTemplateSelectorForm/CepTemplateSelectorForm.hook';
import { cepTemplateCreationModeStyle } from 'components/CepTemplateSelectorForm/CepTemplateSelectorForm.styles';
import CepTemplateSelectorList from 'components/CepTemplateSelectorList/CepTemplateSelectorList';
import FormContainer from 'components/FormContainer/FormContainer';
import { FormLayoutContainer } from 'components/FormLayout';
import RadioGroup from 'yoda-ui/components/Form/RadioGroup/RadioGroup';
import { YodaJustifyContent } from 'yoda-ui/yodaTheme';

const CepTemplateSelectorForm: FC = () => {
  const {
    cepCreationMode,
    cepTemplateSelectorFilters,
    cepTemplateSelectorFormConfig,
    providerFields,
    setCepTemplateSelectorFilters,
    submitButtonConfig,
  } = useCepTemplateSelectorForm();

  return (
    <>
      <FormContainer
        providerFields={ providerFields }
        { ...(cepCreationMode === CepCreationMode.custom ? { submitButtonConfig } : {}) }
      >
        <FormLayoutContainer justifyContent={ YodaJustifyContent.center } >
          <Box>
            <RadioGroup { ...cepTemplateSelectorFormConfig.selectCreationMode } sx={ cepTemplateCreationModeStyle } />
          </Box>
        </FormLayoutContainer>
      </FormContainer>

      {
        cepCreationMode === CepCreationMode.template && (
          <>
            <CepTemplateSelectorFilters setCepTemplateSelectorFilters={ setCepTemplateSelectorFilters } />
            <CepTemplateSelectorList cepTemplateSelectorFilters={ cepTemplateSelectorFilters } />
          </>
        )
      }
    </>
  );
};

export default CepTemplateSelectorForm;
