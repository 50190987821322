import { FC, useRef, useState } from 'react';
import styles from './MoreActionsMenu.styles';
import { MoreActionsMenuProps } from './MoreActionsMenu.types';
import MoreActionsMenuItem from './MoreActionsMenuItem';
import Icon, { Icons } from 'yoda-ui/components/Icons/Icon';
import IconButton from 'yoda-ui/components/Icons/IconButton';
import Menu from 'yoda-ui/components/Menu';

const isLast = (index: number, arr: unknown[]) => index >= arr.length - 1;

const MoreActionsMenu: FC<MoreActionsMenuProps> = ({ menuItemConfigs }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const menuItems = menuItemConfigs.map((menuItemConfig, index) => (
    menuItemConfig?.hideItem ? null : (<MoreActionsMenuItem
      key={ menuItemConfig?.id || menuItemConfig?.label }
      menuItemConfig={ menuItemConfig }
      isLast={ isLast(index, menuItemConfigs) }
      closeMenu={ () => setOpen(false) }
    />)
  ));

  return (
    <>
      <IconButton ref={ buttonRef } onClick={ () => setOpen(true) }>
        <Icon
          name={ Icons.moreVertical }
          fontSize='large'
        />
      </IconButton>
      <Menu
        anchorOrigin={
          {
            vertical: 'bottom',
            horizontal: 'center',
          }
        }
        transformOrigin={
          {
            vertical: 'top',
            horizontal: 'center',
          }
        }
        sx={ styles.menu }
        open={ open }
        onClose={ () => setOpen(false) }
        anchorEl={ buttonRef.current }
      >
        { menuItems }
      </Menu>
    </>
  );
};

export default MoreActionsMenu;
