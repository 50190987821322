/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { isLocal } from 'config/config';

type SentryTags = { [key: string]: string };

export type CustomContext = Record<string, unknown>;

type LoggerInfo = {
  customContext?: CustomContext;
  transactionName?: string;
  tags?: SentryTags;
};

type LoggerData = LoggerInfo & {
  message: string;
};

type LoggerErrorData = LoggerInfo & {
  error: Error;
};

type SetScopeDetails = (
  scope: Sentry.Scope,
  tags: SentryTags,
  transactionName?: string
) => Sentry.Scope;
const setScopeDetails: SetScopeDetails = (scope, tags, transactionName) => {
  Object.entries(tags).forEach(([key, value]) => {
    scope.setTag(key, value);
  });
  if (transactionName) {
    scope.setTransactionName(transactionName);
  }
  return scope;
};

const Logger = {
  logError(loggerErrorData: LoggerErrorData): void {
    const {
      error,
      transactionName,
      customContext = {},
      tags = {},
    } = loggerErrorData;
    if (!isLocal) {
      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Error);
        scope = setScopeDetails(scope, tags, transactionName);
        Sentry.setContext('context', { ...customContext });
        Sentry.captureException(error);
      });
    } else {
      console.log(error.message);
      console.error(error);
    }
  },
  logWarning(loggerData: LoggerData): void {
    const {
      message,
      transactionName,
      customContext = {},
      tags = {},
    } = loggerData;
    if (!isLocal) {
      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Warning);
        scope = setScopeDetails(scope, tags, transactionName);
        Sentry.setContext('context', { ...customContext });
        Sentry.captureMessage(message);
      });
    } else {
      console.warn(message);
      console.dir(customContext);
    }
  },
  logInfo(loggerData: LoggerData): void {
    const {
      message,
      transactionName,
      customContext = {},
      tags = {},
    } = loggerData;
    if (!isLocal) {
      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Info);
        scope = setScopeDetails(scope, tags, transactionName);
        Sentry.setContext('context', { ...customContext });
        Sentry.captureMessage(message);
      });
    } else {
      console.log(message);
      console.dir(customContext);
    }
  },
};
export default Logger;
