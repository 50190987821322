import Box from 'yoda-ui/components/Box';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const FormFieldTitle = ({ title }: { title: string }) => (
  <Box
    marginBottom={ YodaSpacing.medium }
    fontSize={ YodaFontSize.large }
    fontWeight={ YodaFontWeight.medium }
  >
    { title }
  </Box>
);

export default FormFieldTitle;
