import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerInitiativeForm from 'components/CustomerInitiativeForm/CustomerInitiativeForm';
import Heading from 'components/Heading';
import MayaLayout from 'components/MayaLayout';
import useCiTemplateCreate from 'routes/AuthenticatedApp/CiTemplateCreate/CiTemplateCreate.hook';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';

const CiTemplateCreate: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    parentStrategicImperative,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  } = useCiTemplateCreate();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_ci_tpl_form_title') }
      </Heading>

      {
        loading
          ? (

            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Loader center />
            </Box>
          )
          : (
            <CustomerInitiativeForm {
              ...{
                parentStrategicImperative,
                submitCallback: handleSaveCustomerInitiativeForm,
                cancelCallback: handleCancelCustomerInitiativeForm,
              }
            } />
          )
      }
    </MayaLayout>
  );
};

export default CiTemplateCreate;
