import { ProviderConfig } from 'launchdarkly-react-client-sdk/lib/types';
import { getConfigVar } from 'config/config';

const clientSideID: string = getConfigVar('REACT_APP_LAUNCHDARKLY_CLIENT_ID') ?? '';
export const launchDarklyConfig: ProviderConfig = {
  clientSideID,
  user: {
    anonymous: true,
  },
  options: {
    bootstrap: 'localStorage',
  },
};
