import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CAMPAIGN_YEAR } from 'basics/constants/common.constant';
import { mayaStrategicImperativeListRoute } from 'basics/constants/routes.constants';
import { useCreateStrategicImperativeMutation } from 'basics/graphql/mutations/createStrategicImperatives';
import { succesToast, warningToast } from 'basics/utils/toast';
import { SiFormSubmitCallbackType } from 'components/StrategicImperativeForm/StrategicImperativeForm.types';
import { useGTM } from 'contexts/gtm';

const useStrategicImperativeCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dataLayer = useGTM();

  const [createStrategicImperative] = useCreateStrategicImperativeMutation();

  const handleCancelStrategicImperativeForm = () => {
    navigate(`${mayaStrategicImperativeListRoute}?year=${CAMPAIGN_YEAR}`);
  };

  const handleSaveStrategicImperativeForm: SiFormSubmitCallbackType = async (si) => {
    const { data: strategicImperativeData, error } = await createStrategicImperative(si);

    if (strategicImperativeData && !error) {
      dataLayer({
        event: 'strategic_imperatives',
        step: 'initiation',
      });
      succesToast(t('maya_si_created', { title: strategicImperativeData.title }));
      navigate(`${mayaStrategicImperativeListRoute}?year=${strategicImperativeData.year}`);
    } else {
      warningToast(error || t('errors_standard'));
      navigate(`${mayaStrategicImperativeListRoute}?year=${si.year}`);
    }
  };

  return {
    handleCancelStrategicImperativeForm,
    handleSaveStrategicImperativeForm,
  };
};

export default useStrategicImperativeCreate;
