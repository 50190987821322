import { array, mixed } from 'yup';
import { Channel, CustomerTypes, Specialities } from 'basics/enums/maya.enums';

const useCepTemplateSelectorFiltersSchema = () => {
  return {
    customerType: array()
      .of(mixed<CustomerTypes>().oneOf(Object.values(CustomerTypes))),
    specialty: array()
      .of(mixed<Specialities>().oneOf(Object.values(Specialities))),
    channel: array()
      .of(mixed<Channel>().oneOf(Object.values(Channel))),
  };
};

export default useCepTemplateSelectorFiltersSchema;
