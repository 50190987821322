import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { strategicImperativeFragment } from 'basics/graphql/fragments/strategicImperative.fragments';
import { StrategicImperative } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_STRATEGIC_IMPERATIVES_BY_ID = gql`
query GetStrategicImperativesByIdQuery($id: String!) {
  getStrategicImperativeById(id: $id) {
    ${strategicImperativeFragment}
  }
}
`;

type ResultType = {
  getStrategicImperativeById: StrategicImperative;
};

type UseGetStrategicImperativeById = (strategicImperativeId: string | null) => {
  loading: boolean;
  error?: ApolloError;
  data?: StrategicImperative;
};

const useGetStrategicImperativeByIdQuery: UseGetStrategicImperativeById = (strategicImperativeId) => {
  const { data, loading, error } = useQuery<ResultType>(GET_STRATEGIC_IMPERATIVES_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip: !strategicImperativeId,
    variables: {
      id: strategicImperativeId,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getStrategicImperativeById.ts - useGetStrategicImperativeByIdQuery',
    });
  }

  return {
    data: data?.getStrategicImperativeById,
    loading,
    error,
  };
};

export default useGetStrategicImperativeByIdQuery;
