import { useLocation, useNavigate } from 'react-router-dom';
import useGetFacetCiYearsQuery from 'basics/graphql/queries/getFacetCiYears';
import useGetFacetStrategicImperativeYearsQuery from 'basics/graphql/queries/getFacetStrategicImperativesYears';
import useMayaMenuConfig from 'components/MayaMenu/MayaMenu.config';

const useMayaMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentLocation = `${location.pathname}${location.search}`;

  const { data: siYear } = useGetFacetStrategicImperativeYearsQuery();
  const { data: ciYear } = useGetFacetCiYearsQuery();

  const { configMenu } = useMayaMenuConfig(
    ciYear,
    siYear,
  );

  return {
    configMenu,
    currentLocation,
    navigate,
  };
};

export default useMayaMenu;
