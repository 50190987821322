import { SelectOption } from 'basics/options/options.types';
import { TargetsFacets } from 'generated/maya.types';

const useTargetsFiltersOptions = (targetFilter: TargetsFacets): {
  types?: SelectOption[];
  countries?: SelectOption[];
  hcoNames?: SelectOption[];
  businessUnits?: SelectOption[];
  specialties?: SelectOption[];
  smaSegments?: SelectOption[];
  biosSegments?: SelectOption[];
  imsSegments?: SelectOption[];
  consent?: SelectOption[];
} => {
  if (!targetFilter) {
    return {};
  }

  const typesOptions = targetFilter.types ? targetFilter.types.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const countriesOptions = targetFilter.country ? targetFilter.country.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const hcoNamesOptions = targetFilter.hcoNames ? targetFilter.hcoNames.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const businessUnitsOptions = targetFilter.businessUnits ? targetFilter.businessUnits.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const specialtiesOptions = targetFilter.specialties ? targetFilter.specialties.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const smaSegmentsOptions = targetFilter.smaSegments ? targetFilter.smaSegments.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const biosSegmentsOptions = targetFilter.biosSegments ? targetFilter.biosSegments.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const imsSegmentsOptions = targetFilter.imsSegments ? targetFilter.imsSegments.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const consentOptions = targetFilter.consent ? targetFilter.consent.map((element: string) => {
    return {
      id: element,
      label: element,
    };
  }) : [];

  const targetsFiltersOptions = {
    biosSegments: biosSegmentsOptions,
    businessUnits: businessUnitsOptions,
    countries: countriesOptions,
    hcoNames: hcoNamesOptions,
    imsSegments: imsSegmentsOptions,
    smaSegments: smaSegmentsOptions,
    specialties: specialtiesOptions,
    types: typesOptions,
    consent: consentOptions,
  };

  return targetsFiltersOptions;
};

export default useTargetsFiltersOptions;
