import { CSSProperties } from 'react';
import { MiViewColors } from 'miview-ui/miviewTheme';

export const paperStyle: CSSProperties = {
  color: MiViewColors.secondary,
  border: `1px solid ${MiViewColors.primary}`,
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '100vh',
};

export const screenStyle: CSSProperties = {
  margin: '0px',
  position: 'relative',
  top: 'auto',
  left: 'auto',
  width: 'auto',
  zIndex: 0,
};
