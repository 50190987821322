import { YodaBorderRadius } from 'yoda-ui/yodaTheme';

const styles = {
  menu: {
    '.MuiMenu-list': {
      padding: 0,
    },
    '.MuiPaper-root': {
      borderRadius: `0 0 ${YodaBorderRadius.small} ${YodaBorderRadius.small}`,
    },
  },
};

export default styles;
