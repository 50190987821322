import { CustomerTypes } from 'basics/enums/maya.enums';
import { SelectOption } from 'basics/options/options.types';

const customerTypeOptions: SelectOption[] = [
  { id: CustomerTypes.advocacyGroups, label: 'Advocacy groups' },
  { id: CustomerTypes.caregiver, label: 'Caregiver' },
  { id: CustomerTypes.insurances, label: 'Insurances' },
  { id: CustomerTypes.internalAudience, label: 'Internal Audience' },
  { id: CustomerTypes.KMEs, label: 'KMEs' },
  { id: CustomerTypes.nurses, label: 'Nurses' },
  { id: CustomerTypes.other, label: 'Other' },
  { id: CustomerTypes.patient, label: 'Patient' },
  { id: CustomerTypes.patientsCaregivers, label: 'Patients/Caregivers' },
  { id: CustomerTypes.payers, label: 'Payers' },
  { id: CustomerTypes.pharmacists, label: 'Pharmacists' },
  { id: CustomerTypes.physiciansPrimaryCare, label: 'Physicians - primary care' },
  { id: CustomerTypes.physiciansSpecialists, label: 'Physicians - specialists' },
  { id: CustomerTypes.physiotherapists, label: 'Physiotherapists' },
  { id: CustomerTypes.policyMakers, label: 'Policy Makers' },
  { id: CustomerTypes.public, label: 'Public' },
];

export default customerTypeOptions;
