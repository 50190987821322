import { useTranslation } from 'react-i18next';
import initiativeCountryOptions from 'basics/options/country.options';

export const useCountrySelectorConfig = (country: string | null) => {
  const { t } = useTranslation();

  const countrySelectorConfig = {
    label: t('maya_my_country_label'),
    name: 'countrySelector',
    placeholder: t('maya_choose_country_label'),
    options: [...initiativeCountryOptions, { id: 'AC', label: 'All countries' }],
    defaultValue: country,
  };

  return {
    countrySelectorConfig,
  };
};
