import { FC } from 'react';
import useStrategicImperativeCreate from './StrategicImperativeForm.hook';
import FormContainer from 'components/FormContainer/FormContainer';
import { FormLayoutContainer } from 'components/FormLayout';
import { StrategicImperativeFormPropsType } from 'components/StrategicImperativeForm/StrategicImperativeForm.types';
import Box from 'yoda-ui/components/Box';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const StrategicImperativeForm: FC<StrategicImperativeFormPropsType> = ({
  strategicImperative,
  submitCallback,
  cancelCallback,
}) => {
  const {
    strategicImperativeFormConfig,
    providerFields,
    cancelButtonConfig,
    submitButtonConfig,
  } = useStrategicImperativeCreate(strategicImperative, submitCallback, cancelCallback);

  return (
    <FormContainer providerFields={ providerFields } cancelButtonConfig={ cancelButtonConfig } submitButtonConfig={ submitButtonConfig } >
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } flexDirection="column" >
        <Box>
          <TextYoda { ...strategicImperativeFormConfig.title } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <SelectYoda { ...strategicImperativeFormConfig.therapeuticArea } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <TextYoda { ...strategicImperativeFormConfig.description } />
        </Box>
      </FormLayoutContainer>
    </FormContainer>
  );
};

export default StrategicImperativeForm;
