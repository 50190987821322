import { t } from 'i18next';
import { FC } from 'react';
import { CustomerInitiativeFormPropsType } from './CustomerInitiativeForm.types';
import useCustomerInitiativeCreate from 'components/CustomerInitiativeForm/CustomerInitiativeForm.hook';
import FormContainer from 'components/FormContainer/FormContainer';
import { FormLayoutContainer } from 'components/FormLayout';
import InfoTag from 'components/InfoTag/InfoTag';
import Box from 'yoda-ui/components/Box';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Loader from 'yoda-ui/components/Loader';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const CustomerInitiativeForm: FC<CustomerInitiativeFormPropsType> = ({
  parentStrategicImperative,
  customerInitiative,
  submitCallback,
  cancelCallback,
}) => {
  const {
    cancelButtonConfig,
    customerInitiativeFormConfig,
    handleChangeProduct,
    handleChangeStrategicImperative,
    handleChangeTA,
    providerFields,
    siLoading = false,
    submitButtonConfig,
  } = useCustomerInitiativeCreate(parentStrategicImperative, customerInitiative, submitCallback, cancelCallback);

  return (
    <FormContainer providerFields={ providerFields } cancelButtonConfig={ cancelButtonConfig } submitButtonConfig={ submitButtonConfig }>
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } flexDirection="column" >
        <Box >
          <TextYoda { ...customerInitiativeFormConfig.title } />
        </Box>
        <Box paddingTop={ YodaSpacing.large } >
          <SelectYoda { ...customerInitiativeFormConfig.country } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <DateRangePicker { ...customerInitiativeFormConfig.date } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <TextYoda { ...customerInitiativeFormConfig.description } />
        </Box>
        <Box paddingTop={ YodaSpacing.large } >
          <TextYoda { ...customerInitiativeFormConfig.solutionCoreStory } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <SelectYoda { ...customerInitiativeFormConfig.strategicImperative } onChange={ handleChangeStrategicImperative } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          {
            siLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <SelectYoda
            { ...customerInitiativeFormConfig.therapeuticArea }
            disabled={ siLoading || customerInitiativeFormConfig.therapeuticArea.disabled }
            onChange={ handleChangeTA }
          />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <SelectYoda { ...customerInitiativeFormConfig.product } onChange={ handleChangeProduct } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <SelectYoda { ...customerInitiativeFormConfig.functionalOwner } />
        </Box>
        <Box paddingTop={ YodaSpacing.large }>
          <MultiSelectYoda { ...customerInitiativeFormConfig.functionsInvolved } />
        </Box>
      </FormLayoutContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        marginTop={ YodaSpacing.large }
      >
        <InfoTag
          title={ t('maya_ci_engagement') }
          field={ customerInitiative?.goals.engagement.toString() }
        />
        <InfoTag
          title={ t('maya_ci_reach') }
          field={ customerInitiative?.goals.reach ? `${customerInitiative?.goals.reach.toString()}%` : undefined }
        />
      </Box>
    </FormContainer>
  );
};

export default CustomerInitiativeForm;
