import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { MutationReturnType } from 'basics/types/maya.types';
import { UserData } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createOrUpdateUserDataMutationCall = gql`
  mutation createUserData($userData: UserDataInput!){
    createOrUpdateUserData(userData: $userData){
      country
    }
  }
`;

type ResultType = {
  createOrUpdateUserData: UserData;
};

type UseCreateOrUpdateMayaUserDataMutation = [
  (userData: Partial<UserData>) => Promise<MutationReturnType<UserData>>,
  MutationResult<ResultType>,
];

export const useCreateOrUpdateMayaUserDataMutation = (): UseCreateOrUpdateMayaUserDataMutation => {
  const [createOrUpdateUserData, state] = useMutation<ResultType>(createOrUpdateUserDataMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (userData) => {
    try {
      const { data } = await createOrUpdateUserData({
        variables: {
          userData,
        },
      });

      return { data: data?.createOrUpdateUserData || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, userData);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createOrUpdateMayaUserData.ts - useCreateOrUpdateMayaUserDataMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [createOrUpdateUserData]);

  return [executeMutation, state];
};
