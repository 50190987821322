import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MiViewColors, MiViewFontSize, MiViewSpacing } from 'miview-ui/miviewTheme';

export const enabledIconStyle: SxProps<Theme> = {
  color: MiViewColors.tertiary,
  fontSize: MiViewFontSize.large,
  marginRight: MiViewSpacing.xxSmall,
};

export const disabledIconStyle: SxProps<Theme> = {
  color: MiViewColors.lightGray,
  fontSize: MiViewFontSize.large,
  marginRight: MiViewSpacing.xxSmall,
};

export const boxStyle: SxProps<Theme> = {
  position: 'relative',
  top: '30%',
  textAlign: 'center',
  color: MiViewColors.secondary,
  fontSize: MiViewFontSize.xxxLarge,
};
