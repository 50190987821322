import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import useCepForm from './CepForm.hook';
import ConfigurationStep from './Steps/ConfigurationStep';
import TacticsStep from './Steps/TacticsStep/TacticsStep';
import ButtonsBar from 'components/ButtonsBar';
import { CepFormPropsType, CepFormStepsIndex } from 'components/CepForm/CepForm.types';
import GoalStep from 'components/CepForm/Steps/GoalStep';
import MiViewPreviewStep from 'components/CepForm/Steps/MiViewPreviewStep';
import TargetListStep from 'components/CepForm/Steps/TargetListStep';
import {
  FormLayoutContainer,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
  FormLayoutMidPanelHeader,
  FormLayoutRightPanel,
} from 'components/FormLayout';
import SideFormDetails from 'components/SideFormDetails';
import Box from 'yoda-ui/components/Box';
import Stepper from 'yoda-ui/components/Stepper/Stepper';
import { YodaFormProvider } from 'yoda-ui/yodaForm';

const CepForm: FC<CepFormPropsType> = ({ submitCallback, isTemplate = false }) => {
  const {
    handleChangeStep,
    yodaFormMethods,
    stepIndexer,
    buttonsConfig,
    stepsList,
    syncTargetsLoading,
  } = useCepForm(submitCallback, isTemplate);
  const flags = useFlags();

  return (
    <YodaFormProvider { ...yodaFormMethods.providerFields }>
      <FormLayoutContainer>
        <FormLayoutMidPanel >
          <FormLayoutMidPanelHeader>
            <Stepper
              handleChangeStep={ handleChangeStep }
              stepsList={ stepsList }
              stepIndex={ stepIndexer.stepIndex }
            />
          </FormLayoutMidPanelHeader>
          <FormLayoutMidPanelContent>
            {
              stepIndexer.stepIndex === CepFormStepsIndex.configurationStep
                ? (
                  <Box width="100%">
                    <ConfigurationStep isTemplate={ isTemplate }/>
                  </Box>
                )
                : null
            }
            {
              stepIndexer.stepIndex === CepFormStepsIndex.targetListStep
                ? (
                  <Box width="100%">
                    <TargetListStep syncTargetsLoading={ syncTargetsLoading }/>
                  </Box>
                )
                : null
            }
            {
              stepIndexer.stepIndex === CepFormStepsIndex.tacticsStep
                ? (
                  <Box width="100%">
                    <TacticsStep isTemplate={ isTemplate }/>
                  </Box>
                )
                : null
            }
            {
              flags.mayaBiogenlinc4793EnableGoalStep && stepIndexer.stepIndex === CepFormStepsIndex.goalStep
                ? (
                  <Box width="100%">
                    <GoalStep />
                  </Box>
                )
                : null
            }
            {
              flags.mayaBdcfcsd714EnableMiViewPreview && stepIndexer.stepIndex === CepFormStepsIndex.miViewPreviewStep
                ? (
                  <Box width="100%">
                    <MiViewPreviewStep />
                  </Box>
                )
                : null
            }
            { buttonsConfig && <ButtonsBar { ...buttonsConfig } /> }
          </FormLayoutMidPanelContent>
        </FormLayoutMidPanel>

        <FormLayoutRightPanel >
          <SideFormDetails />
        </FormLayoutRightPanel>

      </FormLayoutContainer>
    </YodaFormProvider>
  );
};

export default CepForm;
