import { FC } from 'react';
import { formLayoutStickyStyles } from './FormLayout.styles';
import Box from 'yoda-ui/components/Box';

const FormLayoutMidPanelHeader: FC = ({ children }) => {
  return (
    <Box
      width="25%"
      sx={ formLayoutStickyStyles }
    >
      { children }
    </Box>
  );
};

export default FormLayoutMidPanelHeader;
