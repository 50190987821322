import { useTranslation } from 'react-i18next';
import { string } from 'yup';

const useCepFormGoalGroupSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  return {
    targetEngagementGoal: string()
      .test({
        name: 'targetEngagementGoal',
        message: t('maya_targetEngagementGoal_error', { min: 0 }),
        test: (value) => {
          return !!(value && parseInt(value, 10) >= 0);
        },
      })
      .required(requiredLabel),
    npsGoal: string()
      .test({
        name: 'npsGoal',
        message: t('maya_npsGoal_error', { min: 0, max: 100 }),
        test: (value) => {
          return !!(!value || (value && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100));
        },
      })
      .notRequired(),
    reachGoal: string()
      .test({
        name: 'reachGoal',
        message: t('maya_reachGoal_error', { min: 0, max: 100 }),
        test: (value) => {
          return !!(!value || (value && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100));
        },
      })
      .required(requiredLabel),
  };
};

export default useCepFormGoalGroupSchema;
