import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import GridListAction from 'components/GridListAction/GridListAction';

const useCepTemplateListConfig = (
  editCepTemplateCallback: (cepId: string) => void,
  CepFromTemplateCallback: (cepId: string) => void,
  openModalCallback: (cepId: string) => void,
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const cepTitleFieldConfig = {
    label: t('maya_cep_tpl_title_label'),
    name: 'name',
    placeholder: t('maya_cep_tpl_title_placeholder'),
    defaultValue: '',
    required: false,
  };

  const cepListColumnConfig: GridColDef[] = [
    {
      field: 'title',
      headerName: t('maya_cep_list_title_header_label'),
      flex: 0.3,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'formattedCepId',
      headerName: t('maya_cep_list_cep_id_header_label'),
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const actions = [
          {
            label: t('actions_edit_label'),
            callback: () => { editCepTemplateCallback(params.row._id); },
          },
          {
            label: t('actions_create_cep_from_tpl'),
            callback: () => { CepFromTemplateCallback(params.row._id); },
          },
        ];

        if (flags.mayaBiogenlinc3595EnableCepDeleting) {
          actions.push({
            label: t('maya_action_delete_cep_label'),
            callback: () => {
              openModalCallback(params.row._id);
            },
          });
        }

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.05,
    },
  ];

  return {
    cepListColumnConfig,
    cepTitleFieldConfig,
  };
};

export default useCepTemplateListConfig;
