import { ApolloLink, Observable, Operation, FetchResult, NextLink, Observer } from '@apollo/client';

type QueueItem = {
  operation: Operation;
  forward: NextLink;
  observer: Observer<FetchResult>;
};

type QueueOptions = {
  activeCount: number;
  limit: number;
  queue: QueueItem[];
};

type Queue = {
  [key: string]: QueueOptions;
};

class QueueLink extends ApolloLink {
  private queue: Queue = {};

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    const { queue = false, limit = 1 } = operation.getContext();

    if (!queue) {
      return forward(operation);
    }

    return new Observable<FetchResult>((observer) => {
      const requestName = operation.operationName;

      if (!this.queue[requestName]) {
        this.queue[requestName] = {
          limit,
          queue: [],
          activeCount: 0,
        };
      }

      this.queue[requestName].queue.push({ operation, forward, observer });
      this.processQueue(requestName);
    });
  }

  private processQueue(requestName: string): void {
    const item = this.queue[requestName];
    const { queue, activeCount, limit } = item;

    if (activeCount >= limit || queue.length === 0) {
      return;
    }

    while (activeCount < limit && queue.length > 0) {
      const queueItem = queue.shift();

      if (!queueItem) {
        break;
      }

      item.activeCount += 1;

      const { operation, forward, observer } = queueItem;

      forward(operation).subscribe({
        next: (result: FetchResult) => {
          if (observer.next) {
            observer.next(result);
          }
        },
        error: (error) => {
          if (observer.error) {
            observer.error(error);
          }
        },
        complete: () => {
          if (observer.complete) {
            observer.complete();
          }

          item.activeCount -= 1;
          this.processQueue(requestName);
        },
      });
    }
  }
}

export default QueueLink;
