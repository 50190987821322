import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mayaStrategicImperativeListRoute } from 'basics/constants/routes.constants';
import {
  FormLayoutContainer,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
  FormLayoutMidPanelHeader,
  FormLayoutRightPanel,
} from 'components/FormLayout';
import Heading from 'components/Heading';
import InfoTag from 'components/InfoTag/InfoTag';
import MayaLayout from 'components/MayaLayout';
import useStrategicImperativeView from 'routes/AuthenticatedApp/StrategicImperativeView/StrategicImperativeView.hook';
import { buttonStyle, sideContainerStyle } from 'routes/AuthenticatedApp/StrategicImperativeView/StrategicImperativeView.styles';
import { ActionButton, ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import Loader from 'yoda-ui/components/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const StrategicImperativeView: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    navigate,
    strategicImperative,
  } = useStrategicImperativeView();

  return (
    <MayaLayout>
      <FormLayoutContainer>
        <FormLayoutMidPanel >

          <FormLayoutMidPanelHeader>
          </FormLayoutMidPanelHeader>

          <FormLayoutMidPanelContent>
            <Heading>
              { t('maya_si_view') }
            </Heading>

            {
              loading
                ? (
                  <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    <Loader center />
                  </Box>
                )
                : (
                  <Box>
                    <Box
                      sx={ sideContainerStyle.firstBox }
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      padding={ YodaSpacing.medium }
                    >
                      <Box marginBottom={ YodaSpacing.xxSmall } marginTop={ YodaSpacing.xxSmall }>
                        <Box>
                          <InfoTag
                            title={ t('maya_si_view_title') }
                            field={ strategicImperative?.title }
                          />
                          <InfoTag
                            title={ t('maya_si_view_therapeutic_area') }
                            field={ strategicImperative?.therapeuticArea }
                          />
                          <InfoTag
                            title={ t('maya_si_view_description') }
                            field={ strategicImperative?.description }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
            }
            <ActionButton
              label={ t('actions_back_label') }
              icon={ Icons.chevronLeft }
              onClick={ () => { navigate(`${mayaStrategicImperativeListRoute}?year=${strategicImperative?.year}`); } }
              variant={ ButtonVariant.primary }
              sx={ buttonStyle }
            />
          </FormLayoutMidPanelContent>

          <FormLayoutRightPanel>
          </FormLayoutRightPanel>

        </FormLayoutMidPanel>
      </FormLayoutContainer>
    </MayaLayout>
  );
};

export default StrategicImperativeView;

