import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_TARGETS_ENGAGEMENT = gql`
query getTargetsEngagement($filters: DashboardFilter!) {
  getTargetsEngagement(filters: $filters)
}
`;

type ResultType = {
  getTargetsEngagement: number;
};

type UseGetTargetsEngagement = () => {
  getTargetsEngagementQuery: Function;
  loading: boolean;
  error?: ApolloError;
  targetsEngagement: unknown;
};

const useGetTargetsEngagementQuery: UseGetTargetsEngagement = () => {
  const [getTargetsEngagementQuery, { data, loading, error }] = useLazyQuery<ResultType>(GET_TARGETS_ENGAGEMENT, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getTargetsEngagement.ts - useGetTargetsEngagementQuery',
    });
  }

  return {
    getTargetsEngagementQuery,
    targetsEngagement: data?.getTargetsEngagement,
    loading,
    error,
  };
};

export default useGetTargetsEngagementQuery;
