import { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { UseFormDropZoneStateSource } from './FormDropZone.enums';
import { UseFormDropZoneProps } from './FormDropZone.types';
import { getCustomErrorsMap, mapRejectedFilesErrors, validateAcceptedFiles } from './FormDropZone.utils';
import { useUploadFilesMutation } from 'basics/graphql/mutations/uploadFiles';
import { errorToast } from 'basics/utils/toast';
import { FileDataInput as FileDataInputMaya } from 'generated/maya.types';

const useFormDropZone = ({
  assetType,
  assetTarget,
  extensionsAllowed,
  handleFiles,
  maxFilesNumber = 1,
  maxFileSize = Infinity,
  minFileSize = 0,
  fieldState,
  assetsArePublic,
  setFieldState,
  setListLoading,
  stateSource = UseFormDropZoneStateSource.INTERNAL,
}: UseFormDropZoneProps) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadFilesMutation, { data: internalUploadFiles, error: internalUploadFilesError }] = useUploadFilesMutation();

  const customErrorsMap = getCustomErrorsMap({ t, extensionsAllowed, maxFileSize, maxFilesNumber, minFileSize });

  useEffect(() => {
    if (internalUploadFiles) {
      setFieldState((prevState) => [...prevState, ...internalUploadFiles.uploadFiles]);
    }
    if (internalUploadFilesError) {
      errorToast(t('errors_standard'));
    }
  }, [internalUploadFiles, internalUploadFilesError]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDrop = () => {
    setErrorMessage('');
  };

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    try {
      const customValidation = validateAcceptedFiles({ fieldState, acceptedFiles, maxFilesNumber });
      if (!customValidation.isValid) {
        setErrorMessage(JSON.stringify(customValidation.errors?.join(', ')) || t('errors_standard'));
        return;
      }

      const filesDataInput = acceptedFiles.map(({ size, name }) => ({
        size,
        name,
        assetTarget,
        assetType,
      }));

      if (setListLoading) {
        setListLoading(true);
      }

      if (stateSource === UseFormDropZoneStateSource.INTERNAL && handleFiles) {
        const uploadResult = await handleFiles(acceptedFiles, setErrorMessage);
        if (uploadResult?.ok === true) {
          await uploadFilesMutation(
            acceptedFiles,
            `${assetType}/${assetTarget}`,
            filesDataInput as FileDataInputMaya[],
            assetsArePublic,
          );
        } else {
          setErrorMessage(uploadResult?.errorMessage ? uploadResult.errorMessage : t('errors_standard'));
        }
      } else if (stateSource === UseFormDropZoneStateSource.EXTERNAL && handleFiles) {
        await handleFiles(acceptedFiles, setErrorMessage);
      } else {
        setErrorMessage(t('errors_standard'));
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }

    if (setListLoading) {
      setListLoading(false);
    }
  }, [assetTarget, assetType, fieldState, handleFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDropRejected = useCallback(async (fileRejections: FileRejection[]) => {
    const errorDisplayed = mapRejectedFilesErrors(fileRejections, customErrorsMap);
    setErrorMessage(errorDisplayed);
  }, [mapRejectedFilesErrors, setErrorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: extensionsAllowed.join(', '),
    maxSize: maxFileSize,
    maxFiles: maxFilesNumber,
    minSize: minFileSize,
    onDrop,
    onDropAccepted,
    onDropRejected,
  });
  return {
    errorMessage,
    isDragActive,
    getInputProps,
    getRootProps,
    t,
  };
};

export default useFormDropZone;
