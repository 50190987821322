import { useTranslation } from 'react-i18next';
import { array } from 'yup';

const useExportFormSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  return {
    countries: array()
      .required(requiredLabel)
      .min(1),
  };
};

export default useExportFormSchema;
