import { ApolloError } from '@apollo/client';
import { CustomContext } from 'services/logger/logger';

export const extractApolloErrorDetails = (error: Error, additionalContext?: CustomContext) => {
  const customContext: CustomContext = {
    code: 'N/A',
    context: { ...additionalContext },
    message: error.message,
  };
  if (error instanceof ApolloError) {
    const graphQLErrorExtensions = error.graphQLErrors[0]?.extensions;
    if (graphQLErrorExtensions) {
      const { code, description, message } = graphQLErrorExtensions;
      customContext.code = code;
      customContext.description = description;
      customContext.message = message || customContext.message;
    }
  }
  return customContext;
};
