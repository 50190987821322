import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useFormAgenda, { FormAgendaProps } from './FormAgenda.hook';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Grid from 'yoda-ui/components/Grid';
import { YodaGridCellSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const FormAgenda: FC<FormAgendaProps> = ({
  addButtonLabel,
  fieldState,
  setFieldState,
  transformToState,
  dateRange,
  startTime,
  endTime,
  title,
  description,
  disabled,
}) => {
  const { t } = useTranslation();
  const {
    canAddItem,
    handleAddItem,
    dateField,
    setDateField,
    startTimeField,
    setStartTimeField,
    endTimeField,
    setEndTimeField,
    titleField,
    setTitleField,
    descriptionField,
    setDescriptionField,
  } = useFormAgenda({
    fieldState,
    setFieldState,
    transformToState,
    dateRange,
    startTime,
    endTime,
    title,
    description,
    disabled,
  });

  return (
    <Grid container spacing={ YodaSpacing.large }>
      <Grid item xs={ YodaGridCellSize.small }>
        <TextYoda
          { ...dateRange }
          defaultValue={ dateField }
          onChange={ (event) => setDateField(event.target.value) }
          disabled={ disabled }
        />
      </Grid>
      <Grid item xs={ YodaGridCellSize.small }>
        <TextYoda
          { ...startTime }
          defaultValue={ startTimeField }
          onChange={ (event) => setStartTimeField(event.target.value) }
          disabled={ disabled }
        />
      </Grid>
      <Grid item xs={ YodaGridCellSize.small }>
        <TextYoda
          { ...endTime }
          defaultValue={ endTimeField }
          onChange={ (event) => setEndTimeField(event.target.value) }
          disabled={ disabled }
        />
      </Grid>
      <Grid item xs={ YodaGridCellSize.full }>
        <TextYoda
          { ...title }
          defaultValue={ titleField }
          onChange={ (event) => setTitleField(event.target.value) }
          disabled={ disabled }
        />
      </Grid>
      <Grid item xs={ YodaGridCellSize.full }>
        <TextYoda
          { ...description }
          defaultValue={ descriptionField }
          onChange={ (event) => setDescriptionField(event.target.value) }
          disabled={ disabled }
        />
      </Grid>
      <Grid item xs={ YodaGridCellSize.full }>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={ YodaSpacing.small }>
          <Button
            buttonType={ ButtonType.neutral }
            disabled={ disabled || !canAddItem }
            onClick={ handleAddItem }
          >
            { addButtonLabel || t('actions_add_label') }
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormAgenda;
