import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { fromUnitsToRem, YodaSpacing, YodaColors, YodaFontSize } from 'yoda-ui/yodaTheme';

export const heading1Style: SxProps<Theme> = {
  marginBottom: fromUnitsToRem(YodaSpacing.large),
  fontSize: YodaFontSize.xLarge,
  color: YodaColors.gray5,
};

export const heading2Style: SxProps<Theme> = {
  marginBottom: YodaSpacing.medium,
  fontSize: YodaFontSize.medium,
  color: YodaColors.gray5,
};

export const heading3Style: SxProps<Theme> = {
  marginBottom: YodaSpacing.small,
  fontSize: YodaFontSize.small,
  color: YodaColors.gray5,
};
