import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { UserData } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_LAZY_USER_DATA = gql`
  query getUserData {
    getUserData {
      country
      dashboardFilters{
        countries
        therapeuticArea
        product
        status
        cepFunction
        cepStatus
        dateStart
        dateEnd
        tacticStatus
      }
    }
  }
`;

  type UseLazyGetMayaUserData = () => {
    queryGetMayaUserData: Function;
    loading: boolean;
    data?: UserData | null;
    error?: ApolloError;
    called: boolean;
  };

const useLazyGetMayaUserData: UseLazyGetMayaUserData = () => {
  const [queryGetMayaUserData, { data, loading, error, called }] = useLazyQuery(GET_LAZY_USER_DATA, {
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getMayaUserDataLazy.ts - useLazyGetMayaUserData',
    });
  }

  return {
    queryGetMayaUserData,
    data: data?.getUserData,
    loading,
    error,
    called,
  };
};

export default useLazyGetMayaUserData;

