import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { MutationReturnType } from 'basics/types/maya.types';
import { warningToast } from 'basics/utils/toast';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const syncCepToVeevaMutationCall = gql`
  mutation syncCepToVeeva($id: String!) {
    syncCepToVeeva(id: $id)
  }
`;

type ResultType = {
  syncCepToVeeva: boolean;
};

type UseSyncCepToVeevaMutation = [
  (id: string) => Promise<MutationReturnType<boolean>>,
  MutationResult<ResultType>,
];

export const useSyncCepToVeevaMutation = (): UseSyncCepToVeevaMutation => {
  const [syncCepToVeeva, state] = useMutation<ResultType>(syncCepToVeevaMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (id) => {
    try {
      const { data } = await syncCepToVeeva({
        variables: {
          id,
        },
      });

      return { data: !!data?.syncCepToVeeva, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'syncCepToVeeva.ts - useSyncCepToVeevaMutation',
        });
        warningToast((error as Error).message);

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [syncCepToVeeva]);

  return [executeMutation, state];
};
