import { useTranslation } from 'react-i18next';
import { array, mixed } from 'yup';
import { BiogenProduct, BiogenTherapeuticArea } from 'basics/enums/biogen.enums';
import { BiogenFunction, CepStatus } from 'basics/enums/maya.enums';
import { TestContextType } from 'basics/types/common.types';
import { testEndDayAfterStartDay } from 'basics/utils/dates/dates.utils';
import { InitiativeCountry } from 'generated/maya.types';
import { YodaFieldsState } from 'yoda-ui/yodaForm/yodaForm.types';

const useDashboardFiltersSchema = () => {
  const { t } = useTranslation();

  return {
    countries: array().of(mixed<InitiativeCountry>().oneOf(Object.values(InitiativeCountry)))
      .test({
        name: 'coutries_or_TA_not_empty',
        test: (countries, context) => {
          const fieldsState: YodaFieldsState = (context.options as TestContextType)?.fieldsState;
          const therapeuticArea = fieldsState.therapeuticArea.value;

          if (countries?.length === 0 && !therapeuticArea) {
            return context.createError(
              {
                message: t(
                  'maya_dashboard_countries_or_ta_error',
                ),
              },
            );
          }

          if (!fieldsState.therapeuticArea.isValid) {
            fieldsState.therapeuticArea.errorMessage = '';
          }

          return true;
        },
      }),
    therapeuticArea: mixed<BiogenTherapeuticArea | ''>().oneOf([...Object.values(BiogenTherapeuticArea), ''])
      .test({
        name: 'coutries_or_TA_not_empty',
        test: (TA, context) => {
          const fieldsState: YodaFieldsState = (context.options as TestContextType)?.fieldsState;
          const countries = fieldsState.countries.value;

          if (countries.length === 0 && !TA) {
            return context.createError(
              {
                message: t(
                  'maya_dashboard_countries_or_ta_error',
                ),
              },
            );
          }

          if (!fieldsState.countries.isValid) {
            fieldsState.countries.errorMessage = '';
          }

          return true;
        },
      }),
    product: array().of(mixed<BiogenProduct>().oneOf(Object.values(BiogenProduct))).nullable(),
    cepFunction: array().of(mixed<BiogenFunction>().oneOf(Object.values(BiogenFunction))).nullable(),
    cepStatus: array().of(mixed<CepStatus>().oneOf(Object.values(CepStatus))).nullable(),
    date: mixed()
      .test({
        name: 'endDate must be after startDate',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayAfterStartDay,
      }),
  };
};

export default useDashboardFiltersSchema;
