import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { TacticFormTypes } from 'components/TacticForm/TacticForm.types';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Loader from 'yoda-ui/components/Loader';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

export const OneToOne: FC<TacticFormTypes> = ({ tacticFormConfig, keyMessagesLoading, dropMaterialsLoading }) => {
  const flags = useFlags();

  return (
    <>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.title } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <DateRangePicker { ...tacticFormConfig.date } />
      </Box>
      <Box marginBottom={ YodaSpacing.xSmall }>
        <CheckBoxYoda { ...tacticFormConfig.isKeyPerformanceIndicator } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...tacticFormConfig.mandatory } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.actionOrder } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.previousTactic } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.executingTeam } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.parentTactic } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.assetId } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.assetName } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        {
          keyMessagesLoading
            ? (
              <Box
                display="flex"
                justifyContent={ YodaJustifyContent.center }
                position="absolute"
                paddingTop={ YodaSpacing.small }
                paddingBottom={ YodaSpacing.small }
              >
                <Loader center size={ 1 } />
              </Box>
            )
            : null
        }
        <SelectYoda
          { ...tacticFormConfig.keyMessage }
          disabled={ keyMessagesLoading }
        />
      </Box>
      {
        flags.mayaBdcfcsd697EnableDropMaterials
          ? <Box marginBottom={ YodaSpacing.large }>
            {
              dropMaterialsLoading
                ? (
                  <Box
                    display="flex"
                    justifyContent={ YodaJustifyContent.center }
                    position="absolute"
                    paddingTop={ YodaSpacing.small }
                    paddingBottom={ YodaSpacing.small }
                  >
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <SelectYoda
              { ...tacticFormConfig.dropMaterial }
              disabled={ dropMaterialsLoading }
            />
          </Box>
          : null
      }
    </>
  );
};
