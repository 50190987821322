import { FC } from 'react';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import Dashboard from 'components/Dashboard/Dashboard';
import MayaLayout from 'components/MayaLayout';

const Maya: FC = () => {
  return (
    <MayaLayout>
      <AlertBanner />
      <Dashboard />
    </MayaLayout>
  );
};

export default Maya;
