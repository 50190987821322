import { createTheme, Theme } from '@mui/material/styles';
import { SxProps as MuiSxProps } from '@mui/system';

export const SPACING_FACTOR = 0.125;
export const fromUnitsToRem = (unit: number) => `${unit * SPACING_FACTOR}rem`;

export type SxProps = MuiSxProps<Theme>;

export enum MiViewColors {
  primary = '#5564d6',
  secondary = '#666666',
  tertiary = '#ffff',
  quaternary = '#ffa500',
  black = '#000',
  vividOrange = '#ed6c02',
  gray = '#808080',
  lightGray = 'rgba(0,0,0,0.26)',
  inherit = 'inherit',
}

export enum MiViewSpacing {
  none = 0,
  xxxxSmall = 1,
  xxxSmall = 2,
  xxSmall = 4,
  xSmall = 6,
  small = 8,
  medium = 12,
  large = 16,
  xLarge = 20,
  xxLarge = 24,
  xxxLarge = 28,
  xxxxLarge = 30,
}

export enum MiViewBorderRadius {
  none = '0',
  xxSmall = '0.125rem',
  xSmall = '.25rem',
  small = '.5rem',
  medium = '1rem',
  large = '1.5rem',
  xLarge = '2rem',
  xxLarge = '3rem',
  xxxLarge = '5rem',
  round = '100%',
}

export enum MiViewFontSize {
  xxSmall = '0.625rem',
  xSmall = '0.75rem',
  small = '0.875rem',
  medium = '1rem',
  large = '1.125rem',
  xLarge = '1.25rem',
  xxLarge = '1.75rem',
  xxxLarge = '2rem',
  xxxxLarge = '3rem',
}

export enum MiViewFontWeight {
  light = 'fontWeightLight',
  regular = 'fontWeightRegular',
  medium = 'fontWeightMedium',
  bold = 'fontWeightBold',
}

export enum MiViewJustifyContent {
  center = 'center',
  flexEnd = 'flex-end',
  flexStart = 'flex-start',
  spaceAround = 'space-around',
  spaceBetween = 'space-between',
  spaceEvenly = 'space-evenly',
  start = 'start',
}

export const miViewTheme: Theme = createTheme({
  palette: {
    primary: {
      main: MiViewColors.primary,
    },
    secondary: {
      main: MiViewColors.secondary,
    },
  },
  typography: {
    fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, sans-serif',
    fontSize: 16,
  },
  spacing: fromUnitsToRem,
});

export default miViewTheme;
