export enum Order {
  asc = 'asc',
  desc = 'desc',
}

export enum ConfigModule {
  Maya = 'Maya',
  Events = 'Events',
  Default = 'Default',
}
