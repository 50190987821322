import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  YodaBorderRadius,
  YodaColors,
  YodaFontSize,
  YodaFontWeight,
  YodaJustifyContent,
  YodaSpacing,
} from 'yoda-ui/yodaTheme';

export const targetListSelectStyle = {
  gap: '80px',

  '& .MuiFormControlLabel-root': {
    flex: 1,
    borderRadius: YodaBorderRadius.small,
    border: `2px ${YodaColors.gray3} solid`,
    paddingX: YodaSpacing.small,
    paddingY: YodaSpacing.xxxSmall,

    '&:hover': {
      borderColor: YodaColors.primaryBlue,
    },

    '&.Mui-disabled': {
      borderColor: YodaColors.gray1,
    },
  },
  '& .MuiFormControlLabel-label': {
    flex: 1,
    fontWeight: YodaFontWeight.medium,
    fontSize: YodaFontSize.small,
  },
};

export const TargetsButtonStyle: SxProps<Theme> = {
  marginTop: YodaSpacing.large,
  display: 'flex',
  gap: '20px',
  justifyContent: YodaJustifyContent.flexEnd,
};
