import { CustomerEngagementPlanning } from 'generated/maya.types';

export type CepViewPropsType = {
  cep?: CustomerEngagementPlanning;
};

export enum CepViewStepsIndex {
  configurationStep = 0,
  goalStep = 1,
  targetListStep = 2,
  tacticsStep = 3,
  miViewPreviewStep = 4,
}
