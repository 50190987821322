import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MiViewSpacing } from 'miview-ui/miviewTheme';

export const boxStyle: SxProps<Theme> = {
  overflow: 'hidden',
  padding: MiViewSpacing.xxxxSmall,
  '@media (max-width: 1600px)': {
    flexDirection: 'row',
    minWidth: '100%',
    maxWidth: '100%',
  },
};

export const gridContainerStyle: SxProps<Theme> = {
  overflow: 'hidden',
};

export const gridStyle: SxProps<Theme> = {
  padding: MiViewSpacing.xxxSmall,
  '@media (max-width: 1600px)': {
    minWidth: '100%',
    maxWidth: '100%',
  },
};
