import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MiViewColors, MiViewJustifyContent } from 'miview-ui/miviewTheme';

export const progressStyle: SxProps<Theme> = {
  color: MiViewColors.primary,
};

export const progressBoxStyle: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: MiViewJustifyContent.center,
  alignItems: 'center',
};
