/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { FC, memo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { mainWrapperStyle, tacticListRowStyle, listWrapperStyle } from './MiViewTargetList.style';
import { TargetListProps, RowProps } from './MiViewTargetList.types';
import MiViewScreenListSkeleton from 'components/MiViewScreenListSkeleton/MiViewScreenListSkeleton';
import MiViewTacticListRow from 'components/MiViewTacticListRow/MiViewTacticListRow';
import MiViewTargetListRow from 'components/MiViewTargetListRow/MiViewTargetListRow';

const TargetRow: FC<RowProps> = memo(({ index, style, data }) => {
  const target = data?.targets[index];
  const tactics = data?.nonChildTactics;
  const selectedTactic = data?.selectedTactic;

  return (
    <MiViewTargetListRow target={ target } tactics={ tactics } selectedTactic={ selectedTactic } style={ style }></MiViewTargetListRow>
  );
});

const MiViewTargetList: FC<TargetListProps> = ({ targets, tactics, nonChildTactics, selectedTactic }) => {
  const childTactics = tactics?.filter((tactic) => tactic?.parentTactic?._id === selectedTactic?._id);

  return (
    <>
      <div style={ mainWrapperStyle }>
        {
          selectedTactic ? (
            <MiViewTacticListRow
              id={ selectedTactic._id }
              tactic={ selectedTactic }
              childRecords={ childTactics }
              hideMenu={ true }
              style={ tacticListRowStyle }/>
          ) : (
            <MiViewScreenListSkeleton listItemsToRender={ 1 } animated={ true } />
          )
        }
      </div>
      <div style={ listWrapperStyle }>
        <AutoSizer>
          {
            ({
              height,
              width,
            }: {
              height: string | number;
              width: string | number;
            }) => (
              <List
                itemData={
                  {
                    targets,
                    nonChildTactics,
                    selectedTactic,
                  }
                }
                height={ height }
                width={ width }
                itemSize={ 100 }
                itemCount={ targets?.length }
                overscanCount={ 2 }
              >
                { TargetRow }
              </List>
            )
          }
        </AutoSizer>
      </div>
    </>
  );
};

export default MiViewTargetList;
