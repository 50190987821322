import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { KEY_MESSAGES_STATUS } from 'basics/constants/common.constant';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { BiogenFunction, KeyMessageType } from 'basics/enums/maya.enums';
import useGetKeyMessagesQuery from 'basics/graphql/queries/getKeyMessages';
import useKeyMessagesOptions from 'basics/options/keyMessages.options';
import { KeyMessagesOptionType } from 'basics/types/keyMessages.types';
import { CepStateType, TacticStateType } from 'basics/types/maya.types';
import { CustomerInitiative } from 'generated/maya.types';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';

const useTacticKeyMessages = (executingTeam: string) => {
  const flags = useFlags();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;

  const customerInitiative = cepStateValue.cep?.customerInitiative as CustomerInitiative;
  const productValues = Object.values(BiogenProduct);
  const productIndex = productValues.indexOf(cepStateValue.cep?.product as BiogenProduct);
  const product = productValues[productIndex];
  const [keyMessagesOptions, setKeyMessagesOptions] = useState<KeyMessagesOptionType[]>([]);

  const keyMessagesFilters = {
    Country: customerInitiative?.country,
    Product: product,
    ActiveStatusFlag: KEY_MESSAGES_STATUS,
  };

  const skip = (!keyMessagesFilters.Country || !keyMessagesFilters.Product);
  const { data: keyMessages, loading: keyMessagesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.keyMessage },
    skip,
  );
  const { data: medicalStrategies, loading: medicalStrategiesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.medicalStrategy },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );
  const { data: internationalMedicalStrategies, loading: internationalMedicalStrategiesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Country: 'INT', Type: KeyMessageType.medicalStrategy },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );
  const { data: discussionTopics, loading: discussionTopicsLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.discussionTopic },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );

  const allKeyMessages = [...keyMessages, ...medicalStrategies, ...internationalMedicalStrategies, ...discussionTopics];

  const currentKeyMessageOption = tacticStateValue.tactic?.keyMessage
    ? [{ ID: tacticStateValue.tactic?.keyMessage?.id, KeyName: tacticStateValue.tactic?.keyMessage?.name }]
    : [];

  const allKeyMessagesOptions = useKeyMessagesOptions(
    !allKeyMessages.length
      ? currentKeyMessageOption
      : allKeyMessages,
  );

  useEffect(() => {
    if (executingTeam) {
      const filteredKeyMessagesOptions = allKeyMessagesOptions.filter((element) => {
        if (!flags.mayaBiogenlinc5479KeyMessagePerExecutingTeam) {
          return true;
        }

        switch (executingTeam) {
          case BiogenFunction.Commercial:
            return element.commercial;
          case BiogenFunction.Medical:
            return element.medical;
          case BiogenFunction.MarketAccess:
            return element.marketAccess;
          default:
            return false;
        }
      });

      setKeyMessagesOptions(filteredKeyMessagesOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executingTeam, keyMessagesLoading, medicalStrategiesLoading, internationalMedicalStrategiesLoading, discussionTopicsLoading]);

  return {
    keyMessagesOptions,
    keyMessagesLoading: keyMessagesLoading || medicalStrategiesLoading || internationalMedicalStrategiesLoading || discussionTopicsLoading,
  };
};

export default useTacticKeyMessages;
