import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { VeevaSyncStatusBadgeMap } from 'basics/constants/common.constant';
import { VeevaSyncStatus } from 'basics/enums/maya.enums';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const useTargetListViewConfig = () => {
  const { t } = useTranslation();

  const targetListConfig: GridColDef[] = [
    {
      field: 'veevaSyncStatus',
      headerName: t('maya_veeva_sync_status_header_label'),
      flex: 0.2,
      renderCell: (cellValues: GridRenderCellParams) => {
        const badge = VeevaSyncStatusBadgeMap[cellValues.row.veevaSyncStatus || VeevaSyncStatus.PENDING];
        return badge && (
          <Tag
            fitContent
            bgcolor={ badge.bgColor }
            color={ badge.color }
            fontWeight={ YodaFontWeight.medium }
            paddingY={ YodaSpacing.xxxxSmall }
            paddingX={ YodaSpacing.xSmall }
            width={ 'auto' }
            height={ '20px' }
            borderRadius={ '1rem' }
          >
            { badge.label }
          </Tag>
        );
      },
      sortable: false,
    },
    {
      field: 'mdmId',
      headerName: t('maya_targetlist_mdmid_label'),
      flex: 0.2,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'email',
      headerName: t('maya_targetlist_email_label'),
      flex: 0.3,
      sortable: false,
    },
    {
      field: 'firstName',
      headerName: t('maya_targetlist_firstname_label'),
      flex: 0.3,
      sortable: false,
    },
    {
      field: 'lastName',
      headerName: t('maya_targetlist_lastname_label'),
      flex: 0.3,
      sortable: false,
    },
  ];

  return { targetListConfig };
};

export default useTargetListViewConfig;
