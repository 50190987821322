import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const COUNT_TARGETS = gql`
query CountTargetsType($filters: DashboardFilter!) {
  countTargets(filters: $filters) {
    count
  }
}
`;

type ResultType = {
  countTargets: {
    count: number;
  };
};

type UseCountTargets = () => {
  countTargetsQuery: Function;
  loading: boolean;
  error?: ApolloError;
  targetsData: unknown;
};

const useCountTargetsQuery: UseCountTargets = () => {
  const [countTargetsQuery, { data, loading, error }] = useLazyQuery<ResultType>(COUNT_TARGETS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'countTactics.ts - useCountTacticsQuery',
    });
  }

  return {
    countTargetsQuery,
    targetsData: data?.countTargets.count,
    loading,
    error,
  };
};

export default useCountTargetsQuery;
