import { FC } from 'react';
import Box from 'yoda-ui/components/Box';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import DialogContent from 'yoda-ui/components/Dialogs/Dialog/DialogContent';
import DialogTitle from 'yoda-ui/components/Dialogs/Dialog/DialogTitle';
import Loader from 'yoda-ui/components/Loader';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

export type LoadingDialogType = {
  text: string;
};

export const LoadingDialog: FC<LoadingDialogType> = ({ text }) => {
  return (
    <Dialog open maxWidth="md">
      <Box padding={ YodaSpacing.xLarge }>
        <DialogTitle>
          <Box textAlign="center">
            { text }
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box marginX={ YodaSpacing.medium } display='flex' justifyContent="center">
            <Loader color={ YodaColors.blue2 }/>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
