import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { QueryOptionsType } from 'basics/types/common.types';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ALL_CEP = gql`
query GetCustomerEngagementPlanningsQuery($limit: Int!, $offset: Int!, $filters: CepFilter) {
  getCustomerEngagementPlannings(limit: $limit, offset: $offset, filters: $filters) {
    customerEngagementPlannings {
      _id
      title,
      cepId,
      formattedCepId,
      status,
      customerInitiative {
        _id
      },
      dateStart,
      updated,
      published,
      veevaSyncStatus
      popularity
      globalCepSyncStatus {
        syncStatus,
        errors
      }
    }
    count
  }
}
`;

type ResultType = {
  getCustomerEngagementPlannings: {
    customerEngagementPlannings: CustomerEngagementPlanning[];
    count: number;
  };
};

type UseGetCeps = (filters?: unknown, queryOptions?: QueryOptionsType) => {
  loading: boolean;
  error?: ApolloError;
  data: CustomerEngagementPlanning[];
  count: number;
};

const useGetAllCepQuery: UseGetCeps = (
  filters?: unknown,
  queryOptions = { limit: 5000, offset: 0 },
) => {
  const { limit, offset } = queryOptions;

  const { data, loading, error } = useQuery<ResultType>(GET_ALL_CEP, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    variables: {
      limit,
      offset,
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getAllCustomerEngagementPlannings.ts - useGetAllCustomerEngagementPlanningQuery',
    });
  }

  return {
    data: data?.getCustomerEngagementPlannings.customerEngagementPlannings || [],
    count: data?.getCustomerEngagementPlannings.count || 0,
    loading,
    error,
  };
};

export default useGetAllCepQuery;
