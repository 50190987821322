export enum FormShape {
  form2FieldsColumn = 'form2FieldsColumn',
  form2FieldsRow = 'form2FieldsRow',
  formAsset = 'formAsset',
  formAsyncSelect = 'formAsyncSelect',
  formAgenda = 'formAgenda',
}

export enum ListShape {
  listAsset = 'listAsset',
  listColumn = 'listColumn',
  listRow = 'listRow',
  listInvitee = 'listInvitee',
}
