import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';
import { MiViewColors, MiViewFontSize, MiViewSpacing } from 'miview-ui/miviewTheme';

export const listItemStyle: SxProps<Theme> = {
  height: '95%',
  overflow: 'hidden',
};

export const avatarStyle: SxProps<Theme> = {
  bgcolor: 'transparent',
  backgroundImage: 'linear-gradient(45deg, orange 10%, #808080 11%)',
  opacity: 1,
  fontSize: MiViewFontSize.large,
};

export const listItemTextStyle: SxProps<Theme> = {
  '& .MuiListItemText-primary': {
    fontSize: MiViewFontSize.small,
  },
  '& .MuiListItemText-secondary': {
    fontSize: MiViewFontSize.xSmall,
  },
  marginLeft: MiViewSpacing.xxSmall,
  wordBreak: 'break-word',
};

export const mainWrapperStyle: CSSProperties = {
  overflowX: 'hidden',
};

export const divCol1Style: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const divCol1ChildStyle: CSSProperties = {
  width: '12%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: MiViewSpacing.medium,
  paddingRight: MiViewSpacing.medium,
};

export const divCol2Style: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
};

export const divCol2ChildStyle: CSSProperties = {
  width: '35%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const divCol3Style: CSSProperties = {
  width: '63%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  overflow: 'auto',
  wordBreak: 'break-word',
};

export const divCol4Style: CSSProperties = {
  height: '100%',
  width: '40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: MiViewSpacing.xxSmall,
};

export const divSpacer: CSSProperties = {
  margin: MiViewSpacing.small,
};

export const boxStyle: SxProps<Theme> = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginLeft: MiViewSpacing.xxSmall,
};

export const childBoxStyle: SxProps<Theme> = {
  pl: 1,
  minWidth: 50,
  overflow: 'auto',
};

export const formControlStyle: SxProps<Theme> = {
  m: 0,
};

export const iconButtonStyle: SxProps<Theme> = {
  alignSelf: 'center',
  padding: 0,
};

export const iconButtonHiddenStyle: SxProps<Theme> = {
  opacity: 0,
};

export const firstBadgeStyle = {
  color: MiViewColors.tertiary,
  background: MiViewColors.primary,
  cursor: 'pointer',
};

export const secondBadgeStyle = {
  color: MiViewColors.tertiary,
  background: MiViewColors.vividOrange,
  cursor: 'pointer',
};

export const chipStyle: SxProps<Theme> = {
  marginBottom: '2px',
};

export const radioStyle: SxProps<Theme> = {
  padding: '6px 4px 6px 8px',
  '& .MuiSvgIcon-root': {
    fontSize: MiViewFontSize.small,
    color: MiViewColors.primary,
  },
};

export const formControlLabelStyle: SxProps<Theme> = {
  '& .MuiFormControlLabel-label': {
    fontSize: MiViewFontSize.small,
  },
  '& .MuiFormControlLabel-label.Mui-disabled': {
    color: MiViewColors.secondary,
  },
  marginRight: MiViewSpacing.xxSmall,
  minWidth: '120px',
};

