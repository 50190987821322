import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

export const loadingBoxStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: YodaJustifyContent.center,
  position: 'absolute',
  paddingTop: YodaSpacing.small,
  paddingBottom: YodaSpacing.small,
};
