import { YodaColors } from 'yoda-ui/yodaTheme';

const assetsListStyle = {
  root: {
    backgroundColor: YodaColors.gray1,
    boxShadow: 'none',
  },
  assets: {
    '& div': {
      color: YodaColors.gray5,
    },
  },
  noAsset: {
    '& div': {
      color: YodaColors.gray3,
    },
  },
  media: {
    height: '80px',
    width: '110px',
  },
};

export default assetsListStyle;
