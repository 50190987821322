import { useEffect, useState } from 'react';
import { useYodaField, useYodaForm } from 'yoda-ui/yodaForm';
import { YodaFieldValidation, YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

type UseQuickForm = {
  name: string;
  validation?: YodaFieldValidation;
  defaultValue?: YodaFieldValue[];
};

const useQuickForm = ({ name, validation, defaultValue = [] }: UseQuickForm) => {
  const [fieldState, setFieldState] = useState<YodaFieldValue[]>(defaultValue);
  const { fieldErrorMessage } = useYodaField({ name, validation, defaultValue });
  const { setValue, setDefaultValue } = useYodaForm();

  useEffect(() => {
    setValue(name, fieldState);
  }, [setValue, name, fieldState]);

  useEffect(() => {
    setDefaultValue(name, defaultValue);
  }, [defaultValue, name, setDefaultValue]);

  const handleRemoveItem = (id: string) => {
    const updatedItemsList = fieldState.filter((item) => item.id !== id);
    setFieldState(updatedItemsList);
  };

  // Re-add objects from mongo after refresh/session ends
  useEffect(() => {
    if (fieldState.length === 0 && defaultValue.length > 0) {
      setFieldState(defaultValue);
    }
    // Required, since if you pass fieldState to deps, it will re-add objects when someone removes every object in fieldState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue.length]);

  return {
    fieldErrorMessage,
    fieldState,
    handleRemoveItem,
    setFieldState,
  };
};

export default useQuickForm;
