import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'basics/options/options.types';
import { SetFieldState } from 'components/QuickForm/QuickForm.types';

type UseFormAsyncSelectProps = {
  setFieldState: SetFieldState;
  transformToState: Function;
};

const useFormAsyncSelect = ({ setFieldState, transformToState }: UseFormAsyncSelectProps) => {
  const [canAddItem, setCanAddItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SelectOption | null>(null);
  const { t } = useTranslation();

  const handleOnChange = <T extends SelectOption>(value: T | null) => {
    if (value) {
      setSelectedItem(value);
      setCanAddItem(true);
    }
    if (value === null) {
      setSelectedItem(value);
    }
  };

  const handleAddItem = () => {
    if (selectedItem) {
      const newItem = transformToState(selectedItem);
      setFieldState((prevState) => [...prevState, newItem]);
      setCanAddItem(false);
    }
  };

  return {
    canAddItem,
    setCanAddItem,
    handleAddItem,
    handleOnChange,
    t,
  };
};

export default useFormAsyncSelect;
