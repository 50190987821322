import { FC } from 'react';
import useFormRow from '../Form2Fields.hook';
import FormFieldTitle from 'components/FormFieldTitle';
import { FieldState, SetFieldState, TextFieldBasis } from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import TextField from 'yoda-ui/components/Form/TextField';
import { textYodaStyle } from 'yoda-ui/components/Form/TextYoda/TextYoda.styles';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

type FormRowProps = {
  addButtonLabel?: string;
  fieldState: FieldState;
  title: string;
  firstFieldConfig: TextFieldBasis;
  secondFieldConfig: TextFieldBasis;
  setFieldState: SetFieldState;
  transformToState: Function;
};

const FormRow: FC<FormRowProps> = ({
  addButtonLabel,
  fieldState,
  firstFieldConfig,
  secondFieldConfig,
  title,
  setFieldState,
  transformToState,
}) => {
  const {
    canAddItem,
    firstField,
    firstTextFieldConfig,
    secondField,
    secondTextFieldConfig,
    handleAddItem,
    setFirstField,
    setSecondField,
    t,
  } = useFormRow({ firstFieldConfig, secondFieldConfig, fieldState, setFieldState, transformToState });

  return (
    <>
      <FormFieldTitle title={ title } />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={ YodaSpacing.medium }
      >
        <Box display="flex" flexDirection="row" width="90%">
          <Box marginRight={ YodaSpacing.medium } width="45%">
            <TextField
              { ...firstTextFieldConfig }
              value={ firstField }
              fullWidth
              sx={ textYodaStyle }
              variant="outlined"
              onChange={ (event) => setFirstField(event.target.value) }
            />
          </Box>
          <Box width="45%">
            <TextField
              { ...secondTextFieldConfig }
              value={ secondField }
              fullWidth
              sx={ textYodaStyle }
              onChange={ (event) => setSecondField(event.target.value) }
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" width="10%">
          <Button
            buttonType={ ButtonType.neutral }
            onClick={ handleAddItem }
            disabled={ !canAddItem }
          >
            { addButtonLabel || t('actions_add_label') }
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FormRow;
