import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { targetListFragment } from 'basics/graphql/fragments/targetList.fragments';
import { Target } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_TARGETLIST_BY_CEP_ID = gql`
query GetTargetListByCepIdQuery($cepId: String!, $limit: Int!, $offset: Int!) {
  getTargetListByCepId(cepId: $cepId, limit: $limit, offset: $offset) {
    targets {
    ${targetListFragment}
    }
    count
  }
}
`;

type ResultType = {
  getTargetListByCepId: {
    targets: Target[];
    count: number;
  };
};

type UseGetTargetListByCepIdLazy = () => {
  queryGetTargetListByCepId: Function;
  loading: boolean;
  error?: ApolloError;
  data: Target[];
  count: number;
};

const defaultTargetListValue: Target[] = [];

const useGetTargetListByCepIdLazyQuery: UseGetTargetListByCepIdLazy = () => {
  const [queryGetTargetListByCepId, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_TARGETLIST_BY_CEP_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getTargetListByCepId.ts - useGetTargetListByCepIdQuery',
    });
  }

  return {
    queryGetTargetListByCepId,
    data: data?.getTargetListByCepId.targets || defaultTargetListValue,
    count: data?.getTargetListByCepId.count || 0,
    loading,
    error,
    called,
  };
};

export default useGetTargetListByCepIdLazyQuery;
