import { Tactic } from 'generated/maya.types';

const useMiViewTacticList = () => {
  const getTacticsByParentTactic = (tactics: Tactic[], currentTactic: Tactic | undefined) => {
    return tactics.filter((tactic) => tactic.parentTactic?._id === currentTactic?._id);
  };

  return {
    getTacticsByParentTactic,
  };
};

export default useMiViewTacticList;
