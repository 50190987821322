import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { EngagementReturnType } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ENGAGEMENT = gql`
query getEngagement($cepId: String!, $channel: String!) {
  getEngagement(cepId: $cepId, channel: $channel) {
    engagement
    percentage
  }
}
`;

type ResultType = {
  getEngagement: {
    engagement: number;
    percentage: number;
  };
};

type UseGetEngagementLazy = () => {
  getEngagement: Function;
  loading: boolean;
  error?: ApolloError;
  data: EngagementReturnType | null;
};

const useGetEngagementLazy: UseGetEngagementLazy = () => {
  const [getEngagement, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_ENGAGEMENT, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getEngagement.ts - useGetEngagementQuery',
    });
  }

  return {
    getEngagement,
    data: data?.getEngagement || null,
    loading,
    error,
    called,
  };
};

export default useGetEngagementLazy;
