import { YodaSpacing } from 'yoda-ui/yodaTheme';

export const logoStyle = {
  paddingTop: YodaSpacing.large,
  paddingLeft: YodaSpacing.xLarge,

  '& > *:hover': {
    cursor: 'pointer',
  },
};
