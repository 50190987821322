import { BYTES_IN_KB, BYTES_IN_MB } from 'basics/constants/assets.constants';
import { EndUnit } from 'basics/enums/files.enums';

type ConvertFileSizeToUnit = (size: number, step?: number) => string;
type ConvertFileSize = (endUnit: EndUnit, bytesInUnit: number) => ConvertFileSizeToUnit;
export const convertFileSize: ConvertFileSize = (endUnit, bytesInUnit) => (size, step = 0.1) => {
  const sizeFloatInMB = Math.round(((size / bytesInUnit) / step)) * step;
  const finalSize = parseFloat(sizeFloatInMB.toFixed(1));
  return `${finalSize} ${endUnit}`;
};

export const convertFileSizeToKb = convertFileSize(EndUnit.KB, BYTES_IN_KB);
export const convertFileSizeToMb = convertFileSize(EndUnit.MB, BYTES_IN_MB);

export const getImageDimensions = (file: File): Promise<{ width: number; height: number }> => new Promise((resolve) => {
  const fileDataURL = window.URL.createObjectURL(file);
  const image = new Image();
  image.onload = () => {
    resolve({
      height: image.height,
      width: image.width,
    });
  };
  image.src = fileDataURL;
});

export const downloadBase64AsPdf = (base64: string, fileName?: string) => {
  const anchorElement = window.document.createElement('a');
  anchorElement.href = `data:application/pdf;base64,${base64}`;
  anchorElement.download = fileName || 'screenshot';
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
};
