import Card from '@mui/material/Card/Card';
import { useTranslation } from 'react-i18next';
import { appBarHeight } from 'basics/constants/common.constants';
import { MODULES_LOGO_PATH } from 'basics/constants/images.constants';
import TacticCard from 'components/TacticCard/TacticCard';
import { Tactic } from 'generated/maya.types';
import useTacticPrintView from 'routes/AuthenticatedApp/TacticPrintView/TacticPrintView.hook';
import {
  PrintWrapperStyle,
  TacticCardListStyle,
  TacticCardWrapperStyle,
  TacticPrintViewStyle,
  TitlePrintStyle,
  printViewLayout,
} from 'routes/AuthenticatedApp/TacticPrintView/TacticPrintView.styles';
import Box from 'yoda-ui/components/Box';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const TacticPrintView = () => {
  const {
    cepStateValue,
    date,
    groupedTactics,
  } = useTacticPrintView();
  const { t } = useTranslation();

  return (
    <Box sx={ printViewLayout }>
      <Box height={ '3rem' } marginTop={ `-${appBarHeight}` }>
        <img src={ `${MODULES_LOGO_PATH}/new_maya_logo.png` } alt="biogen-logo" style={ { height: 'inherit' } } />
      </Box>

      <Box sx={ TitlePrintStyle }>
        <Box className='titlePrintView' marginTop={ YodaSpacing.large }>
          { cepStateValue.cep?.title }
        </Box>
        <Box className='datePrintView'>
          { date }
        </Box>
      </Box>

      <Box sx={ PrintWrapperStyle }>
        <Box sx={ TacticPrintViewStyle }>
          {
            Object.keys(groupedTactics).map((actionOrder: string) => {
              const tactics = groupedTactics[actionOrder];
              return (
                tactics.length > 0 && (

                  <Card key={ actionOrder } variant="outlined" sx={ TacticCardWrapperStyle }>
                    <Box marginBottom={ YodaSpacing.small }>
                      { `${t('maya_tactic_action_order')} ${actionOrder}` }
                    </Box>
                    <Box sx={ TacticCardListStyle }>
                      {
                        tactics.map(
                          (tactic: Tactic) => (
                            <TacticCard key={ tactic._id } tactic={ tactic } readOnly={ true } />
                          ),
                        )
                      }
                    </Box>
                  </Card>
                )
              );
            })
          }
        </Box>
      </Box>
    </Box>
  );
};

export default TacticPrintView;
