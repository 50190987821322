/* eslint-disable no-param-reassign */
import { Badge, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import * as React from 'react';
import { FC } from 'react';
import { tooltipStyle } from './MiViewScreenBadgeAvatar.style';

type BadgeAvatarProps = {
  children: React.ReactNode;
  badgeList: BadgeListType[];
  tooltipMessage: string;
  cursor?: string;
};

export type BadgeListType = {
  content: JSX.Element | string;
  textColor: string;
  backgroundColor: string;
  location: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  invisible?: boolean;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip { ...props }
    classes={ { popper: className } }
    PopperProps={
      {
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      }
    }
    followCursor />
))(() => ({ ...tooltipStyle }));

const MiViewBadgeAvatar: FC<BadgeAvatarProps> = ({ children, badgeList, tooltipMessage, cursor }) => {
  return (
    <LightTooltip title={ tooltipMessage } sx={ tooltipStyle }>
      <Box position="relative" display="inline-flex" sx={ { cursor } }>
        {
          badgeList.map((badge: BadgeListType, index: number) => (
            <Badge sx={
              { '& .MuiBadge-badge': {
                backgroundColor: badge.backgroundColor,
                color: badge.textColor,
              } }
            }
            key={ index }
            badgeContent={ badge.content }
            anchorOrigin={ badge.location }
            invisible={ badge.invisible }
            overlap="circular"
            >
              { index === badgeList.length - 1 ? children : null }
            </Badge>
          ))
        }
      </Box>
    </LightTooltip>
  );
};

export default MiViewBadgeAvatar;
