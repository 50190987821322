import { FC } from 'react';
import FieldCard from 'components/FieldCard';
import { HandleRemoveItem } from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/components/Box';
import Icon, { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import { YodaColors, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type CardColumnProps = {
  item: { id: string; property1: string; property2: string };
  handleRemoveItem: HandleRemoveItem;
  disabled: boolean;
};

const CardColumn: FC<CardColumnProps> = ({ item, handleRemoveItem, disabled }) => (
  <Box
    marginBottom={ YodaSpacing.medium }
    data-testid="user-container"
  >
    <FieldCard>
      <Box>
        <Box display="flex" flexDirection="column">
          <Box fontWeight={ YodaFontWeight.medium }>{ item.property1 }</Box>
          { item.property2 }
        </Box>
      </Box>
      {
        disabled ? null : (<Box display="flex" justifyContent="center">
          <Icon
            data-testid="close-icon"
            name={ Icons.close }
            style={ { fill: YodaColors.gray3, cursor: 'pointer' } }
            onClick={ () => handleRemoveItem(item.id) }
          />
        </Box>)
      }

    </FieldCard>
  </Box>
);

export default CardColumn;
