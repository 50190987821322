import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { QueryOptionsType } from 'basics/types/common.types';
import { CiFilter, CustomerInitiative } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ALL_CUSTOMER_INITIATIVES = gql`
query GetCustomerInitiativesQuery($limit: Int!, $offset: Int!, $filters: CiFilter) {
  getCustomerInitiatives(limit: $limit, offset: $offset, filters: $filters) {
    customerInitiatives {
      _id
      title,
      product,
      therapeuticArea,
      functionalOwner,
      status,
      updated,
      veevaSyncStatus
    }
    count
  }
}
`;

type ResultType = {
  getCustomerInitiatives: {
    customerInitiatives: CustomerInitiative[];
    count: number;
  };
};

type UseGetCustomerInitiatives = (queryOptions?: QueryOptionsType, filters?: CiFilter) => {
  loading: boolean;
  error?: ApolloError;
  data: CustomerInitiative[];
  count: number;
};

const useGetAllCustomerInitiativeQuery: UseGetCustomerInitiatives = (queryOptions = { limit: 5000, offset: 0 }, filters?: CiFilter) => {
  const { limit, offset } = queryOptions;

  const { data, loading, error } = useQuery<ResultType>(GET_ALL_CUSTOMER_INITIATIVES, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    variables: {
      limit,
      offset,
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getAllCustomerInitiatives.ts - useGetAllCustomerInitiativeQuery',
    });
  }

  return {
    data: data?.getCustomerInitiatives.customerInitiatives || [],
    count: data?.getCustomerInitiatives.count || 0,
    loading,
    error,
  };
};

export default useGetAllCustomerInitiativeQuery;
