import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CepForm from 'components/CepForm/CepForm';
import Heading from 'components/Heading';
import MayaLayout from 'components/MayaLayout';
import useCepTemplateEdit from 'routes/AuthenticatedApp/CepTemplateEdit/CepTemplateEdit.hook';

const CepTemplateEdit: FC = () => {
  const { t } = useTranslation();
  const {
    submitCallback,
  } = useCepTemplateEdit();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_cep_tpl_edit_form_title') }
      </Heading>

      <CepForm submitCallback={ submitCallback } isTemplate={ true }/>
    </MayaLayout>
  );
};

export default CepTemplateEdit;
