import { DropMaterialsOptionType } from 'basics/types/dropMaterials.types';
import { Products } from 'generated/maya.types';

const useDropMaterialsOptions = (dropMaterials: Products[]) => {
  const dropMaterialsOptions = dropMaterials.map((dropMaterial: Products): DropMaterialsOptionType => {
    return {
      id: dropMaterial.productID as string,
      label: dropMaterial.productName as string,
      medical: dropMaterial.medicalFunctionFlag || false,
      commercial: dropMaterial.commercialFunctionFlag || false,
      marketAccess: dropMaterial.marketAccessFunctionFlag || false,
    };
  });

  return dropMaterialsOptions;
};

export default useDropMaterialsOptions;
