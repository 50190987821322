import { FC } from 'react';
import useTargetListExport from 'groups/CepFormTargetListGroup/TargetListExport/TargetListExport.hook';
import { ActionButton, ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import Loader from 'yoda-ui/components/Loader';
import { YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const TargetListExport: FC = () => {
  const {
    cepId,
    downloadDisabled,
    downloadTargets,
    exportLoading,
    t,
  } = useTargetListExport();

  const initFC = (
    <Box marginTop={ YodaSpacing.xxxSmall }>
      <ActionButton
        icon={ Icons.donwload }
        label={ t('maya_download_target_list') }
        onClick={ downloadTargets }
        variant={ ButtonVariant.primary }
        disabled={ downloadDisabled }
      />
    </Box>
  );

  const pendingFC = (
    <>
      { t('maya_export_pending') }

      <Box marginTop={ YodaSpacing.small }>
        <Loader size={ 1.5 } />
      </Box>
    </>
  );

  return (
    <Box
      marginBottom={ YodaSpacing.medium }
      fontSize={ YodaFontSize.medium }
      color={ YodaColors.gray4 }
    >
      { exportLoading && pendingFC }
      { !exportLoading && cepId && initFC }
    </Box>
  );
};

export default TargetListExport;
