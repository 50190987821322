import { FC } from 'react';
import ActionButton from 'components/ActionButton';
import { ActionButtonProps } from 'components/ActionButton/ActionButton';
import Box from 'yoda-ui/components/Box';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

export type StepButtonsConfig = {
  firstButton?: ActionButtonProps;
  secondButton?: ActionButtonProps;
  thirdButton: ActionButtonProps;
};

const ButtonsBar: FC<StepButtonsConfig> = ({ firstButton, secondButton, thirdButton }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    marginTop={ YodaSpacing.xLarge }
  >
    <Box>
      { firstButton && <ActionButton { ...firstButton } /> }
    </Box>
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
    >
      {
        secondButton
      && <Box marginRight={ YodaSpacing.medium }>
        <ActionButton { ...secondButton } />
      </Box>
      }
      <ActionButton { ...thirdButton } />
    </Box>
  </Box>
);

export default ButtonsBar;
