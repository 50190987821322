import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { CepFormStepsIndex } from './CepForm.types';
import { CepStatus, CepSteps, TacticMode } from 'basics/enums/maya.enums';
import { TacticStateType } from 'basics/types/maya.types';
import { StepButtonsConfig } from 'components/ButtonsBar/ButtonsBar';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { Icons } from 'yoda-ui/components/Icons/Icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCepFormButtons = (yodaFormMethods: any, cepActions: any, isTemplate: boolean) => {
  const {
    stepIndexer,
    saveAsDraft,
    handlePublishEvent,
    isSubmitting,
    saveAndNext,
    saveAndPrevious,
  } = cepActions;
  const { t } = useTranslation();
  const flags = useFlags();
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;

  const { isValid } = yodaFormMethods.useWatchForm();

  const firstStepButtons: StepButtonsConfig = {
    thirdButton: {
      buttonProps: {
        disabled: !isValid || isSubmitting,
        onClick: saveAndNext,
        buttonType: ButtonType.primary,
      },
      label: t('actions_next_label'),
      loading: isSubmitting,
      hidden: false,
    },
  };

  const middleStepsButtons: StepButtonsConfig = {
    firstButton: {
      buttonProps: {
        disabled: isSubmitting,
        onClick: saveAndPrevious,
        buttonType: ButtonType.secondary,
        startIconName: Icons.chevronLeft,
      },
      label: t('actions_previous_label'),
      hidden: false,
    },
    thirdButton: {
      buttonProps: {
        disabled: !isValid || isSubmitting,
        onClick: saveAndNext,
        buttonType: ButtonType.primary,
      },
      label: t('actions_next_label'),
      loading: isSubmitting,
      hidden: false,
    },
  };

  let publishLabel = t('maya_action_feedback_cep_button_label');
  switch (cepState?.cep?.status) {
    case CepStatus.FEEDBACK:
      publishLabel = t('maya_action_approve_cep_button_label');
      break;
    case CepStatus.APPROVED:
      publishLabel = t('maya_action_approved_cep_button_label');
      break;
    default:
      publishLabel = t('maya_action_feedback_cep_button_label');
  }

  const lastStepButtons: StepButtonsConfig = {
    firstButton: {
      buttonProps: {
        disabled: false,
        onClick: saveAndPrevious,
        buttonType: ButtonType.secondary,
        startIconName: Icons.chevronLeft,
      },
      label: t('actions_previous_label'),
      hidden: false,
    },
    secondButton: {
      buttonProps: {
        disabled: isSubmitting || cepState?.cep?.status === CepStatus.APPROVED,
        onClick: saveAsDraft,
        buttonType: ButtonType.primary,
      },
      label: t('toast_draft_saved'),
      loading: isSubmitting,
      hidden: false,
    },
    thirdButton: {
      buttonProps: {
        disabled: !isValid || isSubmitting || cepState?.cep?.status === CepStatus.APPROVED,
        onClick: handlePublishEvent,
        buttonType: ButtonType.primary,
      },
      label: publishLabel,
      loading: isSubmitting,
      hidden: isTemplate,
    },
  };

  switch (CepFormStepsIndex[stepIndexer.stepIndex]) {
    case CepSteps.configurationStep:
      return firstStepButtons;
    case CepSteps.targetListStep:
      return middleStepsButtons;
    case CepSteps.tacticsStep:
      return tacticStateValue.mode === TacticMode.form ? null
        : !flags.mayaBiogenlinc4793EnableGoalStep ? lastStepButtons : middleStepsButtons;
    case CepSteps.goalStep:
      return flags.mayaBdcfcsd714EnableMiViewPreview ? middleStepsButtons : lastStepButtons;
    case CepSteps.miViewPreviewStep:
      return lastStepButtons;
    default:
      return null;
  }
};

export default useCepFormButtons;
