import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import cepState from 'states/cep.states';
import Alert from 'yoda-ui/components/Alert';
import Box from 'yoda-ui/components/Box';
import { YodaFontSize, YodaFontWeight } from 'yoda-ui/yodaTheme';

const StatusBanner: FC = () => {
  const cepStateValue = useSnapshot(cepState);
  const { t } = useTranslation();

  return (
    <Alert severity='info' hideDefaultIcon>
      <Box
        display="flex"
        flexDirection="row"
        fontSize={ YodaFontSize.xSmall }
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row">
          <Box fontWeight={ YodaFontWeight.medium }>
            { t('maya_side_display_banner_update', { date: new Date(cepStateValue.cep?.updated).toLocaleString('fr-FR') }) }
          </Box>
        </Box>
      </Box>
    </Alert>
  );
};

export default StatusBanner;
