import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { FC, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Box from 'yoda-ui/components/Box';
import { TOOLBAR_OPTIONS } from 'yoda-ui/components/Form/RichtextYoda/RichtextYoda.constant';
import { editorStyle, fieldStyle, labelStyle, toolbarStyle, wrapperStyle } from 'yoda-ui/components/Form/RichtextYoda/RichtextYoda.style';
import { RichtextYodaInputProps } from 'yoda-ui/components/Form/RichtextYoda/RichtextYoda.types';
import TextField from 'yoda-ui/components/Form/TextField';
import { useYodaField } from 'yoda-ui/yodaForm';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichtextYoda: FC<RichtextYodaInputProps> = ({
  defaultValue,
  disabled,
  label,
  max,
  min,
  name,
  onChange,
  placeholder,
  required,
  step,
  validation,
  height,
}) => {
  const {
    fieldValue,
    fieldShowError,
    fieldErrorMessage,
    setFieldShowError,
    setValue,
    setFormIsDirty,
  } = useYodaField({ name, defaultValue, validation });

  const [editorValue, setEditorValue] = useState(EditorState.createWithContent(convertFromRaw(markdownToDraft(defaultValue || ''))));

  useEffect(() => {
    setEditorValue(EditorState.createWithContent(convertFromRaw(markdownToDraft(defaultValue || ''))));
  }, [defaultValue]);

  const EditorStateChange = (newValue: EditorState) => {
    const formattedValue = draftToMarkdown(convertToRaw(newValue.getCurrentContent()));
    setEditorValue(newValue);
    setValue(name, formattedValue);
    if (onChange) {
      onChange(formattedValue);
    }
    setFieldShowError(true, [name]);
    setFormIsDirty(true);
  };

  return (
    <Box>
      <p style={ labelStyle }>{ label }{ required ? ' *' : '' }</p>
      <Editor
        placeholder={ placeholder }
        editorState={ editorValue }
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={ EditorStateChange }
        readOnly={ disabled }
        toolbar={ TOOLBAR_OPTIONS }
        wrapperStyle={ { ...wrapperStyle, height: height || wrapperStyle.height } }
        toolbarStyle={ toolbarStyle }
        editorStyle={ editorStyle }
      />
      <TextField
        disabled={ disabled }
        error={ fieldShowError }
        fullWidth
        helperText={ fieldErrorMessage }
        inputProps={ { max, min, step } }
        label={ label }
        name={ name }
        placeholder={ placeholder }
        required={ required }
        sx={ fieldStyle }
        value={ fieldValue }
      />
    </Box>
  );
};

export default RichtextYoda;
