import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FieldState, FormAgendaConfig, SetFieldState } from 'components/QuickForm/QuickForm.types';

export type FormAgendaProps = FormAgendaConfig & {
  addButtonLabel?: string;
  fieldState: FieldState;
  setFieldState: SetFieldState;
  transformToState: Function;
  disabled: boolean;
};

const useFormAgenda = ({
  fieldState,
  setFieldState,
  transformToState,
  dateRange,
  startTime,
  endTime,
  title,
  description,
}: FormAgendaProps) => {
  // States
  const [dateField, setDateField] = useState('');
  const [startTimeField, setStartTimeField] = useState('');
  const [endTimeField, setEndTimeField] = useState('');
  const [titleField, setTitleField] = useState('');
  const [descriptionField, setDescriptionField] = useState('');
  const [canAddItem, setCanAddItem] = useState(false);

  // Methods
  const isDuplicatedItem = useCallback(
    () => fieldState.find(
      (item) => (item[dateRange.name] === dateField)
        && (item[startTime.name] === startTimeField)
        && (item[endTime.name] === endTimeField)
        && (item[title.name] === titleField),
    ), [fieldState, dateRange.name, dateField, startTime.name, startTimeField, endTime.name, endTimeField, title.name, titleField],
  );

  const handleAddItem = useCallback(
    () => {
      if (canAddItem) {
        const currentItem = {
          id: uuidv4(),
          [dateRange.name]: dateField,
          [startTime.name]: startTimeField,
          [endTime.name]: endTimeField,
          [title.name]: titleField,
          [description.name]: descriptionField,
        };
        const newItem = transformToState(currentItem);
        setFieldState((prevState) => [...prevState, newItem]);
        setDateField('');
        setStartTimeField('');
        setEndTimeField('');
        setTitleField('');
        setDescriptionField('');
      }
    }, [
      canAddItem,
      dateField,
      dateRange.name,
      descriptionField,
      description.name,
      endTimeField,
      endTime.name,
      setFieldState,
      startTimeField,
      startTime.name,
      titleField,
      title.name,
      transformToState,
    ],
  );

  // Effects
  useEffect(() => {
    setCanAddItem(!!dateField && !!startTimeField && !!endTimeField && !!titleField && !isDuplicatedItem());
  }, [isDuplicatedItem, dateField, startTimeField, endTimeField, titleField]);

  return {
    canAddItem,
    handleAddItem,
    dateField,
    setDateField,
    startTimeField,
    setStartTimeField,
    endTimeField,
    setEndTimeField,
    titleField,
    setTitleField,
    descriptionField,
    setDescriptionField,
  };
};

export default useFormAgenda;
