import { useEffect } from 'react';
import useCountTacticsQuery from 'basics/graphql/queries/countTactics';
import { DashboardFilter } from 'generated/maya.types';

const useCountTacticsWidget = (filters?: DashboardFilter) => {
  const { countTacticsQuery, tacticsData, loading, error } = useCountTacticsQuery();

  useEffect(() => {
    if (filters) {
      countTacticsQuery({ variables: { filters } });
    }
  }, [filters, countTacticsQuery]);

  return {
    value: tacticsData,
    loading,
    error,
  };
};

export default useCountTacticsWidget;
