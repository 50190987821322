import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mayaStrategicImperativeListRoute } from 'basics/constants/routes.constants';
import { useCreateStrategicImperativeMutation } from 'basics/graphql/mutations/createStrategicImperatives';
import useGetStrategicImperativeByIdQuery from 'basics/graphql/queries/getStrategicImperativeById';
import { succesToast, warningToast } from 'basics/utils/toast';
import {
  SiFormCanceltCallbackType,
  SiFormSubmitCallbackType,
} from 'components/StrategicImperativeForm/StrategicImperativeForm.types';
import { StrategicImperative } from 'generated/maya.types';

const useStrategicImperativeDuplicate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createStrategicImperative] = useCreateStrategicImperativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const [loading, setLoading] = useState(true);

  const strategicImperative = useGetStrategicImperativeByIdQuery(urlParams.get('sourceId')).data;
  const duplicatedStrategicImperative = {
    ...strategicImperative,
    title: `Copy - ${strategicImperative?.title}`,
  } as StrategicImperative;

  useEffect(() => {
    if (strategicImperative && loading) {
      setLoading(false);
    }
  }, [loading, setLoading, strategicImperative]);

  const handleCancelStrategicImperativeForm: SiFormCanceltCallbackType = () => {
    navigate(`${mayaStrategicImperativeListRoute}?year=${strategicImperative?.year}`);
  };

  const handleSaveStrategicImperativeForm: SiFormSubmitCallbackType = async (si) => {
    const { data: strategicImperativeData, error } = await createStrategicImperative(si);

    if (strategicImperativeData && !error) {
      succesToast(t('maya_si_created', { title: strategicImperativeData.title }));
      navigate(`${mayaStrategicImperativeListRoute}?year=${strategicImperativeData.year}`);
    } else {
      warningToast(error || t('errors_standard'));
      navigate(`${mayaStrategicImperativeListRoute}?year=${si.year}`);
    }
  };

  return {
    loading,
    strategicImperative: duplicatedStrategicImperative,
    handleCancelStrategicImperativeForm,
    handleSaveStrategicImperativeForm,
  };
};

export default useStrategicImperativeDuplicate;
