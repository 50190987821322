export enum CepSteps {
  configurationStep = 'configurationStep',
  goalStep = 'goalStep',
  targetListStep = 'targetListStep',
}

export enum CepGroups {
  configuration = 'configuration',
  goal = 'goal',
}

export enum CepCreationMode {
  custom = 'custom',
  template = 'template',
}
