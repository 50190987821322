import { FC } from 'react';
import { CepTemplateSelectorFiltersType } from 'components/CepTemplateSelectorForm/CepTemplateSelectorForm.types';
import useCepTemplateSelectorList from 'components/CepTemplateSelectorList/CepTemplateSelectorList.hook';
import GridList from 'components/GridList/GridList';
import Box from 'yoda-ui/components/Box';

type CepTemplateSelectorListPropsType = {
  cepTemplateSelectorFilters?: CepTemplateSelectorFiltersType;
};

const CepTemplateSelectorList: FC<CepTemplateSelectorListPropsType> = ({ cepTemplateSelectorFilters }) => {
  const {
    cepTemplateListColumnConfig,
    ceps,
    onPageChange,
    onPageSizeChange,
    count,
    loading,
  } = useCepTemplateSelectorList({ cepTemplateSelectorFilters });

  return (
    <Box >
      <GridList
        loading={ loading }
        getRowId={ (row) => row._id }
        columnConfig={ cepTemplateListColumnConfig }
        rows={ ceps }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ count }
        disableColumnMenu={ true }
      />
    </Box>
  );
};

export default CepTemplateSelectorList;
