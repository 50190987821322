const ENVS: { [hostName: string]: string } = {
  localhost: 'local',
  '127.0.0.1': 'local',
  'maya.back.mybiogen.io': 'local',
  'maya.backoffice.dev.mybiogen.io': 'dev',
  'maya.backoffice.stage.mybiogen.io': 'stage',
  'maya.backoffice.biogenlinc.com': 'prod',
};

const DEFAULTS: { [varName: string]: string } = {
  REACT_APP_ENCRYPT_KEY: 'n6UV8nx8HindzH26P55J',
  REACT_APP_DEFAULT_LANGUAGE: 'en',
  REACT_APP_OKTA_CLIENT_ID: '0oa25oiaviyEMrlk50h8',
  REACT_APP_OKTA_CONFIG_URL: 'https://biogen.oktapreview.com/.well-known/openid-configuration',
  REACT_APP_SENTRY_ENABLED: 'false',
  REACT_APP_SENTRY_DSN: 'https://b88f50895f9053d34decc7f2e8124497@o442068.ingest.us.sentry.io/4505794723053569',
  CONTENTFUL_PDF_GENERATOR_TOKEN: '774f9b9d-55db-495f-91fa-4f5053845303', // Todo rework to use it as secret
  GTM_ID: 'GTM-PT9PF2C',
};

const CONFIG: { [env: string]: { [varName: string]: string } } = {
  local: {
    REACT_APP_AWS_ENV: 'dev',
    REACT_APP_CDN_DNS: 'cdn.dev.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '66a8aebef73b2112619996a3',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://maya.back.mybiogen.io:3004',
    REACT_APP_OKTA_REDIRECT_URI: 'https://maya.back.mybiogen.io:3004',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://localhost:4000/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://127.0.0.1:4005/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://dev.app.biogenlinc.eu',
    REACT_APP_BACKOFFICE_LOGIN_URI: 'https://backoffice.dev.mybiogen.io/login',
    REACT_APP_BACKOFFICE_HOMEPAGE_URI: 'https://backoffice.dev.mybiogen.io',
  },
  dev: {
    REACT_APP_AWS_ENV: 'dev',
    REACT_APP_CDN_DNS: 'cdn.dev.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '66a8aebef73b2112619996a3',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://backoffice.dev.mybiogen.io',
    REACT_APP_OKTA_REDIRECT_URI: 'https://maya.backoffice.dev.mybiogen.io',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://gql-back.dev.mybiogen.io/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://maya.dev.mybiogen.io/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://dev.app.biogenlinc.eu',
    REACT_APP_SENTRY_ENABLED: 'true',
    REACT_APP_BACKOFFICE_LOGIN_URI: 'https://backoffice.dev.mybiogen.io/login',
    REACT_APP_BACKOFFICE_HOMEPAGE_URI: 'https://backoffice.dev.mybiogen.io',
  },
  stage: {
    REACT_APP_AWS_ENV: 'stage',
    REACT_APP_CDN_DNS: 'cdn.stage.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '66a8a5f14843c8106172d14d',
    REACT_APP_OKTA_CLIENT_ID: '0oa25vclwbdfy58v60h8',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://backoffice.stage.mybiogen.io',
    REACT_APP_OKTA_REDIRECT_URI: 'https://maya.backoffice.stage.mybiogen.io',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://gql-back.preprod.mybiogen.io/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://maya.stage.mybiogen.io/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://stg.app.biogenlinc.eu',
    REACT_APP_SENTRY_ENABLED: 'true',
    REACT_APP_BACKOFFICE_LOGIN_URI: 'https://backoffice.stage.mybiogen.io/login',
    REACT_APP_BACKOFFICE_HOMEPAGE_URI: 'https://backoffice.stage.mybiogen.io',
  },
  prod: {
    REACT_APP_AWS_ENV: 'prod',
    REACT_APP_CDN_DNS: 'cdn.mybiogen.io',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '66a8a5f14843c8106172d14e',
    REACT_APP_OKTA_CLIENT_ID: '0oa163wstyp6OmJ7i0x8',
    REACT_APP_OKTA_CONFIG_URL: 'https://biogen.okta.com/.well-known/openid-configuration',
    REACT_APP_OKTA_LOGOUT_REDIRECT_URI: 'https://backoffice.biogenlinc.com',
    REACT_APP_OKTA_REDIRECT_URI: 'https://maya.backoffice.biogenlinc.com',
    REACT_APP_PUBLIC_MYBIOGEN_GRAPHQL_API_URI: 'https://gql-back.mybiogen.io/graphql',
    REACT_APP_MAYA_GRAPHQL_URI: 'https://maya.mybiogen.io/graphql',
    REACT_APP_AKAMAI_MIDDLEWARE_URI: 'https://app.biogenlinc.eu',
    REACT_APP_SENTRY_ENABLED: 'true',
    REACT_APP_BACKOFFICE_LOGIN_URI: 'https://backoffice.biogenlinc.com/login',
    REACT_APP_BACKOFFICE_HOMEPAGE_URI: 'https://backoffice.biogenlinc.com',
  },
};

const isBrowser = () => typeof window !== 'undefined';
export const CURRENT_ENV = isBrowser() ? ENVS[window.location.hostname] : 'local';

export const isProduction = CURRENT_ENV === 'prod';
export const isStage = CURRENT_ENV === 'stage';
export const isDev = CURRENT_ENV === 'dev';
export const isLocal = CURRENT_ENV === 'local';

export function getConfigVar(varName: string, currentEnv = CURRENT_ENV): string {
  return process.env[varName] || CONFIG[currentEnv][varName] || DEFAULTS[varName];
}
