import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_FACET_CI_YEARS = gql`
query GetFacetCiYears {
  getFacetCiYears {
    years
  }
}
`;

type ResultType = {
  getFacetCiYears: {
    years: number[];
  };
};

type UseGetFacetCiYears = () => {
  error?: ApolloError;
  data: number[];
};

const useGetFacetCiYearsQuery: UseGetFacetCiYears = () => {
  const { data, error } = useQuery<ResultType>(GET_FACET_CI_YEARS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getFacetCiYears.ts - useGetFacetCiYearsQuery',
    });
  }

  return {
    data: data?.getFacetCiYears.years || [],
  };
};

export default useGetFacetCiYearsQuery;
