import { YodaBorderRadius, YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

export const cepTemplateCreationModeStyle = {
  gap: '80px',

  '& .MuiFormControlLabel-root': {
    flex: 1,
    borderRadius: YodaBorderRadius.small,
    border: `2px ${YodaColors.gray3} solid`,
    paddingX: YodaSpacing.small,
    paddingY: YodaSpacing.xxxSmall,
    minWidth: '400px',

    '&:hover': {
      borderColor: YodaColors.primaryBlue,
    },

    '&.Mui-disabled': {
      borderColor: YodaColors.gray1,
    },
  },
  '& .MuiFormControlLabel-label': {
    flex: 1,
    fontWeight: YodaFontWeight.medium,
    fontSize: YodaFontSize.small,
  },
};
