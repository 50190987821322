import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const dashboardFiltersForm: SxProps<Theme> = {
  '&': {
    flexWrap: 'wrap',
    gap: '3.5%',
    justifyContent: 'flex-start',
  },
  '& > div': {
    width: '31%',
    marginBottom: '15px',
  },
};
