import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_CEP_ENGAGEMENT = gql`
query getCepEngagement($cepId: String!) {
  getCepEngagement(cepId: $cepId)
}
`;

type ResultType = {
  getCepEngagement: number;
};

type UseGetCepEngagement = (cepId?: string) => {
  loading: boolean;
  error?: ApolloError;
  cepEngagement?: number;
};

const useGetCepEngagementQuery: UseGetCepEngagement = (cepId?: string) => {
  const { data, loading, error } = useQuery<ResultType>(GET_CEP_ENGAGEMENT, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip: !cepId,
    variables: {
      cepId,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getCepEngagement.ts - useGetCepEngagementQuery',
    });
  }

  return {
    cepEngagement: data?.getCepEngagement,
    loading,
    error,
  };
};

export default useGetCepEngagementQuery;
