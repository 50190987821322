import Box from '@mui/material/Box';
import { FC } from 'react';
import { boxStyle } from './MiViewScreenContent.style';

type ScreenContentProps = {
  children: React.ReactNode;
};

const MiViewScreenContent: FC<ScreenContentProps> = ({ children }) => {
  return (
    <Box sx={ boxStyle }>
      { children }
    </Box>
  );
};

export default MiViewScreenContent;
