import merge from 'lodash/merge';
import { FC } from 'react';
import Box from 'yoda-ui/components/Box';
import Card from 'yoda-ui/components/Card';
import CardContent from 'yoda-ui/components/Card/CardContent';
import { YodaBorderRadius, YodaColors, YodaFontWeight, YodaSpacing, SxProps } from 'yoda-ui/yodaTheme';

type FieldCardProps = {
  sx?: SxProps;
  empty?: boolean;
};

const styles = {
  card: {
    backgroundColor: YodaColors.gray1,

    '&.MuiPaper-rounded': {
      borderRadius: YodaBorderRadius.small,
    },
  },
  cardContent: {
    padding: YodaSpacing.xSmall,
    '&:last-child': {
      paddingBottom: YodaSpacing.xSmall,
    },
  },
};

const FieldCard: FC<FieldCardProps> = ({
  children,
  sx,
  empty,
  ...props
}) => {
  const mergedStyles = merge(styles, { card: sx });

  const color = empty ? YodaColors.gray3 : YodaColors.gray5;

  return (
    <Card
      sx={ mergedStyles.card }
      elevation={ 0 }
      { ...props }
    >
      <CardContent sx={ styles.cardContent }>
        <Box
          color={ color }
          fontWeight={ YodaFontWeight.light }
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          { children }
        </Box>
      </CardContent>
    </Card>
  );
};

export default FieldCard;
