import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerInitiativeView from './CustomerInitiativeView.hook';
import { mayaCustomerInitiativeListRoute } from 'basics/constants/routes.constants';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { VeevaSyncStatus } from 'basics/enums/maya.enums';
import {
  FormLayoutContainer,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
  FormLayoutMidPanelHeader,
  FormLayoutRightPanel,
} from 'components/FormLayout';
import Heading from 'components/Heading';
import InfoTag from 'components/InfoTag/InfoTag';
import MayaLayout from 'components/MayaLayout';
import VeevaStatusBar from 'components/VeevaStatusBar/VeevaStatusBar';
import { buttonStyle, sideContainerStyle } from 'routes/AuthenticatedApp/CustomerInitiativeView/CustomerInitiativeView.styles';
import { ActionButton, ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import Loader from 'yoda-ui/components/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CustomerInitiativeView: FC = () => {
  const { t } = useTranslation();
  const {
    customerInitiative,
    date,
    loading,
    navigate,
  } = useCustomerInitiativeView();

  return (
    <MayaLayout>
      <FormLayoutContainer>
        <FormLayoutMidPanel >

          <FormLayoutMidPanelHeader>
          </FormLayoutMidPanelHeader>

          <FormLayoutMidPanelContent>
            <Heading>
              { t('maya_ci_view') }
              <VeevaStatusBar
                lastVeevaSync={ customerInitiative?.lastVeevaSync }
                lastVeevaSyncErrors={ customerInitiative?.lastVeevaSyncError ? [customerInitiative?.lastVeevaSyncError] : undefined }
                veevaSyncStatus={ customerInitiative?.veevaSyncStatus as VeevaSyncStatus }
              />
            </Heading>

            {
              loading
                ? (
                  <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    <Loader center />
                  </Box>
                )
                : (
                  <>
                    <Box>
                      <Box
                        sx={ sideContainerStyle.firstBox }
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        padding={ YodaSpacing.medium }
                        marginBottom={ YodaSpacing.medium }
                      >
                        <Box marginBottom={ YodaSpacing.xxSmall } marginTop={ YodaSpacing.xxSmall }>
                          <Box>
                            <InfoTag
                              title={ t('maya_ci_view_title') }
                              field={ customerInitiative?.title }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_country') }
                              field={ customerInitiative?.country }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_date') }
                              field={ date }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_description') }
                              field={ customerInitiative?.description }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_solution_core_story') }
                              field={ customerInitiative?.solutionCoreStory }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_si') }
                              field={ customerInitiative?.strategicImperative.title }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_therapeutic_area') }
                              field={ customerInitiative?.therapeuticArea }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_product') }
                              field={
                                (Object.keys(BiogenProduct) as Array<keyof typeof BiogenProduct>)
                                  .find((key) => BiogenProduct[key] === customerInitiative?.product)
                              }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_functional_owner') }
                              field={ customerInitiative?.functionalOwner }
                            />
                            <InfoTag
                              title={ t('maya_ci_view_functions_involved') }
                              field={ customerInitiative?.functionsInvolved.toString() }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={ sideContainerStyle.firstBox }
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      padding={ YodaSpacing.medium }
                    >
                      <InfoTag
                        title={ t('maya_ci_engagement') }
                        field={ customerInitiative?.goals.engagement.toString() }
                      />
                      <InfoTag
                        title={ t('maya_ci_reach') }
                        field={ `${customerInitiative?.goals.reach.toString()}%` }
                      />
                    </Box>
                  </>
                )
            }
            <ActionButton
              label={ t('actions_back_label') }
              icon={ Icons.chevronLeft }
              onClick={ () => { navigate(`${mayaCustomerInitiativeListRoute}?year=${customerInitiative?.year}`); } }
              variant={ ButtonVariant.primary }
              sx={ buttonStyle }
            />
          </FormLayoutMidPanelContent>

          <FormLayoutRightPanel>
          </FormLayoutRightPanel>

        </FormLayoutMidPanel>
      </FormLayoutContainer>
    </MayaLayout>
  );
};

export default CustomerInitiativeView;

