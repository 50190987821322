import useGetAllStrategicImperativeQuery from 'basics/graphql/queries/getAllStrategicImperatives';
import { StrategicImperative } from 'generated/maya.types';

const useStrategicImperativeOptions = (filters = {}) => {
  const { data: strategicImperatives } = useGetAllStrategicImperativeQuery(filters);
  const strategicImperativeOptions = strategicImperatives.map((element: StrategicImperative) => {
    return {
      id: element._id,
      label: element.title,
    };
  });

  return strategicImperativeOptions;
};

export default useStrategicImperativeOptions;
