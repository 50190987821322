import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { therapeuticAreaRelativesProducts } from 'basics/constants/common.constants';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { useCreateOrUpdateMayaUserDataMutation } from 'basics/graphql/mutations/createOrUpdateMayaUserData';
import { SelectOption } from 'basics/options/options.types';
import { CurrentUser } from 'basics/types/user.types';
import useDashboardFiltersConfig from 'components/DashboardFilters/DashboardFilters.config';
import { formatDashboardFilter } from 'components/DashboardFilters/DashboardFilters.transformer';
import { CurrentUserStateContext, useCurrentUserState } from 'contexts/user';
import { DashboardFilter } from 'generated/maya.types';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useDashboardFilters = () => {
  const [selectedTA, setSelectedTA] = useState<string | null>(null);
  const [filters, setFilters] = useState<DashboardFilter | undefined>();
  const { getValues, providerFields, setValue, useWatchForm, useWatchField } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const { t } = useTranslation();
  const { user } = useCurrentUserState();
  const { setCurrentUserData } = useContext(CurrentUserStateContext);
  const { dashboardFiltersConfig } = useDashboardFiltersConfig(selectedTA, user);
  const [createOrUpdateMayaUserData] = useCreateOrUpdateMayaUserDataMutation();
  const therapeuticAreaKey = useWatchField(dashboardFiltersConfig.therapeuticArea.name)?.value;

  useEffect(() => {
    setSelectedTA(therapeuticAreaKey);
  }, [therapeuticAreaKey]);

  useEffect(() => {
    const filtersValues = getValues();
    if (filtersValues.countries.length || filtersValues.therapeuticArea) {
      const formattedFilters = formatDashboardFilter(filtersValues);
      setFilters(formattedFilters);

      createOrUpdateMayaUserData({ dashboardFilters: formattedFilters });

      setCurrentUserData((prevState) => {
        return {
          ...prevState,
          currentUser: {
            ...prevState.currentUser,
            dashboardFilters: formattedFilters,
          } as CurrentUser,
        };
      });
    }
  }, [createOrUpdateMayaUserData, getValues, setCurrentUserData]);

  const handleChangeTA = useCallback((event: SyntheticEvent<Element, Event>, toClearTAField: SelectOption | null) => {
    if (toClearTAField === null) {
      setValue(dashboardFiltersConfig.product.name, []);
    }
  }, [setValue, dashboardFiltersConfig]);

  const handleChangeProduct = useCallback((event: SyntheticEvent<Element, Event>, selectedProduct: SelectOption[]) => {
    if (selectedProduct && selectedProduct.length > 0) {
      const therapeuticAreaValue = Object.keys(therapeuticAreaRelativesProducts)
        .find((key: string) => therapeuticAreaRelativesProducts[key as keyof typeof therapeuticAreaRelativesProducts]
          ?.includes(selectedProduct[0].id as BiogenProduct));
      setValue(dashboardFiltersConfig.therapeuticArea.name, therapeuticAreaValue);
    }
  }, [setValue, dashboardFiltersConfig]);

  const handleSearchValueOfUserFilters = () => {
    const formattedFilters = formatDashboardFilter(getValues());
    setFilters(formattedFilters);

    createOrUpdateMayaUserData({ dashboardFilters: formattedFilters });

    setCurrentUserData((prevState) => {
      return {
        ...prevState,
        currentUser: {
          ...prevState.currentUser,
          dashboardFilters: formattedFilters,
        } as CurrentUser,
      };
    });
  };

  const resetDashboardFilter = () => {
    const dashboardFields = Object.keys(dashboardFiltersConfig);

    dashboardFields.forEach((field) => {
      setValue(dashboardFiltersConfig[field as keyof typeof dashboardFiltersConfig].name, undefined);
    });
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSearchValueOfUserFilters,
      buttonType: ButtonType.primary,
      disabled: !isValid,
    },
    label: t('actions_search_label'),
  };

  const resetButtonConfig = {
    buttonProps: {
      onClick: resetDashboardFilter,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_reset_label'),
  };

  return {
    providerFields,
    dashboardFiltersConfig,
    handleChangeTA,
    handleChangeProduct,
    submitButtonConfig,
    resetButtonConfig,
    filters,
  };
};

export default useDashboardFilters;
