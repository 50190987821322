import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { MutationReturnType } from 'basics/types/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const setCiStatusToDeletedMutationCall = gql`
  mutation setCiStatusToDeleted($id: String!, $sendToVeeva: Boolean!) {
      setCiStatusToDeleted(id: $id, sendToVeeva: $sendToVeeva)
  }
`;

type ApiResultType = {
  setCiStatusToDeleted: boolean;
};

type UseSetCiStatusToDeletedMutation = [
  (
    id: string,
    sendToVeeva?: boolean
  ) => Promise<MutationReturnType<boolean>>,
  MutationResult<ApiResultType>,
];

export const useSetCiStatusToDeletedMutation = (): UseSetCiStatusToDeletedMutation => {
  const [setCiStatusToDeleted, state] = useMutation<ApiResultType>(setCiStatusToDeletedMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (id, sendToVeeva = true) => {
    try {
      const { data } = await setCiStatusToDeleted({
        variables: {
          id,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });
      return { data: !!data?.setCiStatusToDeleted, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'setCiStatusToDeleted.ts - useSetCiStatusToDeletedMutation',
        });

        return { data: false, error: customContext.message as string };
      }

      return { data: false, error: null };
    }
  }, [setCiStatusToDeleted, flags.mayaEnableVeevaBiogenlinc1816]);

  return [executeMutation, state];
};
