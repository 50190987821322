import { Alert } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { boxStyle, alertStyle } from './MiViewPreviewStep.style';
import { CepStatus } from 'basics/enums/maya.enums';
import { CepStateType } from 'basics/types/maya.types';
import MiView from 'components/MiView/MiView';
import cepState from 'states/cep.states';
import Box from 'yoda-ui/components/Box';

const MiViewPreviewStep: FC = () => {
  const { t } = useTranslation();
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const alertMessage = cepStateValue.cep?.status === CepStatus.APPROVED
    ? t('maya_cep_step_miview_preview_notification_after_approving') : t('maya_cep_step_miview_preview_notification');

  return (
    <Box>
      <Box sx={ boxStyle }>
        <Alert severity="info" sx={ alertStyle }> { alertMessage }</Alert>
      </Box>
      <MiView></MiView>
    </Box>
  );
};

export default MiViewPreviewStep;
