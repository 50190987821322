import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const useCepDashboardListConfig = () => {
  const { t } = useTranslation();

  const cepTitleFieldConfig = {
    label: t('maya_cep_form_cep_name_label'),
    name: 'name',
    placeholder: t('maya_cep_form_cep_name_placeholder'),
    defaultValue: '',
    required: false,
  };

  const cepListColumnConfig: GridColDef[] = [
    {
      field: 'title',
      headerName: t('maya_cep_dashboard_title'),
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
  ];

  return {
    cepListColumnConfig,
    cepTitleFieldConfig,
  };
};

export default useCepDashboardListConfig;
