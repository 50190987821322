import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import useCepFormConfigurationGroupConfig
  from './CepFormConfigurationGroup.config';
import { therapeuticAreaRelativesProducts } from 'basics/constants/common.constants';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { CepSteps } from 'basics/enums/maya.enums';
import useGetCustomerInitiativeByIdQuery from 'basics/graphql/queries/getCustomerInitiativeById';
import { SelectOption } from 'basics/options/options.types';
import { CepStateType } from 'basics/types/maya.types';
import { formatUTCDate } from 'basics/utils/date.utils';
import { useCurrentUserState } from 'contexts/user';
import { CustomerInitiative } from 'generated/maya.types';
import cepState from 'states/cep.states';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useCepFormConfigurationGroup = (isTemplate: boolean) => {
  const { setValue, setFieldShowError, setStepShowError } = useYodaForm();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const { user } = useCurrentUserState();

  const [customerInitiativeId, setCustomerInitiativeId] = useState<string | null>(null);
  const [selectedTA, setSelectedTA] = useState<string | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const parentCustomerInitiativeId = urlParams.get('parentId');

  const parentCustomerInitiative = useGetCustomerInitiativeByIdQuery(parentCustomerInitiativeId).data;
  const newCustomerInitiative = useGetCustomerInitiativeByIdQuery(customerInitiativeId).data;
  const currentCustomerInitiative = newCustomerInitiative
  || cepStateValue.cep?.customerInitiative as CustomerInitiative
  || parentCustomerInitiative;

  const {
    cepFormConfigurationGroup,
  } = useCepFormConfigurationGroupConfig(
    currentCustomerInitiative,
    user,
    selectedTA,
    selectedStartDate,
    isTemplate,
  );

  if (Object.keys(cepStateValue.cep || {}).length && !parentCustomerInitiativeId) {
    const fieldsName = Object.keys(cepFormConfigurationGroup);
    setFieldShowError(true, fieldsName);
    setStepShowError(true, [CepSteps.configurationStep]);
  }

  useEffect(() => {
    if (newCustomerInitiative) {
      setValue(cepFormConfigurationGroup.therapeuticArea.name, newCustomerInitiative?.therapeuticArea);
      setValue(cepFormConfigurationGroup.product.name, newCustomerInitiative?.product || null);
      setValue(cepFormConfigurationGroup.biogenFunction.name, newCustomerInitiative?.functionalOwner);
      setValue(cepFormConfigurationGroup.date.name, formatUTCDate(newCustomerInitiative?.dateStart));
    }
  }, [
    cepFormConfigurationGroup.biogenFunction.name,
    cepFormConfigurationGroup.product.name,
    cepFormConfigurationGroup.therapeuticArea.name,
    cepFormConfigurationGroup.date.name,
    newCustomerInitiative,
    setValue,
  ]);

  useEffect(() => {
    if (selectedStartDate === null && cepStateValue.cep?.dateStart) {
      setSelectedStartDate(new Date(cepStateValue.cep?.dateStart));
    }
  }, [cepStateValue.cep?.dateStart, selectedStartDate]);

  const handleDateChange = useCallback((date: DateRange) => {
    setSelectedStartDate(date ? date[0] : null);
  }, []);

  const handleCustomerInitiativeChange = useCallback((event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    if (value?.id) {
      setCustomerInitiativeId(value.id as string);
    }
  }, [setCustomerInitiativeId]);

  const handleChangeTA = useCallback((event: SyntheticEvent<Element, Event>, TAValue: SelectOption | null) => {
    setSelectedTA(TAValue?.label || null);
    if (TAValue === null) {
      setValue(cepFormConfigurationGroup.product.name, []);
    }
  }, [setValue, cepFormConfigurationGroup]);

  const handleChangeProduct = useCallback((event: SyntheticEvent<Element, Event>, product: SelectOption) => {
    if (product) {
      const therapeuticAreaValue = Object.keys(therapeuticAreaRelativesProducts)
        .find((key: string) => therapeuticAreaRelativesProducts[key as keyof typeof therapeuticAreaRelativesProducts]
          ?.includes(product.id as BiogenProduct));
      setValue(cepFormConfigurationGroup.therapeuticArea.name, therapeuticAreaValue);
    }
  }, [setValue, cepFormConfigurationGroup]);

  return {
    cepFormConfigurationGroup,
    handleCustomerInitiativeChange,
    handleChangeTA,
    handleChangeProduct,
    handleDateChange,
  };
};

export default useCepFormConfigurationGroup;
