import { OktaJS } from '@mybiogen/auth-openid';
import { getConfigVar } from 'config/config';

const oktaClientId = getConfigVar('REACT_APP_OKTA_CLIENT_ID') || '';
const oktaConfigUrl = getConfigVar('REACT_APP_OKTA_CONFIG_URL') || '';
const oktaRedirectUri = getConfigVar('REACT_APP_OKTA_REDIRECT_URI') || '';
const oktaLogoutRedirectUri = getConfigVar('REACT_APP_OKTA_LOGOUT_REDIRECT_URI') || '';

const oktaClient = new OktaJS(oktaClientId, oktaConfigUrl, oktaRedirectUri, oktaLogoutRedirectUri, ['openid', 'email', 'profile']);

export default oktaClient;
