import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMiViewScreenFilterMenu from './MiViewScreenFilterMenu.hook';
import {
  formControlStyle,
  selectStyle,
  inputLabelStyle,
  menuItemStyle,
  selectPaperStyle,
} from './MiViewScreenFilterMenu.style';

import { FieldValue, FilterMenuProps } from './MiViewScreenFilterMenu.types';

const MiViewScreenFilterMenu: React.FC<FilterMenuProps> = ({
  name,
  minWidth,
  filterField,
  updateFilterField,
  clearFilterField,
}) => {
  const { t } = useTranslation();
  const { handleChange, fieldValue, field } = useMiViewScreenFilterMenu(filterField, updateFilterField, clearFilterField);

  return (
    <FormControl size="small" sx={ { ...formControlStyle, minWidth } }>
      <InputLabel sx={ inputLabelStyle }>
        { name }
      </InputLabel>
      <Select
        sx={ selectStyle }
        size="small"
        value={ fieldValue }
        onChange={ handleChange }
        label={ name }
        MenuProps={
          {
            PaperProps: {
              sx: { ...selectPaperStyle },
            },
          }
        }
      >
        <MenuItem value="" sx={ menuItemStyle }>
          <em>{ t('maya_cep_step_miview_preview_filter_menu_default_item') }</em>
        </MenuItem>
        {
          field.fieldValues.map((fieldItem: FieldValue, index: number) => (
            <MenuItem key={ index } value={ fieldItem.id } sx={ menuItemStyle }>
              { fieldItem.value }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default MiViewScreenFilterMenu;

