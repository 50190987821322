import { useTranslation } from 'react-i18next';
import { array, mixed, string } from 'yup';
import { BiogenProduct, BiogenTherapeuticArea } from 'basics/enums/biogen.enums';
import { BiogenFunction, Specialities, CustomerTypes } from 'basics/enums/maya.enums';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { TestContextType } from 'basics/types/common.types';
import {
  getUTCDate,
  testDateRangeInValidationRange,
  testEndDayAfterStartDay,
  testEndDayNotBeforeStartDay,
  testStartDateIsSet,
} from 'basics/utils/dates/dates.utils';
import { configurationStepFields } from 'groups/CepFormConfigurationGroup/CepFormConfigurationGroup.constants';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker';
import { YodaFieldsState } from 'yoda-ui/yodaForm/yodaForm.types';

const maxTitleLength = 80;
const maxKpiLength = 255;

const useCepFormConfigurationGroupSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');
  const maxLengthTitleMessage = `${t('maya_cep_step_configuration_title_error_max_char', { count: maxTitleLength })}`;
  const maxLengKpiMessage = `${t('maya_cep_step_configuration_kpi_error_max_char', { count: maxKpiLength })}`;

  return {
    title: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    date: mixed()
      .test({
        name: 'startDate is set',
        message: requiredLabel,
        test: testStartDateIsSet,
      })
      .test({
        name: 'endDate must be after startDate',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayAfterStartDay,
      })
      .test({
        name: 'start date before end date',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayNotBeforeStartDay,
      })
      .test({
        name: 'date in CEP date range',
        test: (dateRange, context) => {
          const fieldsState: YodaFieldsState = (context.options as TestContextType)?.fieldsState;
          const parentCiStartDate = fieldsState[configurationStepFields.ciDateStart].value
            ? new Date(fieldsState[configurationStepFields.ciDateStart].value)
            : null;
          const parentCiEndDate = fieldsState[configurationStepFields.ciDateEnd].value
            ? new Date(fieldsState[configurationStepFields.ciDateEnd].value)
            : null;

          const [cepDateStart, cepDateEnd] = dateRange;
          const cepDateStartUTC = cepDateStart
            ? getUTCDate(cepDateStart, `${(cepDateStart as Date).getHours()}:${(cepDateStart as Date).getMinutes()}`, TimeZone.UTC)
            : null;
          const cepDateEndUTC = cepDateEnd
            ? getUTCDate(cepDateEnd, `${(cepDateEnd as Date).getHours()}:${(cepDateEnd as Date).getMinutes()}`, TimeZone.UTC)
            : null;
          const dateRangeUTC = [
            cepDateStartUTC,
            cepDateEndUTC,
          ] as DateRange;

          if (!testDateRangeInValidationRange(dateRangeUTC, [parentCiStartDate, parentCiEndDate])) {
            return context.createError(
              {
                message: t(
                  'maya_cep_date_range_validation',
                  { startDate: parentCiStartDate?.toLocaleDateString(), endDate: parentCiEndDate?.toLocaleDateString() },
                ),
              },
            );
          }

          return true;
        },
      }),

    customerInitiative: string()
      .required(requiredLabel),
    therapeuticArea: mixed<BiogenTherapeuticArea>()
      .oneOf(Object.values(BiogenTherapeuticArea), requiredLabel)
      .required(requiredLabel),
    product: mixed<BiogenProduct>()
      .oneOf(Object.values(BiogenProduct), requiredLabel)
      .required(requiredLabel),
    biogenFunction: mixed<BiogenFunction>()
      .oneOf(Object.values(BiogenFunction), requiredLabel),
    kpi: string()
      .max(maxKpiLength, maxLengKpiMessage)
      .required(requiredLabel),
    description: string(),
    customerType: array()
      .of(mixed<CustomerTypes>().oneOf(Object.values(CustomerTypes)))
      .required(requiredLabel)
      .min(1),
    specialties: array()
      .of(mixed<Specialities>().oneOf(Object.values(Specialities)))
      .required(requiredLabel)
      .min(1),
  };
};

export default useCepFormConfigurationGroupSchema;
