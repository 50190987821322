import { AlertColor, Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import Alert from 'yoda-ui/components/Alert';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const AlertBanner: FC = () => {
  const flags = useFlags();

  const alert = flags.mayaBiogenlinc4416AlertMessage;

  return (
    <>
      {
        alert.enabled
          ? <Box sx={ { marginBottom: YodaSpacing.large } }>
            <Alert severity={ alert.severity as AlertColor } >
              { alert.message }
            </Alert>
          </Box>
          : null
      }
    </>
  );
};

export default AlertBanner;
