export const mainWrapperStyle = {
  height: 100,
  paddingTop: '10px',
};

export const targetListRowStyle = {
  height: 100,
};

export const listWrapperStyle = {
  height: 580,
};
