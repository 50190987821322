export const targetListFragment = `
  _id
  targetId
  mdmId
  email
  firstName
  lastName
  veevaSyncStatus
`;

export const removetargetListFragment = `
  deletedCount
`;

export const targetsFacetsFragment = `
  types
  country
  hcoNames
  businessUnits
  specialties
  smaSegments
  biosSegments
  imsSegments
  consent
`;

