import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import useMiViewScreenTitle from './MiViewScreenTitle.hook';
import {
  formControlStyle,
  selectStyle,
} from './MiViewScreenTitle.style';

type ScreenTitleProps = {
  componentName: string;
  maxWidth?: number;
  inputLabel?: string;
};

const MiViewScreenTitle: React.FC<ScreenTitleProps> = ({ componentName, maxWidth, inputLabel }) => {
  const { name } = useMiViewScreenTitle(componentName);

  return (
    <FormControl sx={ formControlStyle }>
      <InputLabel>
        { inputLabel }
      </InputLabel>
      {
        name && (
          <Select
            sx={ { ...selectStyle, maxWidth } }
            value={ name }
            size="small"
            onChange={ () => null }
          >
            {
              [name].map((option: string, index) => (
                <MenuItem key={ index } value={ option }>
                  { option }
                </MenuItem>
              ))
            }
          </Select>
        )
      }
    </FormControl>
  );
};

export default MiViewScreenTitle;

