import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LanguageCode } from 'basics/enums/languages.enums';
import { isProduction } from 'config/config';
import deTranslations from 'locales/de/ui.json';
import enTranslations from 'locales/en/ui.json';
import frTranslations from 'locales/fr/ui.json';

export const translations = {
  [LanguageCode.de]: { translation: deTranslations },
  [LanguageCode.en]: { translation: enTranslations },
  [LanguageCode.fr]: { translation: frTranslations },
};

const languageDetectionOptions = {
  order: ['localStorage'],
  lookupLocalStorage: 'currentLanguage_i18',
  caches: ['localStorage'],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: languageDetectionOptions,
    resources: translations,
    fallbackLng: LanguageCode.en,
    keySeparator: false,
    returnEmptyString: false,
    debug: !isProduction,
    interpolation: {
      escapeValue: false,
      prefix: '{',
      suffix: '}',
    },
  });

export default i18n;
