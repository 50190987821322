import { FC } from 'react';
import FieldCard from 'components/FieldCard';

type NoUserCardProps = {
  noItemLabel: string;
};

const NoUserCard: FC<NoUserCardProps> = ({ noItemLabel }) => (
  <FieldCard data-testid="no-user-card" empty>
    { noItemLabel }
  </FieldCard>
);

export default NoUserCard;
