import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { APP_TITLE } from 'basics/constants/common.constants';
import { YodaColors } from 'yoda-ui/yodaTheme';

const MetaTags = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{ APP_TITLE }</title>
      <meta name="description" content={ t('meta_description') } />
      <meta name="theme-color" content={ YodaColors.white } />
    </Helmet>
  );
};

export default MetaTags;
