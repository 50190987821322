import { Avatar, Box, Skeleton } from '@mui/material';
import { FC } from 'react';
import { mainBoxStyle, circularSkeletonBoxStyle, rectangularSkeletonBoxStyle } from './MiViewScreenListSkeleton.style';

type ScreenListSkeletonProps = {
  listItemsToRender: number;
  animated: boolean;
};

const MiViewScreenListSkeleton: FC<ScreenListSkeletonProps> = ({ listItemsToRender, animated }) => {
  const animationType = animated ? 'wave' : false;

  return (
    <>
      {
        Array(listItemsToRender)
          .fill(1)
          .map((card, index) => (
            <Box sx={ mainBoxStyle } key={ index }>
              <Box sx={ circularSkeletonBoxStyle }>
                <Skeleton variant="circular" animation={ animationType }>
                  <Avatar />
                </Skeleton>
              </Box>
              <Box sx={ rectangularSkeletonBoxStyle }>
                <Skeleton variant="rectangular" animation={ animationType } height={ 40 }/>
              </Box>
            </Box>
          ))
      }
    </>
  );
};

export default MiViewScreenListSkeleton;
