import { useTranslation } from 'react-i18next';
import channelsOptions from 'basics/options/channel.options';
import customerTypeOptions from 'basics/options/customerType.options';
import SpecialitiesOptions from 'basics/options/specialty.options';
import useCepTemplateSelectorFiltersSchema from 'components/CepTemplateSelectorFilters/CepTemplateSelectorFilters.schema';
import { CepTemplateSelectorFiltersConfigType } from 'components/CepTemplateSelectorFilters/CepTemplateSelectorFilters.types';

const useCepTemplateSelectorFiltersConfig = () => {
  const { t } = useTranslation();

  const cepTemplateSelectorFiltersSchema = useCepTemplateSelectorFiltersSchema();

  const cepTemplateSelectorFiltersConfig: CepTemplateSelectorFiltersConfigType = {
    customerType: {
      label: t('maya_cep_template_selector_filters_customer_type_label'),
      name: 'customerType',
      placeholder: t('maya_cep_template_selector_filters_customer_type_placeholder'),
      required: false,
      defaultValue: [],
      options: customerTypeOptions,
      validation: cepTemplateSelectorFiltersSchema.customerType,
    },
    specialty: {
      label: t('maya_cep_template_selector_filters_specialty_label'),
      name: 'specialty',
      placeholder: t('may_cep_template_selector_filters_specialty_placeholder'),
      options: SpecialitiesOptions,
      defaultValue: [],
      required: false,
      validation: cepTemplateSelectorFiltersSchema.specialty,
    },
    channel: {
      label: t('may_cep_template_selector_filters_channel_label'),
      name: 'channel',
      placeholder: t('maya_cep_template_selector_filters_channel_placeholder'),
      options: channelsOptions,
      defaultValue: [],
      required: false,
      validation: cepTemplateSelectorFiltersSchema.channel,
    },
  };

  return {
    cepTemplateSelectorFiltersConfig,
  };
};

export default useCepTemplateSelectorFiltersConfig;
