import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import InfoTag from 'components/InfoTag/InfoTag';
import { CepViewPropsType } from 'routes/AuthenticatedApp/CepView/CepView.types';
import useCepGoalView from 'routes/AuthenticatedApp/CepView/Steps/CepGoalView/CepGoalView.hook';
import { sideContainerStyle } from 'routes/AuthenticatedApp/CepView/Steps/CepGoalView/CepGoalView.styles';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CepGoalView: FC<CepViewPropsType> = () => {
  const { t } = useTranslation();
  const {
    cep,
    loading,
  } = useCepGoalView();

  return (
    <>
      {
        loading
          ? (
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Loader center />
            </Box>
          )
          : (
            <Box>
              <Box
                sx={ sideContainerStyle.firstBox }
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                padding={ YodaSpacing.medium }
              >
                <Box marginBottom={ YodaSpacing.xxSmall } marginTop={ YodaSpacing.xxSmall }>
                  <Box>
                    <InfoTag
                      title={ t('maya_cep_step_goal_targetEngagementGoal_label') }
                      field={ cep?.targetEngagementGoal?.toString() }
                    />
                    <InfoTag
                      title={ t('maya_cep_step_goal_npsGoal_label') }
                      field={ cep?.npsGoal?.toString() }
                    />
                    <InfoTag
                      title={ t('maya_cep_step_goal_reachGoal_label') }
                      field={ cep?.reachGoal?.toString() }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )
      }
    </>
  );
};

export default CepGoalView;

