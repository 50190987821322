import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

export const TacticCardWrapperStyle: SxProps<Theme> = {
  padding: '1rem',
  border: 'none',
  boxShadow: `0px 5px 20px ${YodaColors.gray2}`,
};

export const printViewLayout: SxProps<Theme> = {
  margin: YodaSpacing.small,
  width: '100vw',
};

export const TitlePrintStyle: SxProps<Theme> = {
  textAlign: 'center',

  '& .titlePrintView': {
    marginBottom: YodaSpacing.xSmall,
    fontSize: YodaFontSize.xxxLarge,
    fontWeight: YodaFontWeight.medium,
  },

  '& .datePrintView': {
    marginBottom: YodaSpacing.large,
    fontSize: YodaFontSize.xxLarge,
    fontWeight: YodaFontWeight.light,
  },
};

export const PrintWrapperStyle: SxProps<Theme> = {
  width: '100%',
};

export const TacticPrintViewStyle: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',

  '& > *': {
    verticalAlign: 'middle',
    marginBottom: YodaSpacing.xxxLarge,
    marginLeft: YodaSpacing.xxxSmall,
    marginRight: YodaSpacing.xxxSmall,
  },
};

export const TacticCardListStyle: SxProps<Theme> = {
  '& > *': {
    width: '160px',
    marginBottom: YodaSpacing.xxxSmall,
  },

  '& > *:hover': {
    backgroundColor: YodaColors.blue1,
    boxShadow: `0 0 7px 1px ${YodaColors.gray2}`,
  },
};

