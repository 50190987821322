import { FC } from 'react';
import { ContainerPropsType } from './Container/Container.types';
import Box from 'yoda-ui/components/Box';

const LargeContainer: FC<ContainerPropsType> = ({ children, ...rest }) => (
  <Box
    position="relative"
    width="100%"
    alignSelf="stretch"
    display="flex"
    { ...rest }
  >
    { children }
  </Box>
);

export default LargeContainer;
