import { VeevaVaultDocument } from 'generated/maya.types';

const useAssetIdOptions = (veevaVaultDocument: VeevaVaultDocument[]) => {
  const assetIdOptions = veevaVaultDocument.map((element: VeevaVaultDocument) => {
    return {
      id: element.documentNumber,
      label: `[${element.documentNumber}] [${element.status}] ${element.name}` as string,
    };
  });

  return assetIdOptions;
};

export default useAssetIdOptions;
