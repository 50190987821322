import { YodaSpacing } from 'yoda-ui/yodaTheme';

export const mayaLogoStyle = {
  height: '2.5rem',
  marginTop: YodaSpacing.xxxSmall,
  marginLeft: YodaSpacing.small,

  '& > *:hover': {
    cursor: 'pointer',
  },
};

