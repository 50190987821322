import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MiViewSpacing } from 'miview-ui/miviewTheme';

export const mainBoxStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

export const circularSkeletonBoxStyle: SxProps<Theme> = {
  margin: MiViewSpacing.xxSmall,
};

export const rectangularSkeletonBoxStyle: SxProps<Theme> = {
  width: '100%',
};

