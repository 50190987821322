export enum BiogenTherapeuticArea {
  ALZHEIMER = 'Alzheimer',
  BIOSIMS = 'Biosims',
  FA = 'FA',
  MS = 'MS',
  NONE = 'None',
  SMA = 'SMA',
}

export enum BiogenProduct {
  Aducanumab = 'Aducanumab_INT',
  Avonex = 'Avonex_INT',
  Benepali = 'Benepali_INT',
  Biogen = 'Biogen_INT',
  Biosimilar = 'Biosimilar_DT_INT',
  Byooviz = 'Byooviz_INT',
  Fampyra = 'Fampyra_INT',
  Flixabi = 'Flixabi_INT',
  Imraldi = 'Imraldi_INT',
  MS_Portfolio = 'MS_INT',
  Omaveloxolone = 'Omaveloxolone_INT',
  Plegridy = 'Plegridy_INT',
  Plegridy_IM = 'Plegridy_IM_INT',
  Skyclarys = 'Skyclarys_INT',
  Spinraza = 'Spinraza_INT',
  Tecfidera = 'Tecfidera_INT',
  Tysabri = 'TysabriSC_INT',
  Tysabri_IV = 'Tysabri IV_INT',
  Vumerity = 'Vumerity_INT',
}
