import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

export const sideContainerStyle = {
  firstBox: {
    backgroundColor: YodaColors.white60,
  },
};

export const buttonStyle = {
  justifyContent: 'start',
  marginTop: YodaSpacing.small,
};

