import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const EXPORT_TARGET_LIST = gql`
query($cepId: String!) {
  exportTargetList(cepId: $cepId)
}
`;

type ResultType = {
  exportTargetList: string;
};

type UseExportTargetList = () => {
  data: unknown;
  error?: ApolloError;
  exportTargetList: Function;
  loading: boolean;
};

const useExportTargetListQuery: UseExportTargetList = () => {
  const [exportTargetList, { data, loading, error }] = useLazyQuery<ResultType>(EXPORT_TARGET_LIST, {
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      customContext,
      error,
      transactionName: 'exportTargetList.ts - useExportTargetListQuery',
    });
  }

  return {
    data: data?.exportTargetList || null,
    error,
    exportTargetList,
    loading,
  };
};

export default useExportTargetListQuery;
