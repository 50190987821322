import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  mayaCepCreateRoute,
  mayaCepDuplicateRoute,
  mayaCepEditRoute,
  mayaCepListRoute,
  mayaCepTemplateCreateRoute,
  mayaCepTemplateEditRoute,
  mayaCepTemplateListRoute,
  mayaCepTemplateSelectorRoute,
  mayaCepTrashedListRoute,
  mayaCepViewRoute,
  mayaCiTemplateCreateRoute,
  mayaCiTemplateEditRoute,
  mayaCiTemplateListRoute,
  mayaCiViewRoute,
  mayaCustomerInitiativeCreateRoute,
  mayaCustomerInitiativeDuplicateRoute,
  mayaCustomerInitiativeEditRoute,
  mayaCustomerInitiativeListRoute,
  mayaImportExportRoute,
  mayaSiViewRoute,
  mayaStrategicImperativeCreateRoute,
  mayaStrategicImperativeDuplicateRoute,
  mayaStrategicImperativeEditRoute,
  mayaStrategicImperativeListRoute,
  mayaTacticPrintRoute,
} from 'basics/constants/routes.constants';

type MapTitle = {
  [key: string]: string;
};

const mapTitle: MapTitle = {
  [mayaStrategicImperativeListRoute]: 'maya_main_menu_strategic_imperative_label',
  [mayaCustomerInitiativeListRoute]: 'maya_main_menu_customer_initiative_label',
  [mayaCiTemplateListRoute]: 'maya_main_menu_ci_tpl_label',
  [mayaCepListRoute]: 'maya_main_menu_cep_label',
  [mayaCepTemplateListRoute]: 'maya_main_menu_cep_tpl_label',
  [mayaStrategicImperativeCreateRoute]: 'maya_strategic_imperative_form_create_title',
  [mayaCustomerInitiativeCreateRoute]: 'maya_customer_initiative_form_title',
  [mayaCiTemplateCreateRoute]: 'maya_ci_tpl_form_title',
  [mayaCepCreateRoute]: 'maya_create_cep_form_title',
  [mayaCepTemplateCreateRoute]: 'maya_cep_tpl_form_title',
  [mayaCepTemplateSelectorRoute]: 'maya_choose_cep_template_title',
  [mayaStrategicImperativeEditRoute]: 'maya_strategic_imperative_form_edit_title',
  [mayaCustomerInitiativeEditRoute]: 'maya.customerInitiativeEditTitle',
  [mayaCiTemplateEditRoute]: 'maya_ci_tpl_edit_form_title',
  [mayaCepEditRoute]: 'maya_edit_cep_form_title',
  [mayaCepTemplateEditRoute]: 'maya_cep_tpl_edit_form_title',
  [mayaStrategicImperativeDuplicateRoute]: 'maya_strategic_imperative_form_duplicate_title',
  [mayaCustomerInitiativeDuplicateRoute]: '',
  [mayaCepDuplicateRoute]: 'maya.customerInitiativeDuplicateTitle',
  [mayaSiViewRoute]: 'maya_si_view',
  [mayaCiViewRoute]: 'maya_ci_view',
  [mayaCepViewRoute]: 'maya_cep_view',
  [mayaImportExportRoute]: 'maya_aop_import_title',
  [mayaCepTrashedListRoute]: 'maya_main_menu_cep_trashed_label',
  [mayaTacticPrintRoute]: '',
};

export const useTitle = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [title, setTitle] = useState('');

  useEffect(() => {
    const key = mapTitle[location.pathname];

    setTitle(() => (location.pathname === '/' ? 'Home page' : t(key)));
  }, [location, t]);

  return title;
};
