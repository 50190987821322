import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import GridListAction from 'components/GridListAction/GridListAction';

const useStrategicImperativeListConfig = (
  editSiCallback: (siId: string) => void,
  duplicateSiCallback: (siId: string) => void,
  addChildAction: (siId: string) => void,
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const siTitleFieldConfig = {
    label: t('maya_si_form_si_title_label'),
    name: 'name',
    placeholder: t('maya_si_form_si_title_placeholder'),
    defaultValue: '',
    required: false,
  };

  const strategicImperativeListColumnConfig: GridColDef[] = [
    {
      field: 'title',
      headerName: t('maya_strategic_imperative_list_title_header_label'),
      flex: 0.4,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'therapeuticArea',
      headerName: t('maya_strategic_imperative_list_TA_header_label'),
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'updated',
      type: 'date',
      headerName: t('maya_strategic_imperative_list_last_update_header_label'),
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const actions = [
          {
            label: t('maya_strategic_imperative_list_add_child_customer_initiative'),
            callback: () => {
              addChildAction(params.row._id);
            },
          },
        ];

        if (flags.mayaBiogenlinc4493EnableStrategicImperativeManagement) {
          actions.push({
            label: t('actions_edit_label'),
            callback: () => { editSiCallback(params.row._id); },
          },
          {
            label: t('actions_duplicate_label'),
            callback: () => { duplicateSiCallback(params.row._id); },
          });
        }

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.1,
    },
  ];

  return { strategicImperativeListColumnConfig, siTitleFieldConfig };
};

export default useStrategicImperativeListConfig;

