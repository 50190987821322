import { MiViewColors, MiViewSpacing, MiViewJustifyContent } from 'miview-ui/miviewTheme';

export const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: MiViewJustifyContent.start,
  paddingLeft: MiViewSpacing.xxxSmall,
  backgroundColor: MiViewColors.primary,
  color: MiViewColors.tertiary,
  height: 46,
  paddingTop: MiViewSpacing.xxxSmall,
  paddingBottom: MiViewSpacing.xxxSmall,
  position: 'sticky',
  top: 0,
  zIndex: 2,
};

