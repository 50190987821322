import { FC } from 'react';
import MiViewNavbar from '../MiViewNavbar/MiViewNavbar';
import { boxStyle } from './MiView.style';
import MiViewScreenLayout from 'components/MiViewScreenLayout/MiViewScreenLayout';
import Box from 'yoda-ui/components/Box';

const MiView: FC = () => {
  return (
    <Box sx={ boxStyle }>
      <MiViewNavbar></MiViewNavbar>
      <MiViewScreenLayout></MiViewScreenLayout>
    </Box>
  );
};

export default MiView;
