import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

export const TacticKanbanStyle: SxProps<Theme> = (theme) => ({
  marginTop: YodaSpacing.medium,
  paddingBottom: YodaSpacing.medium,
  display: 'flex',
  overflowX: 'auto',
  overflowY: 'hidden',
  maxWidth: '58vw',

  '& > *': {
    marginLeft: YodaSpacing.small,
    marginRight: YodaSpacing.small,
    minWidth: '250px',
  },

  [theme.breakpoints.down('xl')]: {
    maxWidth: '50vw',
  },
});

export const TacticCardWrapperStyle: SxProps<Theme> = {
  padding: '1rem',
  border: 'none',
  boxShadow: `0px 5px 20px ${YodaColors.gray2}`,
};

export const TacticCardListStyle: SxProps<Theme> = {
  '& > *': {
    maxWidth: '250px',
    marginBottom: YodaSpacing.small,
  },
  '& > *:hover': {
    backgroundColor: YodaColors.blue1,
    boxShadow: `0 0 7px 1px ${YodaColors.gray2}`,
  },
};

export const ButtonHeadStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: YodaJustifyContent.flexEnd,

  '& .MuiButton-root:first-of-type': {
    marginLeft: 'auto',
    marginRight: YodaSpacing.medium,
  },
};
