import { Box } from '@mui/material';
import { FC } from 'react';
import { dashboardFiltersForm } from 'components/DashboardFilters/DashboardFilters.style';
import { DashboardFiltersPropsType } from 'components/DashboardFilters/DashboardFilters.types';
import FormContainer from 'components/FormContainer/FormContainer';
import { FormLayoutContainer } from 'components/FormLayout';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import { YodaJustifyContent } from 'yoda-ui/yodaTheme';

const DashboardFilters: FC<DashboardFiltersPropsType> = ({
  dashboardFiltersConfig,
  providerFields,
  handleChangeTA,
  handleChangeProduct,
  submitButtonConfig,
  resetButtonConfig,
}) => {
  return (
    <FormContainer providerFields={ providerFields } cancelButtonConfig={ resetButtonConfig } submitButtonConfig={ submitButtonConfig } >
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } sx={ dashboardFiltersForm } >
        <Box>
          <MultiSelectYoda { ...dashboardFiltersConfig.countries } />
        </Box>
        <Box>
          <SelectYoda { ...dashboardFiltersConfig.therapeuticArea } onChange={ handleChangeTA } />
        </Box>
        <Box>
          <MultiSelectYoda { ...dashboardFiltersConfig.product } onChange={ handleChangeProduct } />
        </Box>
        <Box>
          <MultiSelectYoda { ...dashboardFiltersConfig.cepFunction } />
        </Box>
        <Box>
          <MultiSelectYoda { ...dashboardFiltersConfig.cepStatus } />
        </Box>
      </FormLayoutContainer>

      <Box>
        <DateRangePicker { ...dashboardFiltersConfig.date } />
      </Box>
    </FormContainer>
  );
};

export default DashboardFilters;
