import { proxy } from 'valtio';
import { CepStateType } from 'basics/types/maya.types';

const cepState = proxy<CepStateType>({
  cep: {},
  tactics: [],
  targetList: [],
});

export default cepState;
