import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FieldState, SetFieldState, TextFieldBasis } from 'components/QuickForm/QuickForm.types';

type UseForm2Fields = {
  fieldState: FieldState;
  firstFieldConfig: TextFieldBasis;
  secondFieldConfig: TextFieldBasis;
  setFieldState: SetFieldState;
  transformToState: Function;
};

const useForm2Fields = ({
  fieldState,
  firstFieldConfig,
  secondFieldConfig,
  setFieldState,
  transformToState,
}: UseForm2Fields) => {
  const { formFieldName: firstFormFieldName, ...firstTextFieldConfig } = firstFieldConfig;
  const { formFieldName: secondFormFieldName, ...secondTextFieldConfig } = secondFieldConfig;
  const { t } = useTranslation();
  const [firstField, setFirstField] = useState('');
  const [secondField, setSecondField] = useState('');
  const [canAddItem, setCanAddItem] = useState(false);

  const isDuplicatedItem = useCallback(
    () => fieldState.find(
      (item) => (item[firstFormFieldName] === firstField) && (item[secondFormFieldName] === secondField),
    ),
    [firstField, firstFormFieldName, secondField, secondFormFieldName, fieldState],
  );

  useEffect(() => {
    setCanAddItem(!!firstField && !!secondField && !isDuplicatedItem());
  }, [firstField, secondField, isDuplicatedItem]);

  const handleAddItem = () => {
    if (firstField && secondField && !isDuplicatedItem()) {
      const currentItem = {
        id: uuidv4(),
        [firstFormFieldName]: firstField,
        [secondFormFieldName]: secondField,
      };
      const newItem = transformToState(currentItem);
      setFieldState((prevState) => [...prevState, newItem]);
      setFirstField('');
      setSecondField('');
    }
  };

  return {
    canAddItem,
    firstField,
    firstTextFieldConfig,
    secondField,
    secondTextFieldConfig,
    handleAddItem,
    setFirstField,
    setSecondField,
    t,
  };
};

export default useForm2Fields;
