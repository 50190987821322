import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { CepCreationMode } from 'basics/enums/cep.enums';

const useCepTemplateSelectorFormConfig = (
  cepCreationMode: CepCreationMode,
  setCepCreationMode: Dispatch<SetStateAction<CepCreationMode>>,
) => {
  const { t } = useTranslation();

  const cepTemplateCreationModeRadioConfigs = [
    {
      label: t('maya_use_cep_template'),
      key: 'dynamic_list',
      value: CepCreationMode.template,
      checked: cepCreationMode === CepCreationMode.template,
      disabled: false,
    },
    {
      label: t('maya_create_custom_cep'),
      key: 'csv_list',
      value: CepCreationMode.custom,
      checked: cepCreationMode === CepCreationMode.custom,
      disabled: false,
    },
  ];

  const cepTemplateSelectorFormConfig = {
    selectCreationMode: {
      name: 'selectCreationMode',
      radioConfigs: cepTemplateCreationModeRadioConfigs,
      row: true,
      defaultValue: CepCreationMode.template,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        setCepCreationMode(event.target.value as CepCreationMode);
      },
    },
  };

  return {
    cepTemplateSelectorFormConfig,
  };
};

export default useCepTemplateSelectorFormConfig;
