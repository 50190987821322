import { Box, Badge } from '@mui/material';
import React from 'react';

type NewDataIconType = React.FC<{
  icon: React.ReactNode;
  notificationNumber?: number;
  handleOpen?: () => void;
}>;

const MiViewNewDataIcon: NewDataIconType = ({
  icon,
  notificationNumber,
  handleOpen,
}) => {
  return (
    <Box
      onClick={ handleOpen }
      sx={
        {
          marginRight: '15px',
        }
      }
    >
      <Badge badgeContent={ notificationNumber } color="error">
        { icon }
      </Badge>
    </Box>
  );
};

export default MiViewNewDataIcon;
