import { ContactMail, Group, Notifications, DarkModeRounded, Settings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { FC } from 'react';
import {
  boxStyle,
  appBarStyle,
  miViewLogoIconButtonStyle,
  menuIconStyle,
  miViewLogoStyle,
  iconStyle,
  darkModeIconStyle,
  boxFirstSeparatorStyle,
  boxSecondSeparatorStyle,
} from './MiViewNavbar.style';
import MiViewNewDataIcon from 'miview-ui/components/MiViewNewDataIcon';
import MyViewLogo from 'miview-ui/images/MyViewLogo.svg';

const MiViewNavbar: FC = () => {
  return (
    <Box sx={ boxStyle }>
      <AppBar position="sticky" sx={ appBarStyle }>
        <Toolbar>
          <IconButton size="small" edge="start" disabled={ true }>
            <MenuIcon sx={ menuIconStyle }/>
          </IconButton>
          <IconButton size="small" edge="start" disabled={ true } sx={ miViewLogoIconButtonStyle }>
            <MiViewNewDataIcon icon={ <img style={ miViewLogoStyle } src={ MyViewLogo } /> }/>
          </IconButton>

          <Box sx={ boxFirstSeparatorStyle }></Box>
          <Box sx={ boxSecondSeparatorStyle }></Box>

          <IconButton size="small" disabled={ true }>
            <MiViewNewDataIcon icon={ <Notifications sx={ iconStyle } /> }/>
          </IconButton>

          <IconButton size="small" disabled={ true }>
            <MiViewNewDataIcon icon={ <ContactMail sx={ iconStyle } /> }/>
          </IconButton>

          <IconButton size="small" disabled={ true }>
            <MiViewNewDataIcon icon={ <Group sx={ iconStyle } /> }/>
          </IconButton>

          <IconButton size="small" disabled={ true }>
            <DarkModeRounded sx={ darkModeIconStyle } />
          </IconButton>

          <IconButton size="small" edge="end" disabled={ true }>
            <Settings sx={ iconStyle } />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MiViewNavbar;
