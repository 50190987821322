import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { miViewTheme, MiViewColors, MiViewBorderRadius } from 'miview-ui/miviewTheme';

export const boxStyle: SxProps<Theme> = {
  '.MuiBox-root *': {
    fontFamily: miViewTheme.typography.fontFamily,
  },
  '& ::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
    overflowY: 'scroll',
    background: MiViewColors.lightGray,
  },
  '& ::-webkit-scrollbar-thumb': {
    background: MiViewColors.gray,
    borderRadius: MiViewBorderRadius.xSmall,
  },
};
