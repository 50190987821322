import { TFunction } from 'i18next';
import { intersectionBy } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { ErrorCode, FileError, FileRejection } from 'react-dropzone';
import { FileExtension } from 'basics/types/files.types';
import { ValidationResult } from 'basics/types/utils.types';
import { convertFileSizeToKb } from 'basics/utils/files/files.utils';
import { FieldState } from 'components/QuickForm/QuickForm.types';

enum CustomErrorCode {
  UniqueFileName = 'unique-file-name',
}

type MapRejectedFilesErrors = (
  fileRejections: FileRejection[],
  customErrorsMap: Record<ErrorCode | string, string>,
) => string;

type GetCustomErrorsMap = (props: {
  t: TFunction;
  extensionsAllowed: FileExtension[];
  maxFileSize: number;
  maxFilesNumber: number;
  minFileSize: number;
}) => Record<ErrorCode | CustomErrorCode, string>;

export const getCustomErrorsMap: GetCustomErrorsMap = ({
  t,
  extensionsAllowed,
  maxFileSize,
  maxFilesNumber,
  minFileSize,
}) => ({
  [ErrorCode.FileTooLarge]: `
    ${t('maya_asset_dropzone_max_file_size_error_message',
    { maxSize: convertFileSizeToKb(maxFileSize) })}
  `,
  [ErrorCode.TooManyFiles]: `
    ${t('maya_asset_dropzone_max_number_error_message',
    { max: maxFilesNumber })}
  `,
  [ErrorCode.FileInvalidType]: `
    ${t('maya_asset_dropzone_invalid_type_error_message',
    { fileTypes: extensionsAllowed.join(', ') })}
  `,
  [ErrorCode.FileTooSmall]: `
    ${t('maya_asset_dropzone_min_file_size_error_message',
    { minSize: convertFileSizeToKb(minFileSize) })}
  `,
  [CustomErrorCode.UniqueFileName]: 'Filename already used',
});

export const mapRejectedFilesErrors: MapRejectedFilesErrors = (fileRejections, customErrorsMap) => {
  const errorsList: FileError[] = [];
  fileRejections.forEach((fileRejection) => {
    errorsList.push(...[...fileRejection.errors]);
  });
  return uniqBy(errorsList, 'code').map((error) => customErrorsMap[error.code] || error.message).join(', ');
};

export const validateNumberOfFiles = ({ currentFilesLength, newFilesLength, maxFilesNumber }: {
  currentFilesLength: number;
  newFilesLength: number;
  maxFilesNumber: number;
}): ValidationResult => {
  // React-dropzone lib enables the possibility of having `maxFiles` set to 0 to indicate "infinite" number of files
  // This behavior needs to be reproduced here
  if (maxFilesNumber !== 0) {
    const tooManyFiles = currentFilesLength + newFilesLength > maxFilesNumber;
    if (tooManyFiles) {
      return {
        isValid: false,
        errors: [ErrorCode.TooManyFiles],
      };
    }
  }
  return { isValid: true, errors: null };
};

export const validateUniqueFileName = ({ fieldState, acceptedFiles }: {
  fieldState: FieldState;
  acceptedFiles: File[];
}): ValidationResult => {
  const filesListIntersection = intersectionBy(fieldState, acceptedFiles, 'name');
  if (filesListIntersection.length > 0) {
    return { isValid: false, errors: [CustomErrorCode.UniqueFileName] };
  }
  return { isValid: true, errors: null };
};

export const validateAcceptedFiles = ({ fieldState, acceptedFiles, maxFilesNumber }: {
  fieldState: FieldState;
  acceptedFiles: File[];
  maxFilesNumber: number;
}): ValidationResult => {
  const numberOfFilesIsValid = validateNumberOfFiles({
    currentFilesLength: fieldState.length,
    newFilesLength: acceptedFiles.length,
    maxFilesNumber,
  });
  if (!numberOfFilesIsValid.isValid) {
    return numberOfFilesIsValid;
  }

  const filesNamesAreValid = validateUniqueFileName({ fieldState, acceptedFiles });
  if (!filesNamesAreValid.isValid) {
    return filesNamesAreValid;
  }

  return { isValid: true, errors: null };
};
