import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { customerInitiativeFragment } from 'basics/graphql/fragments/customerInitiative.fragments';
import { MutationReturnType } from 'basics/types/maya.types';
import { CustomerInitiative } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateCustomerInitiativeMutationCall = gql`
mutation updateCustomerInitiative($id: String!, $updateCustomerInitiative: UpdateCustomerInitiativeInput!, $sendToVeeva: Boolean!) {
  updateCustomerInitiative(id: $id, updateCustomerInitiativeInput: $updateCustomerInitiative, sendToVeeva: $sendToVeeva) {
    ${customerInitiativeFragment}
  }
}
`;

type ResultType = {
  updateCustomerInitiative: CustomerInitiative;
};

type UseUdpdateCustomerInitiativeMutation = [
  (
    id: string | null,
    customerInitiative: Partial<CustomerInitiative>,
    sendToVeeva?: boolean
  ) => Promise<MutationReturnType<CustomerInitiative>>,
  MutationResult<ResultType>,
];

export const useUpdateCustomerInitiativeMutation = (): UseUdpdateCustomerInitiativeMutation => {
  const [updateCustomerInitiative, state] = useMutation<ResultType>(updateCustomerInitiativeMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (id, customerInitiative, sendToVeeva = true) => {
    try {
      const { data } = await updateCustomerInitiative({
        variables: {
          id,
          updateCustomerInitiative: customerInitiative,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });

      return { data: data?.updateCustomerInitiative || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, customerInitiative);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updateCustomerInitiative.ts - useUpdateCustomerInitiativeMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [flags.mayaEnableVeevaBiogenlinc1816, updateCustomerInitiative]);

  return [executeMutation, state];
};
