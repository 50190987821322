import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mayaCepCreateRoute } from 'basics/constants/routes.constants';
import { CepCreationMode } from 'basics/enums/cep.enums';
import useCepTemplateSelectorFormConfig from 'components/CepTemplateSelectorForm/CepTemplateSelectorForm.config';
import { CepTemplateSelectorFiltersType } from 'components/CepTemplateSelectorForm/CepTemplateSelectorForm.types';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useCepTemplateSelectorForm = () => {
  const { providerFields } = useYodaCreateForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cepCreationMode, setCepCreationMode] = useState(CepCreationMode.template);
  const [cepTemplateSelectorFilters, setCepTemplateSelectorFilters] = useState<CepTemplateSelectorFiltersType>();
  const { cepTemplateSelectorFormConfig } = useCepTemplateSelectorFormConfig(cepCreationMode, setCepCreationMode);

  const submitButtonConfig = {
    buttonProps: {
      onClick: () => { if (cepCreationMode === CepCreationMode.custom) navigate(mayaCepCreateRoute); },
      buttonType: ButtonType.primary,
      disabled: cepCreationMode !== CepCreationMode.custom,
    },
    label: t('actions_continue_label'),
  };

  return {
    cepCreationMode,
    cepTemplateSelectorFormConfig,
    providerFields,
    submitButtonConfig,
    setCepTemplateSelectorFilters,
    cepTemplateSelectorFilters,
  };
};

export default useCepTemplateSelectorForm;
