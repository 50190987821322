import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_CEP_REACH = gql`
query getCepReach($cepId: String!) {
  getCepReach(cepId: $cepId)
}
`;

type ResultType = {
  getCepReach: number;
};

type UseGetCepReach = (cepId?: string) => {
  loading: boolean;
  error?: ApolloError;
  cepReach?: number;
};

const useGetCepReachQuery: UseGetCepReach = (cepId?: string) => {
  const { data, loading, error } = useQuery<ResultType>(GET_CEP_REACH, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip: !cepId,
    variables: {
      cepId,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getCepReach.ts - useGetCepReachQuery',
    });
  }

  return {
    cepReach: data?.getCepReach,
    loading,
    error,
  };
};

export default useGetCepReachQuery;
