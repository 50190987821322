import deLocale from 'date-fns/locale/de';
import gbLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import { useTranslation } from 'react-i18next';
import { LanguageCode } from 'basics/enums/languages.enums';

const MapLocale = {
  [LanguageCode.en]: gbLocale,
  [LanguageCode.de]: deLocale,
  [LanguageCode.fr]: frLocale,
};

const useLocale = () => {
  const { i18n } = useTranslation();
  const language = i18n.language as LanguageCode;

  const locale = (language && MapLocale[language]) || gbLocale;
  return {
    locale,
  };
};

export default useLocale;
