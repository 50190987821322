import { array, object, string } from 'yup';

const useUploadTargetListSchema = () => ({
  targetList: array()
    .nullable(true)
    .of(object().shape({
      mdmid: string().required(),
      email: string(),
      firstName: string(),
      lastName: string(),
    })),
});

export default useUploadTargetListSchema;
