import { FC } from 'react';
import { DragDropContext, DropResult, Droppable, Draggable } from 'react-beautiful-dnd';
import CardsMapper from '../CardsBucket';
import NoItemCard from '../CardsBucket/NoItemCard';
import { FieldState } from '../QuickForm.types';
import { ListProps } from './ListDragAndDrop.types';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const List: FC<ListProps> = (props) => {
  const { fieldState, setFieldState, handleRemoveItem, handleEditItem, list, transformToCard, disabled, loading = false } = props;
  const { shape, listConfig: { title, noItemLabel } } = list;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const reorder = (list: FieldState | Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      fieldState,
      result.source.index,
      result.destination.index,
    );
    setFieldState?.(items);
  };

  return (
    <>
      <Box display="flex" marginBottom={ YodaSpacing.small }>
        { title }
        {
          loading && (
            <Box display="flex" alignItems="center" marginLeft={ YodaSpacing.xxSmall }>
              <Loader size={ 1.25 } center />
            </Box>
          )
        }
      </Box>
      <DragDropContext onDragEnd={ (result) => onDragEnd(result) }>
        <Droppable droppableId="droppable">
          {
            (provided) => (
              <div
                ref={ provided.innerRef }
              >
                <Box paddingBottom={ YodaSpacing.medium } data-testid="items-container">
                  {
                    fieldState && fieldState.length > 0 ? (
                      fieldState.map((item, id) => {
                        const transformedItem = transformToCard(item);
                        return (
                          <Draggable draggableId={ item.id } index={ id } key={ item.id }>
                            {
                              (dragProvided) => (
                                <div
                                  ref={ dragProvided.innerRef }
                                  { ...dragProvided.draggableProps }
                                  { ...dragProvided.dragHandleProps }
                                >
                                  <CardsMapper
                                    key={ transformedItem.id }
                                    card={ { listShape: shape, item: transformedItem } }
                                    handleRemoveItem={ handleRemoveItem }
                                    handleEditItem={ handleEditItem }
                                    disabled={ disabled }
                                  />
                                </div>
                              )
                            }
                          </Draggable>
                        );
                      })
                    ) : (
                      <NoItemCard noItemLabel={ noItemLabel } />
                    )
                  }
                </Box>

                { provided.placeholder }
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default List;
