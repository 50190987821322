import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { CepViewStepsIndex } from './CepView.types';
import { StepItem } from 'yoda-ui/components/Stepper/Stepper';

const useCepViewStepper = () => {
  const { t } = useTranslation();
  const flags = useFlags();

  const stepsList: StepItem[] = [
    {
      title: `1. ${t('maya_cep_step_configuration')}`,
      isValid: true,
      disabled: false,
    },
    {
      title: `2. ${t('maya_cep_step_goal')}`,
      isValid: true,
      disabled: false,
    },
    {
      title: `3. ${t('maya_cep_step_target_list')}`,
      isValid: true,
      disabled: false,
    },
    {
      title: `4. ${t('maya_cep_step_tactics')}`,
      isValid: true,
      disabled: false,
    },
    {
      title: `5. ${t('maya_cep_step_miview_preview')}`,
      isValid: true,
      disabled: false,
    },
  ];

  if (!flags.mayaBdcfcsd714EnableMiViewPreview) {
    stepsList.splice(CepViewStepsIndex.miViewPreviewStep, 1);
  }

  return stepsList;
};

export default useCepViewStepper;
