import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BIOGEN_LOGO_FOOTER } from 'basics/constants/images.constants';
import { useGetConfig } from 'basics/utils/getConfig.hook';
import { logoStyle } from 'components/Layout/Footer/Footer.styles';
import { getConfigVar } from 'config/config';
import Box from 'yoda-ui/components/Box';
import Grid from 'yoda-ui/components/Grid';
import Link from 'yoda-ui/components/Links/Link';
import {
  YodaColors,
  YodaFontWeight,
  YodaGridCellSize,
  YodaSpacing,
} from 'yoda-ui/yodaTheme';

const footerStyles = {
  grid: {
    width: '100%',
    backgroundColor: `${YodaColors.gray5}`,
    margin: 0,
    marginTop: 'auto',
  },
  image: {
    width: '9.125rem',
  },
};

const Footer: FC = () => {
  const { t } = useTranslation();
  const currentModuleConfig = useGetConfig();
  const location = useLocation();
  const currentURL = location.pathname;

  if (currentModuleConfig.noLayoutURL.includes(currentURL)) {
    return null;
  }

  const openHomePage = () => {
    window.location.href = getConfigVar('REACT_APP_BACKOFFICE_HOMEPAGE_URI');
  };

  return (
    <Grid container spacing={ YodaSpacing.xxxSmall } sx={ footerStyles.grid }>
      <Grid item xs={ YodaGridCellSize.xSmall }>
        <Box sx={ logoStyle } onClick={ openHomePage }>
          <img src={ BIOGEN_LOGO_FOOTER } alt="biogen-logo" style={ footerStyles.image } />
        </Box>
      </Grid>
      <Grid item xs={ YodaGridCellSize.xSmall }>
        <Box marginLeft={ YodaSpacing.large } paddingBottom={ YodaSpacing.xLarge }>
          <Box
            color={ YodaColors.white }
            marginTop={ YodaSpacing.medium }
            component="h2"
          >
            { currentModuleConfig.title }
          </Box>
          <Box color={ YodaColors.white } marginTop={ YodaSpacing.medium }>
            <Link
              href={ `${currentModuleConfig.support}` }
              target="_blank"
              color={ YodaColors.inherit }
            >
              { t('footer_support') }
            </Link>
          </Box>
          <Box
            color={ YodaColors.white }
            marginTop={ YodaSpacing.xxxSmall }
            fontWeight={ YodaFontWeight.medium }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
