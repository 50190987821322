import { useTranslation } from 'react-i18next';
import { mixed, string } from 'yup';
import { BiogenTherapeuticArea } from 'basics/enums/biogen.enums';

const useStrategicImperativeFormSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  return {
    title: string()
      .required(requiredLabel),
    description: string(),
    therapeuticArea: mixed<BiogenTherapeuticArea>()
      .oneOf(Object.values(BiogenTherapeuticArea), requiredLabel)
      .required(),
  };
};

export default useStrategicImperativeFormSchema;
