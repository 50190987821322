import { FC } from 'react';
import CardAsset from './CardAsset';
import CardColumn from './CardColumn';
import CardInvitee from './CardInvitee';
import CardRow from './CardRow';
import { ListShape } from 'components/QuickForm/QuickForm.enums';
import { CardMapperProps } from 'components/QuickForm/QuickForm.types';

const CardsMapper: FC<CardMapperProps> = ({
  card,
  handleRemoveItem,
  handleEditItem,
  disabled,
}) => {
  switch (card.listShape) {
    case ListShape.listColumn:
      return (
        <CardColumn
          item={ card.item }
          handleRemoveItem={ handleRemoveItem }
          disabled={ disabled }
        />
      );
    case ListShape.listRow:
      return (
        <CardRow
          item={ card.item }
          handleRemoveItem={ handleRemoveItem }
          handleEditItem={ handleEditItem }
          disabled={ disabled }
        />
      );
    case ListShape.listAsset:
      return (
        <CardAsset
          item={ card.item }
          handleRemoveItem={ handleRemoveItem }
          disabled={ disabled }
        />
      );
    case ListShape.listInvitee:
      return (
        <CardInvitee
          item={ card.item }
          handleRemoveItem={ handleRemoveItem }
          disabled={ disabled }
        />
      );
    default:
      return null;
  }
};

export default CardsMapper;
