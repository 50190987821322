import { StrategicImperative } from 'generated/maya.types';

export const isStrategicImperative = (value: unknown): value is StrategicImperative => {
  const strategicImperativeRequiredKey = ['_id', 'title', 'description', 'therapeuticArea', 'updated'];

  if (value === null || typeof value !== 'object') {
    return false;
  }

  const objectKeys = Object.keys(value);
  const mixedSet = new Set([...objectKeys, ...strategicImperativeRequiredKey]);

  return mixedSet.size === objectKeys.length;
};
