import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { customerInitiativeFragment } from 'basics/graphql/fragments/customerInitiative.fragments';
import { CustomerInitiative } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_CUSTOMER_INITIATIVE_BY_ID = gql`
query GetCustomerInitiativeByIdQuery($id: String!) {
  getCustomerInitiativeById(id: $id) {
    ${customerInitiativeFragment}
  }
}
`;

type ResultType = {
  getCustomerInitiativeById: CustomerInitiative;
};

type UseGetCustomerInitiativeById = (customerInitiativeId: string | null) => {
  loading: boolean;
  error?: ApolloError;
  data?: CustomerInitiative;
};

const useGetCustomerInitiativeByIdQuery: UseGetCustomerInitiativeById = (customerInitiativeId) => {
  const { data, loading, error } = useQuery<ResultType>(GET_CUSTOMER_INITIATIVE_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip: customerInitiativeId === null,
    variables: {
      id: customerInitiativeId,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getCustomerInitiativeById.ts - useGetCustomerInitiativeByIdQuery',
    });
  }

  return {
    data: data?.getCustomerInitiativeById,
    loading,
    error,
  };
};

export default useGetCustomerInitiativeByIdQuery;
