import { FC } from 'react';
import GridList from 'components/GridList/GridList';
import useTargetListView from 'routes/AuthenticatedApp/CepView/Steps/TargetListView/TargetListView.hook';

const TargetListView: FC = () => {
  const {
    data,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    targetListConfig,
  } = useTargetListView();

  return (
    <GridList
      getRowId={ (row) => row._id }
      loading={ loading }
      columnConfig={ targetListConfig }
      rows={ data }
      onPageChange={ onPageChange }
      onPageSizeChange={ onPageSizeChange }
      rowCountState={ rowCountState }
      disableColumnMenu={ true }
      hideFooterPagination={ rowCountState === 0 }
    />
  );
};

export default TargetListView;
