import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { screenStyle, paperStyle } from './MiViewScreen.style';

const ScreenStyled = styled(Paper)(() => ({ ...paperStyle }));

const MiViewScreen: FC = ({ children }) => {
  return (
    <ScreenStyled style={ screenStyle }>
      { children }
    </ScreenStyled>
  );
};

export default MiViewScreen;
