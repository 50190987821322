export enum BiogenFunction {
  Commercial = 'Commercial',
  Medical = 'Medical',
  MarketAccess = 'Market Access',
}

export enum Specialities {
  childNeurology = 'Child Neurology',
  dermatology = 'Dermatology',
  gastroenterology = 'Gastroenterology',
  generalPractice = 'General Practice',
  generalSurgery = 'General Surgery',
  geriatricsGerontology = 'Geriatrics/Gerontology',
  hospitalPharmacy = 'Hospital Pharmacy',
  internalMedicine = 'Internal Medicine',
  neurology = 'Neurology',
  ophthalmology = 'Ophthalmology',
  other = 'Other',
  pediatrics = 'Pediatrics',
  physicalMedicineRehabilitation = 'Physical Medicine & Rehabilitation',
  physicalTherapy = 'Physical Therapy',
  psychiatry = 'Psychiatry',
  radiology = 'Radiology',
  rheumatology = 'Rheumatology',
}

export enum CustomerTypes {
  advocacyGroups = 'Advocacy groups',
  caregiver = 'Caregiver',
  insurances = 'Insurances',
  internalAudience = 'Internal Audience',
  KMEs = 'KMEs',
  nurses = 'Nurses',
  other = 'Other',
  patient = 'Patient',
  patientsCaregivers = 'Patients/Caregivers',
  payers = 'Payers',
  pharmacists = 'Pharmacists',
  physiciansPrimaryCare = 'Physicians - primary care',
  physiciansSpecialists = 'Physicians - specialists',
  physiotherapists = 'Physiotherapists',
  policyMakers = 'Policy Makers',
  public = 'Public',
}

export enum Channel {
  approvedEmail = 'Approved eMail',
  broadcastEmail = 'Broadcast eMail',
  f2fEvent = 'F2F Event',
  f2fMeeting = 'Face to Face',
  hybridEvent = 'Hybrid Event',
  oneToOne = '1:1',
  telephone = 'Telephone',
  virtualEvent = 'Virtual Event',
  virtualMeeting = 'Virtual',
  webLogIn = 'Web LogIn',
  webNoLogIn = 'Web No LogIn',
}

export enum ChannelsDetail {
  privateEmail = 'Detail privateEmail',
  approveEmail = 'Detail approveEmail',
  broadcastEmail = 'Detail broadcastEmail',
  faceToFace = 'Detail faceToFace',
  journalAds = 'Detail journalAds',
  other = 'Detail Other',
  postalMail = 'Detail postalMail',
  serviceCall = 'Detail serviceCall',
  socialMedia = 'Detail socialMedia',
  telephone = 'Detail Telephone',
  thirdPartyBanner = 'Detail thirdPartyBanner',
  thirdPartyElearning = 'Detail thirdPartyElearning',
  thirdPartyEmail = 'Detail thirdPartyEmail',
  thirdPartyWeb = 'Detail thirdPartyWeb',
  virtualEvents = 'Detail virtualEvents',
  web = 'Detail Web',
}

export enum CepSteps {
  configurationStep = 'configurationStep',
  goalStep = 'goalStep',
  tacticsStep = 'tacticsStep',
  targetListStep = 'targetListStep',
  miViewPreviewStep = 'miViewPreviewStep',
}

export enum ReachMetric {
  openRate = 'Open rate',
  ctaClickRate = 'CTA click rate',
  registrationRate = 'Registration rate',
  uniqueVisits = 'Unique visits',
}

export enum EngagementMetric {
  openRate = 'Open rate',
  ctaClickRate = 'CTA click rate',
  attendanceRate = 'Attendance rate',
  actionCompleted = 'Action completed',
}

export enum TacticMode {
  form = 'form',
  list = 'list',
}

export enum TargetListMode {
  select = 'select',
  upload = 'upload',
  dynamic = 'dynamic',
}

export enum CepStatus{
  ONGOING = 'Ongoing',
  FEEDBACK = 'Feedback',
  APPROVED = 'Approved',
  DRAFT = 'Draft',
  DELETED = 'Deleted',
}

export enum CiStatus{
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  DELETED = 'Deleted',
}

export enum TacticStatus{
  APPROVED = 'Approved',
  DELETED = 'Deleted',
  DRAFT = '', // DRAFT IS EMPTY STRING VALUE BECAUSE VEEVA HAS A EMPTY VALUE FOR DRAFT TACTIC - BIOGENLINC-3701
}

export enum VeevaSyncStatus{
  PENDING = 'Pending',
  SYNC = 'Sync',
  ERROR = 'Error',
}

export enum TacticTabsType {
  EMAIL = 'Email',
  EVENT = 'Event',
  INTERACTION = 'Interaction',
  WEB = 'Web',
}

export enum KeyMessageType {
  keyMessage = 'Key Message',
  medicalStrategy = 'Medical Strategy',
  discussionTopic = 'Discussion Topic',
}

export enum TacticDisplayType {
  kanban = 'kanban',
  list = 'list',
}

export enum BooleanOptionsValue {
  true = 'Yes',
  false = 'No',
}
