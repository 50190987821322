import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { mayaCepListRoute } from 'basics/constants/routes.constants';
import { useUpdateCepMutation } from 'basics/graphql/mutations/updateCep';
import useGetCepByIdQuery from 'basics/graphql/queries/getCepById';
import { CepStateType } from 'basics/types/maya.types';
import { warningToast } from 'basics/utils/toast';
import { CepFormSubmitCallbackType } from 'components/CepForm/CepForm.types';
import cepState from 'states/cep.states';

const useCepEdit = () => {
  const [updateCustomerEngagementPlanning] = useUpdateCepMutation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const cepId = urlParams.get('id') as string;

  const { data: customerEngagementPlanning, loading } = useGetCepByIdQuery(cepId || null);
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  useEffect(() => {
    if (!loading) {
      if (customerEngagementPlanning) {
        cepState.cep = customerEngagementPlanning;
      } else {
        warningToast(t('common.wording.error404'));
        navigate(mayaCepListRoute);
      }
    }
  }, [customerEngagementPlanning, loading, navigate, t]);

  useEffect(() => {
    return function cleanup() {
      cepState.cep = {};
      cepState.tactics = [];
      cepState.targetList = [];
    };
  }, []);

  const submitCallback: CepFormSubmitCallbackType = async (cep) => {
    const customerEngagementPlanningData = await updateCustomerEngagementPlanning(
      cepId,
      cep,
      true,
    );

    return customerEngagementPlanningData;
  };

  return {
    cepStateValue,
    submitCallback,
  };
};

export default useCepEdit;
