import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';
import { BiogenFunction } from 'basics/enums/maya.enums';
import useGetDropMaterials from 'basics/graphql/queries/getDropMaterials';
import useDropMaterialsOptions from 'basics/options/dropMaterials.options';
import { CepStateType, DropMaterialsStateType } from 'basics/types/maya.types';
import { CustomerInitiative, DropMaterialsFilter, Products } from 'generated/maya.types';
import cepState from 'states/cep.states';
import dropMaterials from 'states/dropMaterials.states';

const useTacticDropMaterials = (executingTeam: string) => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const dropMaterialsStateValue = useSnapshot(dropMaterials) as DropMaterialsStateType;

  const customerInitiative = cepStateValue.cep?.customerInitiative as CustomerInitiative;
  const therapeuticArea = cepStateValue.cep?.therapeuticArea;
  const [dropMaterialsValue, setDropMaterialsValue] = useState<Products[]>(dropMaterialsStateValue.dropMaterials);
  const dropMaterialsOptions = useDropMaterialsOptions(dropMaterialsValue);
  const dropMaterialsFilter = useMemo<DropMaterialsFilter>(() => ({
    Country: customerInitiative.country,
    TherapeuticArea: therapeuticArea,
  }), [customerInitiative, therapeuticArea]);

  const skip = !dropMaterialsFilter.Country || !dropMaterialsFilter.TherapeuticArea || !isEmpty(dropMaterialsStateValue.dropMaterials);
  const {
    data: dropMaterialsData,
    loading: dropMaterialsLoading,
  } = useGetDropMaterials(dropMaterialsFilter, skip);

  useEffect(() => {
    const filteredDropMaterials = dropMaterialsStateValue.dropMaterials.filter((dropMaterial) => {
      switch (executingTeam) {
        case BiogenFunction.Commercial:
          return dropMaterial.commercialFunctionFlag;
        case BiogenFunction.Medical:
          return dropMaterial.medicalFunctionFlag;
        case BiogenFunction.MarketAccess:
          return dropMaterial.marketAccessFunctionFlag;
        default:
          return false;
      }
    });

    setDropMaterialsValue(filteredDropMaterials);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executingTeam, dropMaterialsStateValue.dropMaterials]);

  useEffect(() => {
    if (!dropMaterialsLoading && dropMaterialsData.length > 0) {
      dropMaterials.dropMaterials = dropMaterialsData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropMaterialsLoading, dropMaterialsData]);

  return {
    dropMaterialsOptions,
    dropMaterialsLoading,
  };
};

export default useTacticDropMaterials;
