import { YodaColors } from 'yoda-ui/yodaTheme';

export const sideContainerStyle = {
  root: {
    width: '272px',
  },
  firstBox: {
    backgroundColor: YodaColors.white60,
  },
};
