import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { targetListFragment } from 'basics/graphql/fragments/targetList.fragments';
import { MutationReturnType } from 'basics/types/maya.types';
import { Target, TargetFilterInput } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type CreateVeevaTargetsResponse = {
  createVeevaTargets: Target[];
};

export type UseCreateVeevaTargetsMutation = [
  (
    cepId: string,
    targetsFilter: TargetFilterInput
  ) => Promise<MutationReturnType<Target[]>>,
  MutationResult<CreateVeevaTargetsResponse>,
];

export const createVeevaTargetsMutationCall = gql`
mutation createVeevaTargets(
  $cepId: String!, 
  $filters: TargetFilterInput!, 
  ) {
    createVeevaTargets(
    cepId: $cepId, 
    filters: $filters) {
    ${targetListFragment}
  }
}`;

export const useCreateVeevaTargetsMutation = (): UseCreateVeevaTargetsMutation => {
  const [createVeevaTargetsMutation, state] = useMutation<CreateVeevaTargetsResponse>(createVeevaTargetsMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (cepId, filters) => {
    try {
      const { data } = await createVeevaTargetsMutation({
        variables: {
          cepId,
          filters,
        },
      });

      return { data: data?.createVeevaTargets || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createTargetListFromCSV.ts - useCreateTargetListFromCSVMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [createVeevaTargetsMutation]);

  return [executeMutation, state];
};
