import { BiogenTherapeuticArea } from 'basics/enums/biogen.enums';
import { Channel } from 'basics/enums/maya.enums';

export const RequestFormUrlMapping = {
  [Channel.oneToOne]: 'https://reqform.mybiogen.net/#/form/iva/new-asset?',
  [Channel.approvedEmail]: 'https://reqform.mybiogen.net/#/form/approved-email/new-asset?',
  [Channel.broadcastEmail]: 'https://reqform.mybiogen.net/#/form/broadcast-email/new-asset?',
  [Channel.f2fEvent]: 'https://reqform.mybiogen.net/#/form/event/standard-event?',
  [Channel.f2fMeeting]: 'https://reqform.mybiogen.net/#/form/iva/new-asset?',
  [Channel.hybridEvent]: 'https://reqform.mybiogen.net/#/form/event/standard-event?',
  [Channel.telephone]: '',
  [Channel.virtualEvent]: '',
  [Channel.virtualMeeting]: '',
  [Channel.webLogIn]: '',
  [Channel.webNoLogIn]: '',
};

export const TherapeuticAreaRequestFormMapping = {
  [BiogenTherapeuticArea.FA]: 'FA',
  [BiogenTherapeuticArea.MS]: 'MS',
  [BiogenTherapeuticArea.ALZHEIMER]: 'ALS',
  [BiogenTherapeuticArea.SMA]: 'SMA',
  [BiogenTherapeuticArea.BIOSIMS]: 'BBU',
  [BiogenTherapeuticArea.NONE]: 'None',
};
