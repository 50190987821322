import { Dispatch, SetStateAction } from 'react';
import { TacticDisplayType } from 'basics/enums/maya.enums';

const useSelectTacticDesignConfig = (
  displayType: string,
  setdisplayType: Dispatch<SetStateAction<TacticDisplayType>>,
) => {
  const cardSwitchConfig = {
    name: 'selectTacticDesign',
    defaultValue: displayType,
    value: displayType,
    onclick: (event: React.MouseEvent<HTMLElement>, selectedDisplay: TacticDisplayType) => {
      setdisplayType(selectedDisplay);
    },
  };

  return { cardSwitchConfig, displayType };
};

export default useSelectTacticDesignConfig;
