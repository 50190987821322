import { useTranslation } from 'react-i18next';
import { CepStatus } from 'basics/enums/maya.enums';
import { SelectOption } from 'basics/options/options.types';

const useCepStatusOptions = (): SelectOption[] => {
  const { t } = useTranslation();
  return [
    { id: CepStatus.APPROVED, label: t('maya_status_approved') },
    { id: CepStatus.FEEDBACK, label: t('maya_status_feedback') },
    { id: CepStatus.DRAFT, label: t('maya_status_draft') },
  ];
};

export default useCepStatusOptions;
