export enum InitiativeCountry {
  AE = 'United Arab Emirates',
  AR = 'Argentina',
  AT = 'Austria',
  AU = 'Australia',
  BE = 'Belgium',
  BH = 'Bahrain',
  BR = 'Brazil',
  CA = 'Canada',
  CH = 'Switzerland',
  CL = 'Chile',
  CN = 'China',
  CO = 'Colombia',
  CZ = 'Czech Republic',
  DE = 'Germany',
  DK = 'Denmark',
  EE = 'Estonia',
  ES = 'Spain',
  FI = 'Finland',
  FR = 'France',
  GB = 'Great Britain',
  HK = 'Hong Kong',
  HR = 'Croatia',
  HU = 'Hungary',
  IE = 'Ireland',
  IT = 'Italy',
  JP = 'Japan',
  LT = 'Lithuania',
  LV = 'Latvia',
  MX = 'Mexico',
  NL = 'Netherlands',
  NO = 'Norway',
  NZ = 'New Zealand',
  OM = 'Oman',
  PL = 'Poland',
  PT = 'Portugal',
  QA = 'Qatar',
  SA = 'Saudi Arabia',
  SE = 'Sweden',
  SI = 'Slovenia',
  SK = 'Slovakia',
  TR = 'Turkey',
  TW = 'Taiwan',
  UY = 'Uruguay',
}

export enum TemplateRegion {
  AM = 'Above Market',
  APAC = 'APAC',
  ECP = 'ECP',
  LATAM = 'LATAM',
}
