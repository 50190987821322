import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from 'components/Heading';
import MayaLayout from 'components/MayaLayout';
import StrategicImperativeForm from 'components/StrategicImperativeForm/StrategicImperativeForm';
import useStrategicImperativeCreate from 'routes/AuthenticatedApp/StrategicImperativeCreate/StrategicImperativeCreate.hook';

const StrategicImperativeCreate: FC = () => {
  const { t } = useTranslation();
  const {
    handleCancelStrategicImperativeForm,
    handleSaveStrategicImperativeForm,
  } = useStrategicImperativeCreate();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_strategic_imperative_form_create_title') }
      </Heading>
      <StrategicImperativeForm
        {
          ...{
            submitCallback: handleSaveStrategicImperativeForm,
            cancelCallback: handleCancelStrategicImperativeForm,
          }
        }
      />
    </MayaLayout>
  );
};

export default StrategicImperativeCreate;
