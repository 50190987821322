import { Component, ReactNode } from 'react';
import Logger from 'services/logger';

type ErrorBoundaryProps = {
  children: ReactNode;
};
class ErrorBoundary extends Component<ErrorBoundaryProps, Record<string, unknown>> {
  componentDidCatch(error: Error) {
    Logger.logError({
      error,
      transactionName: 'ErrorBoundary',
    });
  }

  render() {
    const { children } = this.props;
    return <>{ children }</>;
  }
}

export default ErrorBoundary;
