import { isStrategicImperative } from 'basics/types/strategicImperative.types';
import { formatUTCDate } from 'basics/utils/date.utils';
import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { CustomerInitiative, StrategicImperative } from 'generated/maya.types';
import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

export const ciToBE = (data: YodaFieldValue) => {
  return {
    ...transformInputDateUTC(data.date),
    country: data.country,
    description: data.description,
    functionalOwner: data.functionalOwner,
    functionsInvolved: data.functionsInvolved,
    isTemplate: data.isTemplate,
    problemStatement: data.problemStatement,
    product: data.product,
    solutionCoreStory: data.solutionCoreStory,
    strategicImperative: data.strategicImperative,
    therapeuticArea: data.therapeuticArea,
    title: data.title,
    year: new Date(data.date[1]).getFullYear(),
  };
};

export const ciToForm = (
  ci: Partial<CustomerInitiative> | undefined | null,
  strategicImperative?: StrategicImperative | undefined,
) => {
  const strategicImperativeDefaultValue = isStrategicImperative(ci?.strategicImperative) && ci?.strategicImperative._id
    ? ci?.strategicImperative._id
    : (strategicImperative?._id || '');

  const therapeuticAreaDefaultValue = ci?.therapeuticArea || strategicImperative?.therapeuticArea || '';
  const dateDefaultValue = formatUTCDate(ci?.dateStart, ci?.dateEnd);

  return {
    country: ci?.country || '',
    date: dateDefaultValue,
    description: ci?.description || '',
    functionalOwner: ci?.functionalOwner || '',
    functionsInvolved: ci?.functionsInvolved || [],
    problemStatement: ci?.problemStatement || '',
    product: ci?.product || '',
    solutionCoreStory: ci?.solutionCoreStory || '',
    strategicImperative: strategicImperativeDefaultValue,
    therapeuticArea: therapeuticAreaDefaultValue,
    title: ci?.title || '',
    year: ci?.year ? ci.year.toString() : '',
  };
};
