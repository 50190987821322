import { MiViewColors, MiViewSpacing } from 'miview-ui/miviewTheme';

export const tooltipStyle = {
  '& .MuiTooltip-tooltip': {
    padding: MiViewSpacing.xSmall,
    position: 'relative',
    top: '10px',
    backgroundColor: MiViewColors.primary,
  },
};
