import { cloneDeep, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCepTrashedListConfig from './CepTrashedList.config';
import { PAGINATION_PAGE_SIZE } from 'basics/constants/common.constant';
import { CepStatus } from 'basics/enums/maya.enums';
import { useSetCepStatusToRestoredMutation } from 'basics/graphql/mutations/setCepStatusToRestored';
import useGetAllCepQuery from 'basics/graphql/queries/getAllCeps';
import { succesToast, warningToast } from 'basics/utils/toast';
import { useCurrentUserState } from 'contexts/user';
import { CustomerEngagementPlanning } from 'generated/maya.types';

const useCepTrashedList = () => {
  const { user } = useCurrentUserState();

  const [cepSearchFilter, setCepSearchFilter] = useState<(string)>();
  const [customerInitiativeFilter] = useState<(string | number)[]>([]);
  const [statusFilter] = useState<(string | number)[]>([]);
  const [ceps, setCeps] = useState<CustomerEngagementPlanning[]>([]);

  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);

  const [setCepStatusToRestored] = useSetCepStatusToRestoredMutation();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const queryFilters = useMemo(
    () => ({
      search: cepSearchFilter,
      isTemplate: false,
      status: [CepStatus.DELETED],
      countries: user?.country ? [user?.country] : undefined,
    }),
    [cepSearchFilter, user?.country],
  );

  const { data: cepData, loading: cepLoading, count } = useGetAllCepQuery(queryFilters, queryOptions);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!cepLoading) {
      setCeps(cepData);
    }
  }, [cepData, cepLoading]);

  const deleteCepFromList = (cepId: string) => {
    const clonedCeps = cloneDeep(ceps);
    const targetCepIndex = ceps.findIndex((cep) => { return cep._id === cepId; });
    if (targetCepIndex >= 0) {
      clonedCeps.splice(targetCepIndex, 1);
      setCeps(clonedCeps);
    }
  };

  const restoreCepCallback = async (cepId: string) => {
    const { data: cepRestored, error: cepRestoredError } = await setCepStatusToRestored(cepId, true);
    if (cepRestored && !cepRestoredError) {
      succesToast(t('maya_action_restore_cep_success'));
      deleteCepFromList(cepId);
    } else {
      warningToast(cepRestoredError || t('maya_action_restore_cep_error'));
    }
  };

  const {
    cepListColumnConfig,
    cepTitleFieldConfig,
  } = useCepTrashedListConfig(
    restoreCepCallback,
  );

  const handleChangeCepSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCepSearchFilter(event.target.value);
  };
  const debouncedHandleChangeCepSearch = useMemo(
    () => debounce(handleChangeCepSearch, 750),
    [],
  );

  const getFilteredRows = useCallback(() => {
    let filteredRows = ceps;

    if (customerInitiativeFilter.length > 0) {
      filteredRows = cepData.filter((value) => {
        if (typeof value.customerInitiative === 'string') {
          return value.customerInitiative;
        }

        return customerInitiativeFilter.indexOf(value.customerInitiative._id) !== -1;
      });
    }

    if (statusFilter.length > 0) {
      filteredRows = filteredRows.filter((value) => {
        return statusFilter.indexOf(value.status) !== -1;
      });
    }

    return filteredRows;
  }, [ceps, customerInitiativeFilter, statusFilter, cepData]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const menuCustomerEngagementPlanningLabel = t('maya_main_menu_cep_trashed_label');

  return {
    cepData,
    cepListColumnConfig,
    cepLoading,
    cepTitleFieldConfig,
    customerInitiativeFilter,
    debouncedHandleChangeCepSearch,
    getFilteredRows,
    menuCustomerEngagementPlanningLabel,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    statusFilter,
  };
};

export default useCepTrashedList;
