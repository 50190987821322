import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';
import { MiViewColors, MiViewFontSize, MiViewSpacing } from 'miview-ui/miviewTheme';

export const listItemStyle: SxProps<Theme> = {
  height: '95%',
  overflow: 'hidden',
  opacity: 1,
};

export const listItemTextStyle: SxProps<Theme> = {
  marginLeft: MiViewSpacing.xxSmall,
  height: '100%',
  '& .MuiListItemText-primary': {
    fontSize: MiViewFontSize.small,
  },
  '& .MuiListItemText-secondary': {
    fontSize: MiViewFontSize.xSmall,
  },
  overflow: 'auto',
};

export const chipStyle: SxProps<Theme> = {
  marginBottom: MiViewSpacing.xxxSmall,
  border: '0px solid orange',
  opacity: 1,
  fontSize: MiViewFontSize.xxSmall,
  '& .MuiChip-label': {
    padding: '6px',
  },
};

export const mainWrapperStyle: CSSProperties = {
  overflowX: 'hidden',
};

export const divCol1Style: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const divCol1ChildStyle: CSSProperties = {
  width: '12%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: MiViewSpacing.medium,
  paddingRight: MiViewSpacing.medium,
};

export const divCol2Style: CSSProperties = {
  width: '35%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  wordBreak: 'break-word',
  overflow: 'auto',
};

export const divCol3Style: CSSProperties = {
  width: '62%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
};

export const divCol3ChildStyle: CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  overflow: 'auto',
};

export const chipListStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

export const secondChipStyle: SxProps<Theme> = {
  color: MiViewColors.primary,
  borderColor: MiViewColors.primary,
  alignSelf: 'center',
  marginLeft: MiViewSpacing.xxxSmall,
  '& .MuiChip-label': {
    padding: MiViewSpacing.xxxSmall,
  },
};

export const divSpacer: CSSProperties = {
  margin: MiViewSpacing.small,
};

export const iconButtonStyle: SxProps<Theme> = {
  alignSelf: 'center',
  padding: 0,
};

export const firstBadgeStyle = {
  color: MiViewColors.tertiary,
  background: MiViewColors.primary,
  cursor: 'pointer',
};

export const secondBadgeStyle = {
  color: MiViewColors.tertiary,
  background: MiViewColors.vividOrange,
  cursor: 'pointer',
};
