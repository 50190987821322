import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CepForm from 'components/CepForm/CepForm';
import Heading from 'components/Heading';
import MayaLayout from 'components/MayaLayout';
import useCepTemplateCreate from 'routes/AuthenticatedApp/CepTemplateCreate/CepTemplateCreate.hook';

const CepTemplateCreate: FC = () => {
  const { t } = useTranslation();
  const {
    submitCallback,
  } = useCepTemplateCreate();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_cep_tpl_form_title') }
      </Heading>

      <CepForm submitCallback={ submitCallback } isTemplate={ true } />
    </MayaLayout>
  );
};

export default CepTemplateCreate;
