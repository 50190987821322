import { SelectChangeEvent } from '@mui/material';
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { ChangeEvent } from 'react';

const useListPagination = (
  pageSize: number,
  setPageSize: (pageSize: number) => void,
) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowsCount = apiRef.current.getRowsCount();

  let displayedCount = pageSize;
  if (pageSize * (page + 1) > rowsCount) {
    displayedCount = rowsCount % pageSize;
  }

  const onPageChange = (event: ChangeEvent<unknown>, value: number) => apiRef.current.setPage(value - 1);

  const onPageSizeChange = (event: SelectChangeEvent<unknown>) => {
    apiRef.current.setPageSize(event.target.value as number);
    setPageSize(event.target.value as number);
  };

  return { apiRef, page, pageCount, displayedCount, rowsCount, onPageChange, onPageSizeChange };
};

export default useListPagination;
