import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MiViewColors, MiViewFontSize, MiViewSpacing } from 'miview-ui/miviewTheme';

export const boxStyle: SxProps<Theme> = {
  display: 'flex',
};

export const appBarStyle: SxProps<Theme> = {
  background: MiViewColors.primary,
  paddingLeft: MiViewSpacing.small,
  paddingRight: MiViewSpacing.small,
  zIndex: 100,
};

export const miViewLogoIconButtonStyle: SxProps<Theme> = {
  marginRight: MiViewSpacing.xxxSmall,
};

export const menuIconStyle: SxProps<Theme> = {
  color: MiViewColors.tertiary,
  fontSize: MiViewFontSize.xLarge,
};

export const boxFirstSeparatorStyle: SxProps<Theme> = {
  flexGrow: 1,
};

export const boxSecondSeparatorStyle: SxProps<Theme> = {
  display: { xs: 'none', md: 'flex' },
};

export const iconStyle: SxProps<Theme> = {
  fontSize: MiViewFontSize.xLarge,
  color: MiViewColors.tertiary,
};

export const darkModeIconStyle: SxProps<Theme> = {
  fontSize: MiViewFontSize.xLarge,
};

export const miViewLogoStyle = {
  width: 30,
  height: 30,
};
