/* eslint-disable no-param-reassign */
import { useCallback, useEffect } from 'react';
import { useSnapshot } from 'valtio';

export type UseStepIndexer = {
  stepIndex: number;
  moveNextStep: () => void;
  movePreviousStep: () => void;
  moveToStep: (step: number) => void;
  previousStep: number | undefined;
};

export type StepIndexerState = {
  stepIndex: number;
  previousStep: number;
};

const useStepIndexer = (stepIndexerState: StepIndexerState): UseStepIndexer => {
  useEffect(() => {
    return () => {
      stepIndexerState.stepIndex = 0;
    };
  }, [stepIndexerState]);
  const { stepIndex, previousStep } = useSnapshot<StepIndexerState>(stepIndexerState);
  const moveNextStep = useCallback(() => {
    stepIndexerState.previousStep = stepIndexerState.stepIndex;
    stepIndexerState.stepIndex += 1;
  }, [stepIndexerState]);
  const movePreviousStep = useCallback(() => {
    stepIndexerState.previousStep = stepIndexerState.stepIndex;
    stepIndexerState.stepIndex -= 1;
  }, [stepIndexerState]);
  const moveToStep = useCallback((step: number) => {
    if (step !== stepIndexerState.stepIndex) {
      stepIndexerState.previousStep = stepIndexerState.stepIndex;
      stepIndexerState.stepIndex = step;
    }
  }, [stepIndexerState]);

  return {
    stepIndex,
    moveNextStep,
    movePreviousStep,
    moveToStep,
    previousStep,
  };
};

export default useStepIndexer;
