import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useMayaMenuEntry = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current
    && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return {
    anchorRef,
    handleClose,
    handleListKeyDown,
    handleToggle,
    navigate,
    open,
    setOpen,
  };
};

export default useMayaMenuEntry;
