import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { useCreateCepMutation } from 'basics/graphql/mutations/createCep';
import { useUpdateCepMutation } from 'basics/graphql/mutations/updateCep';
import { CepStateType } from 'basics/types/maya.types';
import { CepFormSubmitCallbackType } from 'components/CepForm/CepForm.types';
import { useGTM } from 'contexts/gtm';
import cepState from 'states/cep.states';

const useCepCreate = () => {
  const dataLayer = useGTM();
  const [createCustomerEngagementPlanning] = useCreateCepMutation();
  const [updateCustomerEngagementPlanning] = useUpdateCepMutation();

  const customerEngagementPlanningStateValue = useSnapshot(cepState) as CepStateType;

  useEffect(() => {
    return function cleanup() {
      cepState.cep = {};
      cepState.tactics = [];
      cepState.targetList = [];
    };
  }, []);

  const submitCallback: CepFormSubmitCallbackType = async (cep) => {
    if (customerEngagementPlanningStateValue.cep?._id) {
      const customerEngagementPlanningData = await updateCustomerEngagementPlanning(
        customerEngagementPlanningStateValue.cep._id,
        cep,
        true,
      );

      return customerEngagementPlanningData;
    }

    const customerEngagementPlanningData = await createCustomerEngagementPlanning(
      cep,
      true,
    );

    dataLayer({
      event: 'customer_engagement_planning',
      step: 'configuration',
    });

    return customerEngagementPlanningData;
  };

  return {
    submitCallback,
  };
};

export default useCepCreate;
