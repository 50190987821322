import Box from '@mui/material/Box';
import { FC } from 'react';
import { headerStyle } from './MiViewScreenHeader.style';

type ScreenHeaderProps = {
  children?: React.ReactNode;
};

const MiViewScreenHeader: FC<ScreenHeaderProps> = ({ children }) => {
  return (
    <Box sx={ headerStyle }>
      { children }
    </Box>
  );
};

export default MiViewScreenHeader;
