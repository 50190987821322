import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

export const mayaMenuItemsStyles = {
  display: 'flex',
  justifyContent: 'center',
  gap: '50px',
  paddingLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: YodaSpacing.xxxLarge,
  marginRight: YodaSpacing.xxxLarge,
  listStyleType: 'none',
};

export const mayaMenuItemStyles: SxProps<Theme> = {
  color: YodaColors.gray8,
};
