import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerInitiativeEdit from './CustomerInitiativeEdit.hook';
import { mayaCustomerInitiativeEditRoute } from 'basics/constants/routes.constants';
import { VeevaSyncStatus } from 'basics/enums/maya.enums';
import CustomerInitiativeForm from 'components/CustomerInitiativeForm/CustomerInitiativeForm';
import Heading from 'components/Heading';
import MayaLayout from 'components/MayaLayout';
import VeevaStatusBar from 'components/VeevaStatusBar/VeevaStatusBar';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';

const CustomerInitiativeEdit: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    customerInitiative,
    currentURL,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  } = useCustomerInitiativeEdit();

  return (
    <MayaLayout>
      <Heading>
        { t('maya.customerInitiativeEditTitle') }
        {
          currentURL === mayaCustomerInitiativeEditRoute
            ? <VeevaStatusBar
              lastVeevaSync={ customerInitiative?.lastVeevaSync }
              lastVeevaSyncErrors={ customerInitiative?.lastVeevaSyncError ? [customerInitiative?.lastVeevaSyncError] : undefined }
              veevaSyncStatus={ customerInitiative?.veevaSyncStatus as VeevaSyncStatus }
            />
            : null
        }
      </Heading>

      {
        loading
          ? (
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Loader center />
            </Box>
          )
          : (
            <CustomerInitiativeForm {
              ...{
                customerInitiative,
                submitCallback: handleSaveCustomerInitiativeForm,
                cancelCallback: handleCancelCustomerInitiativeForm,
              }
            } />
          )
      }
    </MayaLayout>
  );
};

export default CustomerInitiativeEdit;
