import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BuSegmentMapping } from 'components/DynamicTargetListForm/DynamicTargetListForm.enums';
import useDynamicTargetList from 'components/DynamicTargetListForm/DynamicTargetListForm.hook';
import { loadingBoxStyle } from 'components/DynamicTargetListForm/DynamicTargetListForm.style';
import { DynamicTargetListFormPropsType } from 'components/DynamicTargetListForm/DynamicTargetListForm.types';
import FormContainer from 'components/FormContainer/FormContainer';
import { FormLayoutContainer } from 'components/FormLayout';
import Heading from 'components/Heading';
import Box from 'yoda-ui/components/Box';
import Collapse from 'yoda-ui/components/Collapse';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import CollapseIcon from 'yoda-ui/components/Icons/CollapseIcon';
import Loader from 'yoda-ui/components/Loader';
import { fromUnitsToRem, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const DynamicTargetListForm: FC<DynamicTargetListFormPropsType> = (
  {
    updateTargetList,
    loading,
    setLoading,
  },
) => {
  const {
    affinityHandleClick,
    businessUnit,
    customerAttributeHandleClick,
    dynamicTargetListConfig,
    filteredTargetsFacetsLoading,
    hcoAttributeHandleClick,
    openAffinity,
    openCustomerAttribute,
    openHcoAttribute,
    openScope,
    openSegment,
    providerFields,
    scopeHandleClick,
    segmentHandleClick,
    submitButtonConfig,
    targetsFacetsLoading,
  } = useDynamicTargetList(updateTargetList, loading, setLoading);

  const { t } = useTranslation();

  const width50Style = 'calc(50% - 20px)';

  return (
    <FormContainer providerFields={ providerFields } submitButtonConfig={ submitButtonConfig } >
      <Box onClick={ scopeHandleClick } sx={ { cursor: 'pointer' } }>
        <Heading level={ 3 } >
          { t('maya_dynamic_target_list_form_title_scope') }
          <CollapseIcon
            expand={ openScope }
            onClick={ scopeHandleClick }
            aria-expanded={ openScope }
            aria-label="show more"
            data-testid="collapse-icon"
          />
        </Heading>
      </Box>
      <Collapse
        in={ openScope }
        timeout="auto"
      >
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              targetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <SelectYoda
              { ...dynamicTargetListConfig.country }
              disabled={ targetsFacetsLoading || dynamicTargetListConfig.country.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            {
              targetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <SelectYoda
              { ...dynamicTargetListConfig.businessUnit }
              disabled={ targetsFacetsLoading || dynamicTargetListConfig.businessUnit.disabled }
            />
          </Box>
        </FormLayoutContainer>
      </Collapse>

      <Box onClick={ segmentHandleClick } sx={ { cursor: 'pointer' } }>
        <Heading level={ 3 }>
          { t('maya_dynamic_target_list_form_title_segment') }
          <CollapseIcon
            expand={ openSegment }
            onClick={ segmentHandleClick }
            aria-expanded={ openSegment }
            aria-label="show more"
            data-testid="collapse-icon"
          />
        </Heading>
      </Box>
      <Collapse
        in={ openSegment }
        timeout="auto"
      >
        <FormLayoutContainer
          justifyContent={ YodaJustifyContent.spaceBetween }
          flexWrap="wrap"
          gap="25px"
          marginBottom={ fromUnitsToRem(YodaSpacing.large) }
        >
          {
            BuSegmentMapping[businessUnit as keyof typeof BuSegmentMapping] === dynamicTargetListConfig.smaSegments.name
              ? <Box width={ width50Style }>
                {
                  filteredTargetsFacetsLoading
                    ? (
                      <Box sx={ loadingBoxStyle }>
                        <Loader center size={ 1 } />
                      </Box>
                    )
                    : null
                }
                <MultiSelectYoda
                  { ...dynamicTargetListConfig.smaSegments }
                  disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.smaSegments.disabled }
                />
              </Box>
              : null
          }
          {
            BuSegmentMapping[businessUnit as keyof typeof BuSegmentMapping] === dynamicTargetListConfig.biosSegments.name
              ? <Box width={ width50Style }>
                {
                  filteredTargetsFacetsLoading
                    ? (
                      <Box sx={ loadingBoxStyle }>
                        <Loader center size={ 1 } />
                      </Box>
                    )
                    : null
                }
                <MultiSelectYoda
                  { ...dynamicTargetListConfig.biosSegments }
                  disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.biosSegments.disabled }
                />
              </Box>
              : null
          }
          {
            BuSegmentMapping[businessUnit as keyof typeof BuSegmentMapping] === dynamicTargetListConfig.imsSegments.name
              ? <Box width={ width50Style }>
                {
                  filteredTargetsFacetsLoading
                    ? (
                      <Box sx={ loadingBoxStyle }>
                        <Loader center size={ 1 } />
                      </Box>
                    )
                    : null
                }
                <MultiSelectYoda
                  { ...dynamicTargetListConfig.imsSegments }
                  disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.imsSegments.disabled }
                />
              </Box>
              : null
          }
        </FormLayoutContainer>
      </Collapse>

      <Box onClick={ customerAttributeHandleClick } sx={ { cursor: 'pointer' } }>
        <Heading level={ 3 }>
          { t('maya_dynamic_target_list_form_title_customer_attribute') }
          <CollapseIcon
            expand={ openCustomerAttribute }
            onClick={ customerAttributeHandleClick }
            aria-expanded={ openCustomerAttribute }
            aria-label="show more"
            data-testid="collapse-icon"
          />
        </Heading>
      </Box>
      <Collapse
        in={ openCustomerAttribute }
        timeout="auto"
      >
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.type }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.type.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.specialty }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.specialty.disabled }
            />
          </Box>
        </FormLayoutContainer>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.consent }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.consent.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            <SelectYoda
              { ...dynamicTargetListConfig.cyclePlan }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.cyclePlan.disabled }
            />
          </Box>

        </FormLayoutContainer>
      </Collapse>

      <Box onClick={ affinityHandleClick } sx={ { cursor: 'pointer' } }>
        <Heading level={ 3 }>
          { t('maya_dynamic_target_list_form_title_channel_affinity') }
          <CollapseIcon
            expand={ openAffinity }
            onClick={ affinityHandleClick }
            aria-expanded={ openAffinity }
            aria-label="show more"
            data-testid="collapse-icon"
          />
        </Heading>
      </Box>
      <Collapse
        in={ openAffinity }
        timeout="auto"
      >
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.approvedEmailsAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.approvedEmailsAffinity.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.broadcastEmailsAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.broadcastEmailsAffinity.disabled }
            />
          </Box>
        </FormLayoutContainer>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.f2fAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.f2fAffinity.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.f2fEventAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.f2fEventAffinity.disabled }
            />
          </Box>
        </FormLayoutContainer>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.virtualEventAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.virtualEventAffinity.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.virtualAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.virtualAffinity.disabled }
            />
          </Box>
        </FormLayoutContainer>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.phoneAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.phoneAffinity.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.webSessionsAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.webSessionsAffinity.disabled }
            />
          </Box>
        </FormLayoutContainer>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.allDigitalAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.allDigitalAffinity.disabled }
            />
          </Box>
          <Box width={ width50Style }>
            {
              filteredTargetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.allF2FAffinity }
              disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.allF2FAffinity.disabled }
            />
          </Box>
        </FormLayoutContainer>
      </Collapse>
      <Box onClick={ hcoAttributeHandleClick } sx={ { cursor: 'pointer' } }>
        <Heading level={ 3 } >
          { t('maya_dynamic_target_list_form_title_hco_attribute') }
          <CollapseIcon
            expand={ openHcoAttribute }
            onClick={ hcoAttributeHandleClick }
            aria-expanded={ openHcoAttribute }
            aria-label="show more"
            data-testid="collapse-icon"
          />
        </Heading>
      </Box>
      <Collapse
        in={ openHcoAttribute }
        timeout="auto"
      >
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
          <Box width={ width50Style }>
            {
              targetsFacetsLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <MultiSelectYoda
              { ...dynamicTargetListConfig.hcoNames }
              disabled={ targetsFacetsLoading || dynamicTargetListConfig.hcoNames.disabled }
            />
          </Box>
        </FormLayoutContainer>
      </Collapse>
    </FormContainer>
  );
};

export default DynamicTargetListForm;
