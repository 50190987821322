import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations/dist/captureconsole';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getConfigVar } from 'config/config';

export const initializeSentry = () => {
  if (getConfigVar('REACT_APP_SENTRY_ENABLED') === 'true') {
    Sentry.init({
      dsn: getConfigVar('REACT_APP_SENTRY_DSN'),
      tracesSampleRate: 0,
      environment: getConfigVar('REACT_APP_AWS_ENV'),
      attachStacktrace: true,
      integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsoleIntegration({
          levels: ['warn', 'error'],
        }),
      ],
      beforeSend(event) {
        const mutatedEvent = { ...event };
        // Reduce noise in stacktrace
        const appStacktrace = event.stacktrace?.frames?.filter((frame) => !!frame.in_app);
        if (appStacktrace) {
          mutatedEvent.stacktrace = { frames: [...appStacktrace] };
        }

        // Override console errors/warnings fingerprints
        const stackTraceFrames = event.stacktrace?.frames?.filter((frame) => frame.function !== '?');
        if (event.logger === 'console' && stackTraceFrames?.length) {
          const lastFrameIndex = stackTraceFrames.length - 1;
          const lastFrameFunction = stackTraceFrames[lastFrameIndex].function || '';
          const groupMessage = event.message?.slice(0, 70);
          mutatedEvent.fingerprint = [`${groupMessage} - ${lastFrameFunction}`];
          if (event.message?.startsWith('Warning')) {
            mutatedEvent.level = Sentry.Severity.Warning;
          }
        }
        return mutatedEvent;
      },
      beforeBreadcrumb(breadcrumb, hint) {
        const mutatedBreadcrumb = { ...breadcrumb };
        // change breadcrumbs message for buttons and inputs
        if ((breadcrumb.category === 'ui.click' || breadcrumb.category === 'click') && hint?.event) {
          const { target } = hint.event;
          const { innerText, nodeName } = target;
          if (nodeName === 'INPUT') {
            mutatedBreadcrumb.message = `clicked input ${target.id}`;
          }
          if (innerText) {
            // can not use nodeName as MUI button are SPAN in DOM
            mutatedBreadcrumb.message = `clicked button ${innerText}`;
          }
        }
        // remove console.log
        if ((breadcrumb.category === 'console' && hint?.level === 'log')) {
          return null;
        }
        return mutatedBreadcrumb;
      },
    });
  }
};
