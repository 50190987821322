import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { boxStyle, gridContainerStyle, gridStyle } from './MiViewScreenLayout.style';
import MiViewScreenTactic from 'components/MiViewScreenTactic/MiViewScreenTactic';
import MiViewScreenTarget from 'components/MiViewScreenTarget/MiViewScreenTarget';

const MiViewScreenLayout: FC = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%" sx={ boxStyle }>
      <Grid container sx={ gridContainerStyle }>
        <Grid item={ true } md={ 12 } lg={ 6 } height={ '50%' } sx={ gridStyle }>
          <MiViewScreenTactic></MiViewScreenTactic>
        </Grid>

        <Grid item={ true } md={ 12 } lg={ 6 } height={ '50%' } sx={ gridStyle }>
          <MiViewScreenTarget></MiViewScreenTarget>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MiViewScreenLayout;
