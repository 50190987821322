import { createContext, ReactNode, useState } from 'react';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';

type DialogConfig = {
  title: string;
  message: string;
  onConfirmLabel: string;
  onDismissLabel: string;
  actionCallback?: (state: boolean) => void;
};

type ConfirmationDialogState = {
  openDialog: (config: DialogConfig) => void;
};

const ConfirmationDialogContext = createContext<ConfirmationDialogState | undefined>(undefined);

export const ConfirmationDialogProvider = ({ children }: { children: ReactNode }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig | null>(null);

  const openDialog = ({
    title,
    message,
    actionCallback,
    onConfirmLabel,
    onDismissLabel,
  }: DialogConfig) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback, onConfirmLabel, onDismissLabel });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(null);
  };

  const onButtonClick = (state: boolean) => {
    resetDialog();
    if (dialogConfig && dialogConfig.actionCallback) {
      dialogConfig.actionCallback(state);
    }
  };

  return (
    <ConfirmationDialogContext.Provider value={ { openDialog } }>
      {
        (dialogOpen && dialogConfig) ? (
          <ConfirmationDialog
            dialogTitle={ dialogConfig.title }
            dialogMessage={ dialogConfig.message }
            onConfirm={ () => onButtonClick(true) }
            onDismiss={ () => onButtonClick(false) }
            onConfirmLabel={ dialogConfig.onConfirmLabel }
            onDismissLabel={ dialogConfig.onDismissLabel }
          />
        ) : null
      }
      { children }
    </ConfirmationDialogContext.Provider>
  );
};
