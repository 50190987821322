import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { tacticFragment } from 'basics/graphql/fragments/tactic.fragments';
import { QueryOptionsType } from 'basics/types/common.types';
import { Tactic } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_NEXT_TACTIC = gql`
query getNextTactics($id: String!, $limit: Int!, $offset: Int!) {
  getNextTactics(id: $id, limit: $limit, offset: $offset) {
    tactics {
    ${tacticFragment}
    }
    count
  }
}
`;

type ResultType = {
  getNextTactics: {
    tactics: Tactic[];
    count: number;
  };
};

type UseGetNextTactics = (id: string | null, queryOptions?: QueryOptionsType) => {
  loading: boolean;
  error?: ApolloError;
  data: Tactic[];
  count: number;
};

const defaultTacticsValue: Tactic[] = [];

const useGetNextTacticsQuery: UseGetNextTactics = (id, queryOptions = { limit: 5000, offset: 0 }) => {
  const { limit, offset } = queryOptions;

  const { data, loading, error } = useQuery<ResultType>(GET_NEXT_TACTIC, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip: !id,
    variables: {
      id,
      limit,
      offset,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getNextTactics.ts - useGetNextTacticsQuery',
    });
  }

  return {
    data: data?.getNextTactics.tactics || defaultTacticsValue,
    count: data?.getNextTactics.count || 0,
    loading,
    error,
  };
};

export default useGetNextTacticsQuery;
