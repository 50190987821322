import { getConfigVar } from 'config/config';

export const CDN_DNS = getConfigVar('REACT_APP_CDN_DNS');
const cdnImagePath = `https://${CDN_DNS}/images`;

export const MODULES_LOGO_PATH = `${cdnImagePath}/backOffice/modules`;

export const SCHEDULE_IMAGE = `${cdnImagePath}/Schedule.png`;
export const BACKOFFICE_LOGO = `${cdnImagePath}/backOffice/biogenlinc_bo_logo.svg`;
export const BIOGEN_LOGO_FOOTER = `${cdnImagePath}/backOffice/bo_logo_dark.svg`;
export const BO_MAYA_CARD = `${cdnImagePath}/backOffice/bo_maya_card.png`;
export const BO_HOME_BACKGROUND = `${cdnImagePath}/backOffice/bo_home_background.png`;
export const EMAIL_CONFIG_CF_URL = `${cdnImagePath}/backOffice/emailConfiguration/cf_url.png`;
export const EMAIL_CONFIG_HEADER_NOTE = `${cdnImagePath}/backOffice/emailConfiguration/header_note.png`;
export const EMAIL_CONFIG_FOOTER_NOTE = `${cdnImagePath}/backOffice/emailConfiguration/footer_note.png`;
export const EMAIL_CONFIG_LOGOS = `${cdnImagePath}/backOffice/emailConfiguration/logos_options.png`;
export const EMAIL_CONFIG_BUTTONS_COLOR = `${cdnImagePath}/backOffice/emailConfiguration/buttons_color.png`;
export const EMAIL_CONFIG_MAYA_TACTICS = `${cdnImagePath}/backOffice/emailConfiguration/maya_tactics.png`;
