import { MoreVert } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useMiViewScreenTactic from './MiViewScreenTactic.hook';
import { enabledIconStyle, disabledIconStyle, boxStyle } from './MiViewScreenTactic.style';
import MiViewScreen from 'components/MiViewScreen/MiViewScreen';
import MiViewScreenContent from 'components/MiViewScreenContent/MiViewScreenContent';
import MiViewScreenFilterMenu from 'components/MiViewScreenFilterMenu/MiViewScreenFilterMenu';
import MiViewScreenHeader from 'components/MiViewScreenHeader/MiViewScreenHeader';
import MiViewScreenTitle from 'components/MiViewScreenTitle/MiViewScreenTitle';
import MiViewTacticList from 'components/MiViewTacticList/MiViewTacticList';
import MiViewPreloader from 'miview-ui/components/MiViewPreloader/MiViewPreloader';

const MiViewScreenTactic: FC = () => {
  const { t } = useTranslation();
  const {
    targetFieldValues,
    tactics,
    nonChildTactics,
    cepTitle,
    selectedTarget,
    updateFilterField,
    clearFilterField,
    screenLoading,
  } = useMiViewScreenTactic();

  return (
    <MiViewScreen>
      <MiViewScreenHeader>
        <ArrowBackIosNewIcon sx={ disabledIconStyle } />
        <MiViewScreenTitle componentName={ cepTitle || '' } maxWidth={ 220 }></MiViewScreenTitle>
        {
          targetFieldValues?.length > 0 && (
            <MiViewScreenFilterMenu
              minWidth={ 120 }
              name={ t('maya_cep_step_miview_preview_filter_menu_account_name') }
              filterField={
                {
                  name: t('maya_cep_step_miview_preview_filter_menu_account_name'),
                  sortStatus: 'desc',
                  fieldValues: targetFieldValues,
                }
              } updateFilterField={ updateFilterField } clearFilterField={ clearFilterField }></MiViewScreenFilterMenu>
          )
        }
        <ChangeCircleOutlinedIcon sx={ { ...enabledIconStyle, marginLeft: targetFieldValues?.length ? '0' : 'auto' } }/>
        <BarChartIcon sx={ disabledIconStyle }/>
        <SortIcon sx={ enabledIconStyle }/>
        <FilterListIcon sx={ enabledIconStyle }/>
        <MoreVert sx={ disabledIconStyle } />
      </MiViewScreenHeader>
      <MiViewScreenContent>
        {
          !screenLoading && tactics.length > 0
          && <MiViewTacticList tactics={ tactics } nonChildTactics={ nonChildTactics } target={ selectedTarget }></MiViewTacticList>
        }
        {
          !screenLoading && !tactics.length ? (
            <Box sx={ boxStyle }>
              { t('maya_cep_step_miview_preview_tactic_screen_no_data') }
            </Box>
          ) : null
        }
        {
          screenLoading
            && <MiViewPreloader size={ 50 }></MiViewPreloader>
        }
      </MiViewScreenContent>
    </MiViewScreen>
  );
};

export default MiViewScreenTactic;
