import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const COUNT_CEP = gql`
query CountCustomerEngagementPlanningsType($filters: DashboardFilter!) {
  countCustomerEngagementPlannings(filters: $filters) {
    count
  }
}
`;

type ResultType = {
  countCustomerEngagementPlannings: {
    count: number;
  };
};

type UseCountCustomerEngagementPlannings = () => {
  countCepQuery: Function;
  loading: boolean;
  error?: ApolloError;
  cepData: unknown;
};

const useCountCepQuery: UseCountCustomerEngagementPlannings = () => {
  const [countCepQuery, { data, loading, error }] = useLazyQuery<ResultType>(COUNT_CEP, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'countCustomerEngagementPlannings.ts - useCountCustomerEngagementPlanningsQuery',
    });
  }

  return {
    countCepQuery,
    cepData: data?.countCustomerEngagementPlannings.count,
    loading,
    error,
  };
};

export default useCountCepQuery;
