import { FC } from 'react';
import { ListActionPropsType } from './GridListAction.types';
import MoreActionsMenu from 'components/MoreActionsMenu';

const GridListAction: FC<ListActionPropsType> = (
  {
    actions,
  },
) => {
  const gridActions = actions ? actions.map((action) => ({
    label: action.label,
    onClick: action.callback,
  })) : [];

  return (
    <MoreActionsMenu menuItemConfigs={ gridActions }/>
  );
};

export default GridListAction;
