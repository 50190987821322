import { useTranslation } from 'react-i18next';
import { array, mixed, string } from 'yup';
import { BiogenProduct, BiogenTherapeuticArea } from 'basics/enums/biogen.enums';
import { InitiativeCountry, TemplateRegion } from 'basics/enums/country.enums';
import { BiogenFunction } from 'basics/enums/maya.enums';
import { testEndDayAfterStartDay, testStartDateIsSet } from 'basics/utils/dates/dates.utils';

const maxTitleLength = 80;
const maxDescriptionLength = 255;
const maxProblemStatementLength = 255;
const maxSolutionCoreStoryLength = 1000;

const useCustomerInitiativeFormSchema = (isTemplate: boolean) => {
  const { t } = useTranslation();
  const countriesAndRegion = isTemplate
    ? [...Object.keys(InitiativeCountry), ...Object.keys(TemplateRegion)]
    : Object.keys(InitiativeCountry);

  const requiredLabel = t('form_errors_required');
  const maxLengthTitleMessage = `${t('maya_customer_initiative_form_title_error_max_char', {
    count: maxTitleLength,
  })}`;
  const maxLengthDescriptionMessage = `${t('maya_customer_initiative_form_description_error_max_char', {
    count: maxDescriptionLength,
  })}`;
  const maxLengthProblemStatementMessage = `${t('maya_customer_initiative_form_problem_statement_error_max_char', {
    count: maxProblemStatementLength,
  })}`;
  const maxLengthSolutionCoreStoryMessage = `${t('maya_customer_initiative_form_solution_core_story_error_max_char', {
    count: maxSolutionCoreStoryLength,
  })}`;

  return {
    title: string().required(requiredLabel).max(maxTitleLength, maxLengthTitleMessage),
    date: mixed()
      .test({
        name: 'startDate is set',
        message: requiredLabel,
        test: testStartDateIsSet,
      })
      .test({
        name: 'endDate must be after startDate',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayAfterStartDay,
      }),
    description: string().required(requiredLabel).max(maxDescriptionLength, maxLengthDescriptionMessage),
    country: string().oneOf(countriesAndRegion, requiredLabel).required(),
    therapeuticArea: mixed<BiogenTherapeuticArea>().oneOf(Object.values(BiogenTherapeuticArea), requiredLabel).required(),
    product: string()
      .oneOf(Object.values(BiogenProduct))
      .required(requiredLabel),
    strategicImperative: string().required(),
    problemStatement: string().max(maxProblemStatementLength, maxLengthProblemStatementMessage),
    solutionCoreStory: string().required(requiredLabel).max(maxSolutionCoreStoryLength, maxLengthSolutionCoreStoryMessage),
    functionalOwner: mixed<BiogenFunction | string>().oneOf([...Object.values(BiogenFunction)], requiredLabel).required(),
    functionsInvolved: array()
      .of(mixed<BiogenFunction>().oneOf(Object.values(BiogenFunction)))
      .min(1)
      .required(requiredLabel),
  };
};

export default useCustomerInitiativeFormSchema;
