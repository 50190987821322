import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mayaCepListRoute } from 'basics/constants/routes.constants';
import useGetCepByIdQuery from 'basics/graphql/queries/getCepById';
import { warningToast } from 'basics/utils/toast';
import useStepIndexer from 'hooks/useStepIndexer';
import { cepViewStepIndexer } from 'routes/AuthenticatedApp/CepView/CepView.states';
import useCepViewButtons from 'routes/AuthenticatedApp/CepView/CepViewButtons.hook';
import useCepViewStepper from 'routes/AuthenticatedApp/CepView/CepViewStepper.hook';
import cepState from 'states/cep.states';

const useCepView = () => {
  const stepIndexer = useStepIndexer(cepViewStepIndexer);
  const { moveNextStep, movePreviousStep } = stepIndexer;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const { data: cep, loading } = useGetCepByIdQuery(urlParams.get('id'));

  useEffect(() => {
    if (!loading) {
      if (cep) {
        cepState.cep = cep;
      } else {
        warningToast(t('common.wording.error404'));
        navigate(mayaCepListRoute);
      }
    }
  }, [cep, loading, navigate, t]);

  useEffect(() => {
    return function cleanup() {
      cepState.cep = {};
      cepState.tactics = [];
      cepState.targetList = [];
    };
  }, []);

  const handleChangeStep = useCallback(
    (stepIndexVal: number) => {
      stepIndexer.moveToStep(stepIndexVal);
    },
    [stepIndexer],
  );

  const nextPage = useCallback(async () => {
    moveNextStep();
  }, [moveNextStep]);

  const previousPage = useCallback(() => {
    movePreviousStep();
  }, [movePreviousStep]);

  const buttonsConfig = useCepViewButtons(
    stepIndexer,
    nextPage,
    previousPage,
  );
  const stepsList = useCepViewStepper();

  return {
    buttonsConfig,
    cep,
    handleChangeStep,
    stepIndexer,
    stepsList,
  };
};

export default useCepView;
