import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { MutationReturnType } from 'basics/types/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const setCepStatusToApprovedMutationCall = gql`
  mutation setCepStatusToApproved($id: String!, $sendToVeeva: Boolean!) {
      setCepStatusToApproved(id: $id, sendToVeeva: $sendToVeeva)
  }
`;

type ResultType = {
  setCepStatusToApproved: boolean;
};

type UseSetCepStatusToApprovedMutation = [
  (id: string) => Promise<MutationReturnType<boolean>>,
  MutationResult<ResultType>,
];

export const useSetCepStatusToApprovedMutation = (): UseSetCepStatusToApprovedMutation => {
  const [setCepStatusToApproved, state] = useMutation<ResultType>(setCepStatusToApprovedMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (id) => {
    try {
      const { data } = await setCepStatusToApproved({
        variables: {
          id,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816,
        },
      });

      return { data: !!data?.setCepStatusToApproved, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'setCepStatusToApproved.ts - useSetCepStatusToAppoveMutation',
        });

        return { data: false, error: customContext.message as string };
      }

      return { data: false, error: null };
    }
  }, [setCepStatusToApproved, flags.mayaEnableVeevaBiogenlinc1816]);

  return [executeMutation, state];
};
