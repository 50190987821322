import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_TARGETS_REACH = gql`
query getTargetsReach($filters: DashboardFilter!) {
  getTargetsReach(filters: $filters)
}
`;

type ResultType = {
  getTargetsReach: number;
};

type UseGetTargetsReach = () => {
  getTargetsReachQuery: Function;
  loading: boolean;
  error?: ApolloError;
  targetsReach: unknown;
};

const useGetTargetsReachQuery: UseGetTargetsReach = () => {
  const [getTargetsReachQuery, { data, loading, error }] = useLazyQuery<ResultType>(GET_TARGETS_REACH, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getTargetsReach.ts - useGetTargetsReachQuery',
    });
  }

  return {
    getTargetsReachQuery,
    targetsReach: data?.getTargetsReach,
    loading,
    error,
  };
};

export default useGetTargetsReachQuery;
