import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useExportTargetListQuery from 'basics/graphql/queries/exportTargetList';
import { CepStateType } from 'basics/types/maya.types';
import { errorToast, succesToast } from 'basics/utils/toast';
import { useGTM } from 'contexts/gtm';
import cepState from 'states/cep.states';

const useTargetListExport = () => {
  const { t } = useTranslation();
  const dataLayer = useGTM();

  const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
  const { data, error, exportTargetList, loading: exportLoading } = useExportTargetListQuery();

  const cepStateValue = useSnapshot(cepState) as CepStateType;

  useEffect(() => {
    if (error) {
      setTriggerDownload(false);
    }

    if (!exportLoading && data && triggerDownload) {
      const url = URL.createObjectURL(new Blob(
        [data as Buffer],
        { type: 'text/csv' },
      ));
      const event = new MouseEvent('click');
      const el = document.createElement('a');
      const fileName = `export-targets-${cepStateValue.cep?.formattedCepId}-${new Date().getTime()}`;

      dataLayer({
        event: 'export_to_csv',
        export_name: fileName,
      });

      el.setAttribute('href', url);
      el.setAttribute('download', fileName);
      el.dispatchEvent(event);
      URL.revokeObjectURL(url);

      setTriggerDownload(false);
    }
  }, [exportLoading, data, error, triggerDownload, cepStateValue.cep?.formattedCepId, dataLayer]);

  const downloadTargets = async () => {
    await exportTargetList({
      variables: {
        cepId: cepStateValue?.cep?._id,
      },
    });
    setTriggerDownload(true);
    if (data) {
      succesToast(t('maya_export_success'));
    }
    if (error) {
      errorToast(t('maya_export_error'));
    }
  };

  return {
    cepId: cepStateValue?.cep?._id,
    downloadDisabled: !cepStateValue.targetList.length,
    downloadTargets,
    exportLoading,
    t,
  };
};

export default useTargetListExport;
