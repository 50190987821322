import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { MiViewColors, MiViewBorderRadius, MiViewSpacing, MiViewFontSize } from 'miview-ui/miviewTheme';

export const formControlStyle: SxProps<Theme> = {
  ml: 'auto',
  marginRight: MiViewSpacing.small,
  maxWidth: 120,
  label: {
    opacity: 1,
    transition: 'opacity 0.3s ease',
  },
  'label.Mui-focused': {
    opacity: 0,
  },
};

export const inputLabelStyle: SxProps<Theme> = {
  color: MiViewColors.tertiary,
  fontSize: MiViewFontSize.small,
  '&.Mui-focused': {
    color: MiViewColors.tertiary,
  },
};

export const selectStyle = {
  fontSize: MiViewFontSize.small,
  color: MiViewColors.quaternary,
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const selectPaperStyle: SxProps<Theme> = {
  maxHeight: '50vh',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
    overflowY: 'scroll',
    background: MiViewColors.lightGray,
  },
  '&::-webkit-scrollbar-thumb': {
    background: MiViewColors.gray,
    borderRadius: MiViewBorderRadius.xSmall,
  },
};

export const menuItemStyle: SxProps<Theme> = {
  fontSize: MiViewFontSize.small,
};
