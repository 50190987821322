import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { array, mixed } from 'yup';
import { VeevaSyncStatusBadgeMap } from 'basics/constants/common.constant';
import { CepStatus, VeevaSyncStatus } from 'basics/enums/maya.enums';
import useCepStatusOptions from 'basics/options/cepStatus.options';
import useCustomerInitiativeTypeOptions from 'basics/options/customerInitiative.options';
import GridListAction from 'components/GridListAction/GridListAction';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaColors, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const useCepListConfig = (
  setCepToFeedbackCallback: (cepId: string) => void,
  setCepToApprovedCallback: (cepId: string) => void,
  syncCepCallback: (cepId: string) => void,
  editCepCallback: (cepId: string) => void,
  duplicateCepCallback: (cepId: string) => void,
  openModalCallback: (cepId: string) => void,
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const CepStatusBadgeMap = {
    [CepStatus.DRAFT as string]: {
      label: t('maya_status_draft'),
      bgColor: YodaColors.errorLight,
      color: YodaColors.error,
    },
    [CepStatus.APPROVED as string]: {
      label: t('maya_status_approved'),
      bgColor: YodaColors.greenSuccessLight,
      color: YodaColors.greenSuccessDark,
    },
    [CepStatus.FEEDBACK as string]: {
      label: t('maya_status_feedback'),
      bgColor: YodaColors.warningLight,
      color: YodaColors.warning,
    },
    [CepStatus.ONGOING as string]: {
      label: t('maya_status_ongoing'),
      bgColor: YodaColors.warningLight,
      color: YodaColors.warning,
    },
  };

  const cepTitleFieldConfig = {
    label: t('maya_cep_form_cep_name_label'),
    name: 'name',
    placeholder: t('maya_cep_form_cep_name_placeholder'),
    defaultValue: '',
    required: false,
  };

  const statusFieldConfig = {
    label: t('status_label'),
    name: 'status',
    placeholder: t('status_placeholder'),
    options: useCepStatusOptions(),
    defaultValue: [],
    required: false,
    validation: array()
      .of(mixed<CepStatus>().oneOf(Object.values(CepStatus))),
  };

  const customerInitiativeFieldConfig = {
    label: t('maya_cep_form_ci_label'),
    name: 'initiative',
    placeholder: t('maya_cep_form_ci_placeholder'),
    options: useCustomerInitiativeTypeOptions({ isTemplate: false }),
    defaultValue: [],
    required: false,
    validation: array(),
  };

  const cepListColumnConfig: GridColDef[] = [
    {
      field: 'globalCepSyncStatus',
      headerName: t('maya_veeva_sync_status_header_label'),
      flex: 0.15,
      renderCell: (cellValues: GridRenderCellParams) => {
        const badge = VeevaSyncStatusBadgeMap[cellValues.row.globalCepSyncStatus.syncStatus || VeevaSyncStatus.PENDING];
        return badge && (
          <Tag
            fitContent
            bgcolor={ badge.bgColor }
            color={ badge.color }
            fontWeight={ YodaFontWeight.medium }
            paddingY={ YodaSpacing.xxxxSmall }
            paddingX={ YodaSpacing.xSmall }
            width={ 'auto' }
            height={ '20px' }
            borderRadius={ '1rem' }
          >
            { badge.label }
          </Tag>
        );
      },
      sortable: false,
    },
    {
      field: 'title',
      headerName: t('maya_cep_list_title_header_label'),
      flex: 0.3,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'formattedCepId',
      headerName: t('maya_cep_list_cep_id_header_label'),
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'published',
      type: 'date',
      headerName: t('maya_cep_list_published_date_header_label'),
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value == null) {
          return null;
        }
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
      flex: 0.1,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (cellValues: GridRenderCellParams) => {
        const badge = CepStatusBadgeMap[cellValues.row.status];
        return badge && (
          <Tag
            fitContent
            bgcolor={ badge.bgColor }
            color={ badge.color }
            fontWeight={ YodaFontWeight.medium }
            paddingY={ YodaSpacing.xxxxSmall }
            paddingX={ YodaSpacing.xSmall }
            width={ 'auto' }
            height={ '20px' }
            borderRadius={ '1rem' }
          >
            { badge.label }
          </Tag>
        );
      },
      sortable: false,
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const cep = params.row;
        const actions = [
          {
            label: t('actions_edit_label'),
            callback: () => { editCepCallback(cep._id); },
          },
          {
            label: t('actions_duplicate_label'),
            callback: () => { duplicateCepCallback(cep._id); },
          },
        ];

        if (cep.status === CepStatus.DRAFT) {
          actions.push({
            label: t('maya_action_feedback_cep_label'),
            callback: () => {
              setCepToFeedbackCallback(cep._id);
            },
          });
        }

        if (cep.status === CepStatus.FEEDBACK) {
          actions.push({
            label: t('maya_action_approve_cep_label'),
            callback: () => {
              setCepToApprovedCallback(cep._id);
            },
          });
        }

        if (flags.mayaBiogenlinc3595EnableCepDeleting && cep.status !== CepStatus.APPROVED) {
          actions.push({
            label: t('maya_action_delete_cep_label'),
            callback: () => {
              openModalCallback(cep._id);
            },
          });
        }

        if (flags.mayaBiogenlinc2489VeevaSyncButtons) {
          actions.push({
            label: t('maya_action_sync_cep_label'),
            callback: () => {
              syncCepCallback(cep._id);
            },
          });
        }

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.08,
    },
  ];

  return {
    cepListColumnConfig,
    cepTitleFieldConfig,
    statusFieldConfig,
    customerInitiativeFieldConfig,
  };
};

export default useCepListConfig;
