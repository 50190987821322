import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { progressBoxStyle, progressStyle } from './MiViewPreloader.style';

export type MiViewPreloaderProps = {
  size: number;
};

const MiViewPreloader: FC<MiViewPreloaderProps> = ({ size }) => {
  return (
    <Box sx={ progressBoxStyle }>
      <CircularProgress size={ size } sx={ progressStyle }/>
    </Box>
  );
};

export default MiViewPreloader;
