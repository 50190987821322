import { useState, useEffect } from 'react';

const useMiViewScreenTitle = (componentName: string) => {
  const [name, setName] = useState<string>(componentName);

  useEffect(() => {
    setName(componentName);
  }, [componentName]);

  return {
    name,
  };
};

export default useMiViewScreenTitle;
