import { customerInitiativeFragment } from './customerInitiative.fragments';

export const cepFragment = `
  _id
  title
  formattedCepId
  description
  customerInitiative {
    ${customerInitiativeFragment}
  }
  therapeuticArea
  product
  biogenFunction
  dateStart
  dateEnd
  kpi
  customerType
  specialty
  status
  updated
  published
  targetFilter {
    countries
    types
    businessUnits
    specialties
    biosSegments
    smaSegments
    imsSegments
    hcoNames
    consent
    cyclePlan
    channelAffinity {
      approvedEmails
      broadcastEmails
      f2f
      f2fEvent
      phone
      virtual
      virtualEvent
      webSessions
    }
  }
  targetEngagementGoal
  npsGoal
  reachGoal
  veevaSyncStatus
  lastVeevaSync
  lastVeevaSyncError
  globalCepSyncStatus {
    syncStatus,
    errors
  }
`;
