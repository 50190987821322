import { VeevaVaultDocument } from 'generated/maya.types';

const useApprovedDocumentIdOptions = (veevaVaultDocument: VeevaVaultDocument[]) => {
  const approvedDocumentIdOptions = veevaVaultDocument.map((element: VeevaVaultDocument) => {
    return {
      id: element.id.toString(),
      label: `[${element.documentNumber}] [${element.status}] ${element.name}` as string,
    };
  });

  return approvedDocumentIdOptions;
};

export default useApprovedDocumentIdOptions;
