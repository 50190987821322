import { useTranslation } from 'react-i18next';
import initiativeCountryOptions from 'basics/options/country.options';
import { ExportFormConfigType } from 'components/ExportForm//ExportForm.types';
import useExportFormSchema from 'components/ExportForm/ExportForm.schema';

const useExportFormConfig = () => {
  const { t } = useTranslation();
  const exportFormSchema = useExportFormSchema();

  const exportFormConfig: ExportFormConfigType = {
    countries: {
      label: t('maya_export_form_countries_label'),
      name: 'countries',
      placeholder: t('maya_export_form_countries_placeholder'),
      options: initiativeCountryOptions,
      required: true,
      validation: exportFormSchema.countries,
    },
  };

  return { exportFormConfig };
};

export default useExportFormConfig;
